import React, { ReactElement, ReactNode } from 'react'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import CalculationView from '@/pages/Log/Factors/CalculationView'

import LogProvider from '@/contexts/log'

import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'

const Wrapper = ({
  children,
  feature,
}: {
  excludeRoles?: string[]
  feature?: string
  children: ReactNode
}): ReactElement => (
  <LogProvider>
    <PrivateRoute>
      <FeatureRoute requiredFeature={feature}>{children}</FeatureRoute>
    </PrivateRoute>
  </LogProvider>
)

const getCalculationRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.log.factors.calculations.viewCalculation,
    element: (
      <Wrapper feature="custom-factors">
        <CalculationView />
      </Wrapper>
    ),
  },
]

export default getCalculationRoutes
