import { RootState } from '..'

import { OnboardingState } from './slice'

// Selectors
export const selectSteps = (state: RootState): OnboardingState['steps'] => state.onboarding.steps

export const selectIsFinished = (state: RootState): boolean =>
  state.onboarding.steps?.filter((step) => !step.completed)?.length <= 0

export const selectIsLastStep = (state: RootState): boolean =>
  state.onboarding.steps?.filter((step) => !step.completed)?.length === 1

export const hasFinishedOnboardingStep = (state: RootState, stepKey: string): boolean =>
  state.onboarding.steps?.some((step) => step.onboardingStep.key === stepKey && step.completed)
