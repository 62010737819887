import { RootState } from '..'
import { createSelector } from '@reduxjs/toolkit'

import { BusinessUnit } from '@cozero/models'

export const selectSelectedBusinessUnit = (state: RootState): BusinessUnit | null =>
  state.businessUnits?.selectedBusinessUnit

export const selectSelectedBusinessUnitKey = (state: RootState): BusinessUnit['key'] | undefined =>
  state.businessUnits?.selectedBusinessUnit?.key

export const getIsRootBusinessUnit: (state: RootState) => boolean = createSelector(
  selectSelectedBusinessUnit,
  (bu) => !bu?.parentUnit,
)

export const getRootBusinessUnitId: (state: RootState) => number | undefined = createSelector(
  selectSelectedBusinessUnit,
  (bu) => (bu?.ancestorIds.length ? (bu?.ancestorIds?.[0] as number) : bu?.id),
)
