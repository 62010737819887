import React, { ReactElement, useEffect, useState } from 'react'

import LoadingSpinner from '@/atoms/LoadingSpinner'

import classes from './SuspenseSpinner.module.less'

const DELAY = 1500

const SuspenseSpinner = (): ReactElement | null => {
  const [showLoading, setShowLoading] = useState(true)

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setShowLoading(false)
    }, DELAY)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return showLoading ? (
    <div className={classes.container}>
      <LoadingSpinner title={''} size={'sm'} />
    </div>
  ) : null
}
export default SuspenseSpinner
