import React from 'react'

import { useAppSelector } from '@/redux'
import { selectUserOrganization } from '@/redux/auth'

import BusinessUnitTreeDropdown from '../BusinessUnitTreeDropdown'

import Logo from './Logo'
import classes from './NavCompanyHeader.module.less'

const NavCompanyHeader = (): JSX.Element => {
  const organization = useAppSelector(selectUserOrganization)

  return (
    <div className={classes.companyHeader}>
      <Logo logoSrc={organization?.logo?.url} />
      <div className={classes.dropdownWrapper}>
        <BusinessUnitTreeDropdown />
      </div>
      <div className={classes.background} />
    </div>
  )
}

export default NavCompanyHeader
