import { Industry } from '@cozero/models'
import { shareApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { GET_INDUSTRIES, TAG_INDUSTRIES } from './constants'

const industriesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_INDUSTRIES]: builder.query<Industry[], void>({
      query: () => ({
        url: shareApiGatewayClient.industries.GET_MANY,
        method: 'GET',
      }),
      providesTags: (result) =>
        providesList<Industry[], typeof TAG_INDUSTRIES>(result, TAG_INDUSTRIES),
    }),
  }),
})

export const { useGetIndustriesQuery, useLazyGetIndustriesQuery, usePrefetch } = industriesApiSlice

export default industriesApiSlice
