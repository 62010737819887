import { Dispatch, SetStateAction, useState } from 'react'

import useSWR from 'swr'

import { Category, Subcategory } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { LifecycleStepsID, LifecycleStepsKey } from '../pages/Organization/NewProduct/steps'
import axios from '../utils/axios'

type UseCategories = {
  categories?: Category[]
  subcategories?: Subcategory[]
  subcategoryId: number | undefined
  setSubcategoryId: Dispatch<SetStateAction<number | undefined>>
  mutate: (categories?: Category[]) => void
  setCategoryId: Dispatch<SetStateAction<number | undefined>>
  error: Error
  loading: boolean
}

/**
 * Hook to fetch categories from the API
 * @param {string} section which section to fetch categories for
 * @returns {UseCategories}
 */
const useCategories = (
  lifecycleStep?: LifecycleStepsKey,
  lifecycleStepId?: LifecycleStepsID,
): UseCategories => {
  const [categoryId, setCategoryId] = useState<number | undefined>()
  const [subcategoryId, setSubcategoryId] = useState<number | undefined>()
  const {
    data: categories,
    mutate: mutateCategories,
    error,
    isValidating,
  } = useSWR<Category[]>(
    [logApiGatewayClient.categories.GET_MANY],
    async (uri) => {
      const { data } = await axios.get<Category[]>(uri, { params: { lifecycleStep } })
      return data
    },
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false },
  )

  const { data: subcategories, mutate: mutateSubcategories } = useSWR<Subcategory[]>(
    [logApiGatewayClient.subcategories.GET_MANY, categoryId],
    async (uri, categoryId) => {
      const { data } = await axios.get<Subcategory[]>(uri, {
        params: { categoryId, lifecycleStepId },
      })
      return data
    },
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false },
  )

  const mutate = (): void => {
    mutateCategories()
    mutateSubcategories()
  }

  return {
    categories,
    subcategories,
    mutate,
    setCategoryId,
    subcategoryId,
    setSubcategoryId,
    error,
    loading: !error && isValidating,
  }
}
export default useCategories
