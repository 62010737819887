import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Popconfirm, Row, Spin, message } from 'antd/es'

import jwt_decode from 'jwt-decode'
import moment from 'moment'

import { ApiKey, User } from '@cozero/models'

import Modal from '@/molecules/Modal'
import Table from '@/molecules/Table'

import Button from '@/atoms/Button'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import {
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
  useGetApiKeysQuery,
} from '@/redux/apiKeys/api'
import { ApiKeyJwt } from '@/types/general'

import classes from './classes.module.less'

function ApiAccess(): JSX.Element {
  const { t } = useTranslation('common')
  const { data: apiKeys, isLoading: loadingApiKeys } = useGetApiKeysQuery()
  const [deleteApiKey, { isLoading: isDeletingKey }] = useDeleteApiKeyMutation()
  const [createApiKey, { isLoading: isCreatingKey }] = useCreateApiKeyMutation()
  const [secretKey, setApiKey] = useState<string | undefined>()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = (): void => {
    setIsModalVisible(true)
  }

  const handleOk = (): void => {
    setIsModalVisible(false)
    setApiKey(undefined)
  }

  const columnsForApiKeys = [
    {
      title: t('settings.api.requested-by'),
      dataIndex: 'user',
      key: 'user',
      render: (user: User) => {
        return user?.email
      },
    },
    {
      title: t('settings.api.created-at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: Date) => {
        return moment(date).format('DD. MMMM YYYY, HH:mm ')
      },
    },
    {
      title: t('actions.title'),
      key: 'action',
      render: function DeleteButton(_text: string, record: ApiKey) {
        return (
          <Popconfirm
            title={t('settings.api.delete')}
            onConfirm={async (e) => {
              e?.stopPropagation()
              await deleteKey(record.id)
            }}
            onCancel={(e) => {
              e?.stopPropagation()
            }}
            okText={t('yes')}
            cancelText={t('no')}
          >
            <Button
              loading={isDeletingKey}
              category={'api-keys'}
              action={'delete'}
              onClick={(e) => e.stopPropagation()}
              type="primary"
              color="danger"
            >
              {t('settings.api.delete-btn')}
            </Button>
          </Popconfirm>
        )
      },
    },
  ]

  async function getApiKey(): Promise<void> {
    const token = await createApiKey().unwrap()
    if (token) {
      const decoded = jwt_decode(token) as ApiKeyJwt
      message.success(t('settings.api.create-success'))
      setApiKey(decoded.key)
      showModal()
    }
  }

  async function deleteKey(id: ApiKey['id']): Promise<void> {
    try {
      const res = await deleteApiKey(id).unwrap()
      if (res) {
        message.success(t('settings.api.delete-success'))
      }
    } catch (error) {
      message.error(t('settings.api.delete-success'))
    }
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Row className={classes.section}>
            <Col span={24}>
              <Title size="sm">{t('settings.api.title')}</Title>
            </Col>
            <Col span={24}>
              <Text size="xl" color="secondary">
                {t('settings.api.subtitle')}
              </Text>
            </Col>
          </Row>
          <Row justify="end" gutter={16} className={classes.section}>
            <Col>
              <Button
                category="api-keys"
                action="go-to-docs"
                type="ghost"
                onClick={() => window.open('https://cozero.readme.io')}
              >
                {t('settings.api.docs')}
              </Button>
            </Col>
            <Col>
              <Button
                category={'api-keys'}
                action={'request'}
                className={classes.requestButton}
                type="primary"
                onClick={getApiKey}
                loading={isCreatingKey}
              >
                {t('settings.api.request')}
              </Button>
            </Col>
          </Row>
          <Row className={classes.section}>
            <Col span={24}>
              <Spin spinning={loadingApiKeys}>
                <Table
                  columns={columnsForApiKeys}
                  dataSource={apiKeys}
                  pagination={false}
                  rowKey="id"
                />
              </Spin>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        title={t('settings.api.modal-title')}
        visible={isModalVisible}
        onOk={handleOk}
        footer={[
          <Button
            category={'api-keys'}
            action={'ok'}
            key="ok"
            type="primary"
            onClick={() => handleOk()}
          >
            {t('settings.api.modal-ok')}
          </Button>,
        ]}
      >
        <Text strong type="danger" size="xl">
          {t('settings.api.warning')}
        </Text>
        <Text
          mode="paragraph"
          size="xl"
          className={classes.tokenBlock}
          ellipsis={{ rows: 15 }}
          copyable
          code
        >
          {secretKey}
        </Text>
      </Modal>
    </>
  )
}

export default ApiAccess
