import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { UserOnboardingStep } from '@cozero/models'

import { GET_USER_DATA, authApiSlice } from '../auth'

import { SLICE_NAME_ONBOARDING } from './constants'

// Define a type for the slice state
export interface OnboardingState {
  error: Error | null
  loading: boolean
  steps: UserOnboardingStep[]
}

const initialState: OnboardingState = {
  error: null,
  loading: false,
  steps: [],
}

const onboardingSlice = createSlice({
  name: SLICE_NAME_ONBOARDING,
  initialState,
  reducers: {
    setSteps: (state, action: PayloadAction<UserOnboardingStep[]>) => {
      state.steps = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApiSlice.endpoints[GET_USER_DATA].matchFulfilled,
      (state: OnboardingState, { payload }) => {
        state.steps = payload.onboardingSteps
      },
    )
  },
})

export const { setSteps } = onboardingSlice.actions

export default onboardingSlice
