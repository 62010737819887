import React, { memo } from 'react'

type Props = {
  symbol: string
  label?: string
  className?: string
}

const Emoji = ({ symbol, label, className }: Props): JSX.Element => {
  return (
    <span
      role="image"
      aria-label={label ? label : ''}
      aria-hidden={!label}
      className={`${className ? className : ''}`}
    >
      {symbol}
    </span>
  )
}

export default memo(Emoji)
