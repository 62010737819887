import React, { memo, useMemo, useState } from 'react'

import { InputNumber, InputNumberProps } from 'antd/es'

import i18n from '@/i18n'
import { getLocaleDecimalSeparator, parseLocaleNumber } from '@/utils/number'

import classes from './CustomInputNumber.module.less'

export interface CustomInputNumberProps extends InputNumberProps {
  className?: string
}

const CustomInputNumber = ({ className, ...props }: CustomInputNumberProps): JSX.Element => {
  const [currentValue, setCurrentValue] = useState<number | string | null>(
    () => props.value as number,
  )
  const classNames = useMemo(() => `${classes.inputNumber} ${className}`, [className])

  return (
    <InputNumber
      parser={(value) => {
        const decimalSeparator = getLocaleDecimalSeparator(i18n.language)
        const isAlreadyDecimal = currentValue?.toString().includes(decimalSeparator)
        if (isAlreadyDecimal) {
          return parseFloat(value || '0')
        }
        return value ? parseLocaleNumber(value.toString(), i18n.language) : 0
      }}
      formatter={(value, info) => {
        const decimalSeparator = getLocaleDecimalSeparator(i18n.language)
        if (value && decimalSeparator === ',') {
          return value.toString().replace('.', ',')
        }
        const result = value?.toString() ?? '0'
        return props.formatter ? props.formatter(result, info) : result
      }}
      onChange={(...args) => {
        if (args) {
          if (args[0]) {
            setCurrentValue(args[0])
          }
          props.onChange && props.onChange(...args)
        }
      }}
      {...props}
      className={classNames}
    />
  )
}

export default memo(CustomInputNumber)
