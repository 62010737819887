import React from 'react'

import { CINDER_BLUE_80 } from '@/styles/variables'
import { IconProps } from '@/types/general'

const Sankey = ({ width = 24, height = 24, color = CINDER_BLUE_80 }: IconProps): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M4 12C15 12 9 18 20 18M4 18C15 18 9 6 20 6M4 6C15 6 9 12 20 12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        fill={color}
      />
    </svg>
  )
}

export default Sankey
