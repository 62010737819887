import { CreateUserFunctionDto, UpdateUserFunctionDto } from '@cozero/dtos'
import { UserFunction } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import {
  CREATE_USER_FUNCTION,
  DELETE_USER_FUNCTION,
  GET_SINGLE_USER_FUNCTION,
  GET_USER_FUNCTIONS,
  TAG_USER_FUNCTIONS,
  UPDATE_USER_FUNCTION,
} from './constants'

const userFunctionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_USER_FUNCTIONS]: builder.query<UserFunction[], void>({
      query: () => ({
        url: centralApiGatewayClient.userFunctions.GET_MANY,
        method: 'GET',
      }),
      providesTags: (result) =>
        providesList<UserFunction[], typeof TAG_USER_FUNCTIONS>(result, TAG_USER_FUNCTIONS),
    }),
    [GET_SINGLE_USER_FUNCTION]: builder.query<UserFunction, void>({
      query: () => ({
        url: centralApiGatewayClient.userFunctions.GET_ONE,
        method: 'GET',
      }),
      providesTags: (result) => [{ type: TAG_USER_FUNCTIONS, id: result?.id }],
    }),
    [CREATE_USER_FUNCTION]: builder.mutation<UserFunction, CreateUserFunctionDto>({
      query: (data) => ({
        url: centralApiGatewayClient.userFunctions.CREATE,
        method: 'POST',
        data,
      }),
      invalidatesTags: () => [{ type: TAG_USER_FUNCTIONS }],
    }),
    [UPDATE_USER_FUNCTION]: builder.mutation<
      UserFunction,
      UpdateUserFunctionDto & { id: UserFunction['id'] }
    >({
      query: (data) => ({
        url: centralApiGatewayClient.userFunctions.UPDATE_ONE.replace(':id', `${data?.id}`),
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result) => [{ type: TAG_USER_FUNCTIONS, id: result?.id }],
    }),
    [DELETE_USER_FUNCTION]: builder.mutation<UserFunction, number>({
      query: (id) => ({
        url: centralApiGatewayClient.userFunctions.DELETE_ONE.replace(':id', `${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [{ type: TAG_USER_FUNCTIONS, id: result?.id }],
    }),
  }),
})

export const {
  usePrefetch,
  useGetUserFunctionsQuery,
  useLazyGetUserFunctionsQuery,
  useGetSinglerUserFunctionQuery,
  useLazyGetSinglerUserFunctionQuery,
  useCreateUserFunctionMutation,
  useUpdateUserFunctionMutation,
  useDeleteUserFunctionMutation,
} = userFunctionsSlice

export default userFunctionsSlice
