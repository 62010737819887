import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row, Skeleton } from 'antd'

import Card from '../../../../atoms/Card'
import Tag, { TagType } from '../../../../atoms/Tag'
import Text from '../../../../atoms/Text'

import classes from './ActionCard.module.less'

const ActionCard = ({
  children,
  disabled,
  title,
  text,
  stepNumber,
  status,
  active = true,
  shadow = 'none',
  isLoading,
  tagType,
}: {
  active?: boolean
  title?: string
  text?: JSX.Element
  shadow?: 'none' | 'sm' | 'md' | 'lg'
  className?: string
  status?: string
  stepNumber?: number
  disabled?: boolean
  isLoading?: boolean
  tagType?: TagType
  children: JSX.Element
}): JSX.Element => {
  const { t } = useTranslation('common')

  const statusTagType =
    tagType ??
    (status === t('onboarding.suppliers.waiting') ||
    status === t('onboarding.suppliers.in-progress')
      ? 'default'
      : 'info')

  return (
    <div className={classes.container}>
      {disabled && <div className={classes.disableLayer}></div>}
      <Card bgColor="default" shadow={shadow} className={active ? classes.activeCard : ''}>
        <Skeleton loading={isLoading}>
          <Row>
            <Col span={1}>
              <div className={classes.stepPill}>
                <Text color="blue" size="lg" mode="text">
                  {stepNumber}
                </Text>
              </div>
            </Col>
            <Col span={21}>
              <div className={classes.titleContainer}>
                <Text fontWeight="semibold" className={classes.title}>
                  {title}
                </Text>
              </div>
            </Col>
            <Col className={classes.justifyContentEnd} span={2}>
              <Tag type={statusTagType}>{status}</Tag>
            </Col>
          </Row>
          <Row>
            <Col offset={1}>
              <div className={classes.stepHeader}>
                <Text color="secondary" mode="paragraph" size="xl">
                  {text}
                </Text>
              </div>
            </Col>
          </Row>
          {children}
        </Skeleton>
      </Card>
    </div>
  )
}

export default ActionCard
