import { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { PageFilter } from '@cozero/models'

import { FilterKey, PageType } from '@/molecules/FiltersDrawer'

import { SLICE_NAME_FILTER } from './constants'

const initialState: {
  [key: string]: (PageFilter & {
    key: FilterKey
  })[]
} = {
  product: [],
  factors: [],
  locations: [],
  locationList: [],
}

const filterSlice = createSlice({
  name: SLICE_NAME_FILTER,
  initialState,
  reducers: {
    setProductsFilters: (
      state,
      action: PayloadAction<
        (PageFilter & {
          key: FilterKey
        })[]
      >,
    ) => {
      state[PageType.PRODUCT] = action.payload
    },
    resetProductsFilters: (state) => {
      state[PageType.PRODUCT] = []
    },
    setLocationsFilters: (
      state,
      action: PayloadAction<
        (PageFilter & {
          key: FilterKey
        })[]
      >,
    ) => {
      state[PageType.LOCATION] = action.payload
    },
    resetLocationsFilters: (state) => {
      state[PageType.LOCATION] = []
    },
    setFactorsFilters: (
      state,
      action: PayloadAction<
        (PageFilter & {
          key: FilterKey
        })[]
      >,
    ) => {
      state[PageType.FACTOR] = action.payload
    },
    resetFactorsFilters: (state) => {
      state[PageType.FACTOR] = []
    },
    setLocationListFilters: (
      state,
      action: PayloadAction<
        (PageFilter & {
          key: FilterKey
        })[]
      >,
    ) => {
      state[PageType.LOCATION_LIST] = action.payload
    },
    resetLocationListFilters: (state) => {
      state[PageType.LOCATION_LIST] = []
    },
  },
})

export const {
  setProductsFilters,
  resetProductsFilters,
  setLocationsFilters,
  resetLocationsFilters,
  resetFactorsFilters,
  setLocationListFilters,
  resetLocationListFilters,
} = filterSlice.actions

export default filterSlice
