import React, { ReactElement, memo } from 'react'

import { Product } from '@cozero/models'

import Stepper from '@/molecules/VerticalStepper'

import classes from './ProductSidebar.module.less'

interface Props {
  handleAction: (action: 'next' | 'back' | number) => void
  product?: Product
}

const ProductSidebar = ({ handleAction, product }: Props): ReactElement => (
  <div className={classes.sider}>
    <div className={classes.siderInnerContainer}>
      <Stepper size="small" direction="vertical" handleAction={handleAction} product={product} />
    </div>
  </div>
)

export default memo(ProductSidebar)
