import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BusinessUnit } from '@cozero/models'

import Select, { SelectOptionsProperties, SelectProps } from '@/atoms/Select'

import classes from './classes.module.less'

interface BusinessUnitsDropdownProps extends SelectProps {
  businessUnits: BusinessUnit[]
  showNoParent: boolean
  onChange?: (value: string) => void
  defaultValue?: number
  size?: 'small' | 'middle' | 'large'
  loading?: boolean
}

export default function BusinessUnitsDropdown({
  businessUnits,
  onChange,
  showNoParent,
  defaultValue,
  value,
  size,
  loading = false,
  ...rest
}: BusinessUnitsDropdownProps): JSX.Element {
  const { t } = useTranslation('common')

  const options = useMemo(() => {
    const selectOptions: SelectOptionsProperties[] = [
      ...businessUnits.map((businessUnit) => ({
        key: businessUnit.id,
        value: businessUnit.id,
        label: businessUnit.title,
      })),
    ]
    if (showNoParent) {
      selectOptions.unshift({ key: 'root', value: 'root', label: t('settings.bu.no-parent') })
    }
    return selectOptions
  }, [businessUnits])

  return (
    <Select
      showSearch
      style={{ minWidth: '200px', marginRight: '10px', maxWidth: '100%' }}
      size={size}
      placeholder={t('settings.bu.select')}
      optionFilterProp="children"
      onChange={onChange}
      dropdownClassName={classes.locationDropdown}
      filterOption={(input, option) => {
        if (!option || !input || !option.label) {
          return false
        }
        const searchString = (option.label as string).toLocaleLowerCase()
        return !!searchString.match(input.toLocaleLowerCase())
      }}
      value={value ?? defaultValue}
      options={options}
      loading={loading}
      disabled={loading}
      {...rest}
    />
  )
}
