import { Product } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import axios from '@/utils/axios'
import { getUrl } from '@/utils/url'

export async function createProduct(product: Partial<Product>): Promise<Product> {
  const url = getUrl(centralApiGatewayClient.products.CREATE)

  const createProductResponse = await axios.post(url.toString(), product)

  if (createProductResponse.status === 402) {
    throw new Error('PRODUCT_LIMIT_EXCEEDED')
  }

  return await createProductResponse.data
}

export async function deleteProduct(productId: number): Promise<Product> {
  const url = getUrl(centralApiGatewayClient.products.DELETE_ONE.replace(':id', `${productId}`))

  const deleteProductResponse = await axios.delete(url.toString())

  return await deleteProductResponse.data
}

export async function transferProduct(productId: number, parentId: number): Promise<Product> {
  const url = getUrl(centralApiGatewayClient.products.TRANSFER.replace(':id', `${productId}`))

  const transferProductResponse = await axios.post(url.toString(), { parentId })

  return transferProductResponse.data
}
