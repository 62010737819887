import React, { ReactElement, useMemo } from 'react'
import { memo } from 'react'

import { Checkbox as AntCheckbox, CheckboxProps } from 'antd/es'

import classes from './Checkbox.module.less'

interface Props extends CheckboxProps {
  className?: string
  checked?: boolean
  size?: 'sm' | 'lg'
}

const Checkbox = ({ size = 'sm', className, ...rest }: Props): ReactElement => {
  const classNames = useMemo(
    () => [classes.checkbox, classes[size], className].join(' '),
    [className],
  )

  return <AntCheckbox className={classNames} {...rest} />
}

export default memo(Checkbox)
