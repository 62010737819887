import { ReportType } from '@cozero/models'

interface GraphAllowedOptions {
  type: ReportType
  export: { csv: boolean; image: boolean }
  toggleLegend: boolean
  deletable: boolean
}

const graphAllowedOptions: GraphAllowedOptions[] = [
  {
    type: ReportType.PIE,
    export: { csv: true, image: true },
    toggleLegend: true,
    deletable: true,
  },
  {
    type: ReportType.LINE,
    export: { csv: true, image: true },
    toggleLegend: true,
    deletable: true,
  },
  {
    type: ReportType.BAR,
    export: { csv: true, image: true },
    toggleLegend: true,
    deletable: true,
  },
  {
    type: ReportType.SANKEY,
    export: { csv: true, image: true },
    toggleLegend: false,
    deletable: true,
  },
  {
    type: ReportType.SUNBURST,
    export: { csv: true, image: true },
    toggleLegend: false,
    deletable: true,
  },
  {
    type: ReportType.STACKED_BAR,
    export: { csv: true, image: true },
    toggleLegend: true,
    deletable: true,
  },
  {
    type: ReportType.FACET_STACKED_BAR,
    export: { csv: true, image: true },
    toggleLegend: true,
    deletable: true,
  },
  {
    type: ReportType.TABLE,
    export: { csv: true, image: false },
    toggleLegend: false,
    deletable: true,
  },
  {
    type: ReportType.FORECAST,
    export: { csv: true, image: true },
    toggleLegend: false,
    deletable: true,
  },
  {
    type: ReportType.CARBON_ACTIONS,
    export: { csv: false, image: false },
    toggleLegend: false,
    deletable: true,
  },
  {
    type: ReportType.MAP,
    export: { csv: false, image: false },
    toggleLegend: false,
    deletable: false,
  },
]

export default graphAllowedOptions
