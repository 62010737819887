import React from 'react'

import { ConfigProvider } from 'antd/es'
import localeDe from 'antd/es/locale/de_DE'
import localeEn from 'antd/es/locale/en_US'

import { useAppSelector } from '../redux'
import { selectUser } from '../redux/auth'

const AntDesignConfigProvider = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement => {
  const user = useAppSelector(selectUser)
  const locale = user?.locale === 'de' ? localeDe : localeEn

  return <ConfigProvider locale={locale}>{children}</ConfigProvider>
}
export default AntDesignConfigProvider
