import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { GroupedMethodologyChangelog, Page } from '@cozero/models'

import methodologyChangelogApiSlice from './api'
import { FIND_METHODOLOGY_CHANGELOG, SLICE_NAME_USER } from './constants'

export interface MethodologyChangelogState {
  methodologyChangelogs: Page<GroupedMethodologyChangelog>
  selectedCalculationChangelogRecord: GroupedMethodologyChangelog | undefined
}

const initialState: MethodologyChangelogState = {
  methodologyChangelogs: {
    total: 0,
    data: [],
  } as Page<GroupedMethodologyChangelog>,
  selectedCalculationChangelogRecord: undefined,
}

const methodlogyChangelogSlice = createSlice({
  name: SLICE_NAME_USER,
  initialState,
  reducers: {
    setMethodlogyChangelog: (state, action: PayloadAction<Page<GroupedMethodologyChangelog>>) => {
      state.methodologyChangelogs = action.payload
    },
    setSelectedCalculationChangelogRecord: (
      state,
      action: PayloadAction<GroupedMethodologyChangelog>,
    ) => {
      state.selectedCalculationChangelogRecord = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      methodologyChangelogApiSlice.endpoints[FIND_METHODOLOGY_CHANGELOG].matchFulfilled,
      (state: MethodologyChangelogState, { payload }) => ({
        ...state,
        methodologyChangelogs: payload,
      }),
    )
  },
})

export const { setMethodlogyChangelog, setSelectedCalculationChangelogRecord } =
  methodlogyChangelogSlice.actions

export default methodlogyChangelogSlice
