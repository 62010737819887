import React, { useEffect, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlinePlus } from 'react-icons/hi'

import { Col, Row, Spin } from 'antd/es'

import Alert from '@/atoms/Alert'
import Button from '@/atoms/Button'

import { useLifecycleContext } from '@/contexts/lifecycle'

import LifecycleModalTitle from '../LifecycleModalTitle'
import { useProductOutletContext } from '../index'
import { LifecycleStepsID, LifecycleStepsKey } from '../steps'

import classes from './classes.module.less'
import { useGenericStep } from './useGenericStep'

interface Props {
  step: LifecycleStepsKey
  stepId: LifecycleStepsID
}

const GenericStepSelector = ({ step, stepId }: Props): JSX.Element => {
  const { t } = useTranslation('common')
  const bottomRef = useRef<HTMLDivElement>(null)
  const { setIsUnique, setCurrentStep, currentStep } = useLifecycleContext()
  const { childRef, onHasFinished, product, supplierProduct } = useProductOutletContext()
  const {
    stepMetadata: { image, description, title },
    loading,
    genericSteps,
    addNewLogEntryForm,
    fetchData,
    resetStep,
    clearState,
    hasAssumptions,
    hasEntries,
  } = useGenericStep({
    product,
    supplierProduct,
    step,
    stepId,
    bottomRef,
  })

  useEffect(() => {
    resetStep()
  }, [step, currentStep])

  useImperativeHandle(childRef, () => ({
    onClearData: () => {
      clearState()
    },
    onNext: async () => {
      clearState()
      onHasFinished()
    },
    onReturn: () => {
      clearState()
      setCurrentStep(currentStep - 1)
    },
  }))

  useEffect(() => {
    if (product && step) {
      fetchData()
    }

    return () => {
      clearState()
    }
  }, [product, step])

  return (
    <Spin spinning={loading}>
      <Row>
        <LifecycleModalTitle image={image} title={title} description={description} />
        {hasAssumptions && !hasEntries && (
          <Alert type="info">{t('product.assumptions-info')} </Alert>
        )}
        {genericSteps.map((step, i) => {
          if (!step.element) {
            return ''
          }

          return (
            <Col key={i} span={24} style={{ marginTop: '16px' }}>
              {step && step.element && (
                <Row className={classes.inputsContainer}>
                  {
                    <step.element.type
                      {...step.element.props}
                      key={i}
                      index={i}
                      hasAssumptions={hasAssumptions}
                      bottomRef={bottomRef}
                    />
                  }
                </Row>
              )}
            </Col>
          )
        })}
        <Col span={24} style={{ marginTop: '16px' }}>
          <Button
            category="logs"
            action="go-to-bulk-import"
            type="secondary"
            className={classes.bulkImportButton}
            onClick={() => {
              setIsUnique(false)
              addNewLogEntryForm()
            }}
            data-cy="bulk-import-btn"
            prefixIcon={<HiOutlinePlus />}
          >
            {`${t('product.lifecycle-steps.add-new-btn.add-new')} ${title.toLowerCase()} ${t(
              'product.lifecycle-steps.add-new-btn.data',
            )}`}
          </Button>
          <div ref={bottomRef} />
        </Col>
      </Row>
    </Spin>
  )
}

export default GenericStepSelector
