import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { Skeleton } from 'antd/es'

import { selectSelectedBusinessUnitKey } from '@/redux/businessUnits'
import { useGetBusinessUnitsQuery } from '@/redux/businessUnits'

const BusinessUnitCrumb = (): JSX.Element => {
  const { id } = useParams()
  const businessUnitKey = useSelector(selectSelectedBusinessUnitKey)
  const { data: businessUnits, isLoading } = useGetBusinessUnitsQuery(
    { businessUnitKey: businessUnitKey ?? '' },
    { skip: !id || !businessUnitKey },
  )

  const unitName = useMemo(() => {
    const businessUnit = businessUnits?.find(
      ({ id: businessUnitId }) => businessUnitId === parseInt(id || ''),
    )
    return businessUnit?.title
  }, [id, businessUnits])

  if (isLoading) {
    return <Skeleton.Button active style={{ maxHeight: 20, width: 30 }} />
  }
  if (!unitName) {
    return <>{id}</>
  }
  return <>{unitName}</>
}

export default BusinessUnitCrumb
