import { RefObject, useEffect, useState } from 'react'

type Options = {
  threshold?: number
  rootMargin?: string
}
/**
 * A hook to use the infamous IntersectionObserver
 * @param elemRef - the element which is to be in the viewport
 * @param {number} threshold - the number of pixels before the element the intersections should occur
 **/
const useIntersectionObserver = (elemRef: RefObject<Element>, options: Options): boolean => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting)
      },
      {
        threshold: options?.threshold ?? 0,
        rootMargin: options?.rootMargin ?? '0px',
      },
    )
    if (elemRef.current) {
      observer.observe(elemRef.current)
    }
    return () => {
      if (elemRef.current) {
        observer.unobserve(elemRef.current)
      }
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting
}

export default useIntersectionObserver
