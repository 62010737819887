import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router'

import { Col, Row, Spin } from 'antd/es'
import { ColumnsType } from 'antd/lib/table'

import moment from 'moment'

import { Product, Quantity, QuantityTag } from '@cozero/models'

import Table from '@/molecules/Table'
import TagInput from '@/molecules/TagInput'

import Button from '@/atoms/Button'
import CustomInputNumber from '@/atoms/CustomInputNumber'
import DateRangePicker from '@/atoms/DateRangePicker'
import Form from '@/atoms/Form'

import quantity from '@/assets/lifecycle-steps/quantity.svg'
import { useLifecycleContext } from '@/contexts/lifecycle'
import useQuantities from '@/hooks/useQuantities'
import useQuantitiesTableColumns from '@/hooks/useQuantitiesTableColumns'

import LifecycleModalTitle from '../LifecycleModalTitle'

import classes from './classes.module.less'

const Quantities = (): JSX.Element => {
  const [form] = Form.useForm()
  const { product, childRef, onHasFinished } = useOutletContext<{
    product: Product
    childRef: React.MutableRefObject<{
      onNext: () => void
      onReturn: () => void
      onClearData: () => void
    }>
    onHasFinished: () => void
  }>()
  const { t } = useTranslation('common')
  const [quantityToEdit, setQuantityToEdit] = useState<Quantity>()
  const { setCurrentStep, currentStep } = useLifecycleContext()

  const totalEmissions: number = useMemo(
    () =>
      product?.productEmissions?.length
        ? product.productEmissions.reduce((acc, { value }) => acc + value, 0)
        : 0,
    [product],
  )

  useImperativeHandle(childRef, () => ({
    onClearData: () => {
      form.resetFields()
    },
    onNext: async () => onHasFinished(),
    onReturn: () => {
      setCurrentStep(currentStep - 1)
    },
  }))

  const {
    getQuantityTags,
    createQuantityTag,
    deleteQuantityTag,
    data,
    loading,
    createQuantity,
    deleteQuantity,
    editQuantity,
  } = useQuantities({
    filters: [
      {
        key: 'productId',
        type: 'relationship',
        label: 'Product',
        selectedCondition: {
          key: 'in',
          label: 'Is',
        },
        logType: 'quantities',
        value: [product?.id || -1],
        conditions: [],
      },
    ],
    sorters: [
      {
        key: 'id',
        label: 'id',
        logType: 'quantities',
        selectedSort: 'desc',
      },
    ],
  })

  const onEditQuantity = async (data: Quantity): Promise<void> => {
    setQuantityToEdit(data)
    form.setFieldsValue({
      volume: data.volume,
      dateInterval: [moment(data.startDate), moment(data.endDate)],
      quantityTags: data.tags.map(({ name }) => name),
    })
  }

  const columnsData = useQuantitiesTableColumns(totalEmissions, deleteQuantity, onEditQuantity)
  const [tags, setTags] = useState<QuantityTag[]>([])

  const onSubmitHandler = async (): Promise<void> => {
    const valid = await form.validateFields()
    const formValues = form.getFieldsValue()
    const dataToCreate = {
      volume: +formValues.volume,
      startDate: formValues.dateInterval[0].toDate(),
      endDate: formValues.dateInterval[1].toDate(),
      tags: formValues.quantityTags
        ? tags.filter((x) => formValues.quantityTags.includes(x.name))
        : [],
      unit: 'units',
    }

    if (valid) {
      if (quantityToEdit) {
        await editQuantity({ ...quantityToEdit, ...dataToCreate })
      } else {
        await createQuantity({ ...dataToCreate, productId: product.id })
      }
    }

    clearForm()
  }

  const clearForm = (): void => {
    setQuantityToEdit(undefined)
    form.resetFields()
  }

  const fetchTags = async (): Promise<void> => {
    const data = await getQuantityTags()
    setTags(data)
  }

  const onCreateTag = async (name: string): Promise<void> => {
    const data = (await createQuantityTag(name)) as QuantityTag
    setTags([...tags, data])
  }

  const onDeleteTag = async (name: string): Promise<void> => {
    await deleteQuantityTag(name)
    fetchTags()
  }

  useEffect(() => {
    fetchTags()
  }, [])

  return (
    <>
      <Spin spinning={loading}>
        <Row>
          <LifecycleModalTitle
            image={quantity}
            title={t('product.lifecycle-steps.quantity.title')}
            description={t('product.lifecycle-steps.quantity.description')}
          />
          <Row>
            <Form
              className={classes.form}
              onFinish={onSubmitHandler}
              category="products"
              action="submit"
              layout="vertical"
              form={form}
            >
              <Row className={classes.inputsContainer}>
                <Row className={classes.fullWidth} justify="space-between">
                  <Col span={11}>
                    <Form.Item
                      name="volume"
                      label={t('product.lifecycle-steps.quantity.number-of-units')}
                      rules={[
                        {
                          required: true,
                          message: `${t('product.lifecycle-steps.quantity.number-of-units')} ${t(
                            'share.success.form.required',
                          )}`,
                        },
                      ]}
                    >
                      <CustomInputNumber className={classes.unitsInput} />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      name="dateInterval"
                      label={t('product.lifecycle-steps.quantity.date-interval')}
                      rules={[
                        {
                          required: true,
                          message: `${t('product.lifecycle-steps.quantity.date-interval')} ${t(
                            'share.success.form.required',
                          )}`,
                        },
                      ]}
                    >
                      <DateRangePicker />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className={classes.fullWidth}>
                  <Col className={classes.marginTop} span={11}>
                    <Form.Item
                      name="quantityTags"
                      label={t('product.lifecycle-steps.quantity.quantity-tags')}
                      rules={[
                        {
                          required: false,
                          message: `${t('product.lifecycle-steps.quantity.quantity-tags')} ${t(
                            'share.success.form.required',
                          )}`,
                        },
                      ]}
                    >
                      <TagInput
                        options={tags.map((x) => x.name) as string[]}
                        onCreateTag={(name) => onCreateTag(name)}
                        onDeleteTag={(name) => onDeleteTag(name)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className={classes.fullWidth} justify="end">
                  <Col>
                    <Button
                      category="customer-requests"
                      action="open-modal"
                      type="secondary"
                      onClick={clearForm}
                    >
                      {t('product.lifecycle-steps.quantity.clear-form')}
                    </Button>
                  </Col>
                  <Col offset={1}>
                    <Button
                      category="customer-requests"
                      action="open-modal"
                      type="primary"
                      htmlType="submit"
                    >
                      {quantityToEdit
                        ? t('product.lifecycle-steps.quantity.edit-quantity')
                        : t('product.lifecycle-steps.quantity.add-quantity')}
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Form>
          </Row>
          <Row className={classes.marginTop}>
            <Col>
              <Table columns={columnsData as ColumnsType<Quantity>} dataSource={data}></Table>
            </Col>
          </Row>
        </Row>
      </Spin>
    </>
  )
}

export default Quantities
