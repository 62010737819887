import { useEffect } from 'react'

export const useMutedLogs = (): void => {
  useEffect(() => {
    /* eslint-disable no-console */
    const originalLogError = console.error

    console.error = (message, ...rest): void => {
      const isAntDeprecation = typeof message === 'string' && message.startsWith('Warning:')

      if (!isAntDeprecation) {
        return originalLogError(message, ...rest)
      }

      if (rest.length === 0) {
        return console.log(message)
      }

      console.groupCollapsed(message)
      console.log(...rest)
      console.groupEnd()
    }
    /* eslint-enable no-console */
  }, [])
}
