import React from 'react'
import { useTranslation } from 'react-i18next'

import { Popconfirm, Spin } from 'antd/es'
import { SortOrder } from 'antd/es/table/interface'

import moment from 'moment'

import { LogSorter, PageFilter, Quantity } from '@cozero/models'

import Table from '@/molecules/Table'

import Button from '@/atoms/Button'
import Text from '@/atoms/Text'

import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin } from '@/redux/auth'
import { SWRProduct } from '@/types/SWRProduct'

import classes from './classes.module.less'

interface Props {
  filters: PageFilter[]
  sorters: LogSorter[]
  onEditQuantity: (quantity: Partial<Quantity>) => void
  quantities?: Quantity[]
  deleteQuantity: (id: number) => Promise<void>
  error?: SWRProduct<Quantity>['error']
  loading: boolean
}

function QuantitiesTable({
  onEditQuantity,
  quantities,
  deleteQuantity,
  error,
  loading,
}: Props): JSX.Element {
  const { t } = useTranslation('common')
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)

  const columns = [
    {
      title: t('quantity.product'),
      dataIndex: 'product',
      key: 'product',
      render(text: string, record: Quantity, index: number) {
        return <span data-cy={'product-log-' + index}>{record.product?.name}</span>
      },
    },
    {
      title: t('quantity.volume'),
      dataIndex: 'volume',
      key: 'volume',
      render(text: string, record: Quantity) {
        return (
          <span>
            {record.volume} {record.unit}
          </span>
        )
      },
    },
    {
      title: t('quantity.startDate'),
      dataIndex: 'startDate',
      key: 'startDate',
      render(text: string, record: Quantity) {
        return <span>{moment(record.startDate).format('LL')}</span>
      },
      sorter: (a: Quantity, b: Quantity) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
      sortDirections: ['ascend', 'descend'] as SortOrder[],
    },
    {
      title: t('quantity.endDate'),
      dataIndex: 'endDate',
      key: 'endDate',
      render(text: string, record: Quantity) {
        return <span>{moment(record.endDate).format('LL')}</span>
      },
      sorter: (a: Quantity, b: Quantity) =>
        b.endDate && a.endDate ? new Date(b.endDate).getTime() - new Date(a.endDate).getTime() : 1,
      sortDirections: ['ascend', 'descend'] as SortOrder[],
    },
    {
      title: t('quantity.customers'),
      key: 'customers',
      render(text: string, record: Quantity) {
        return record.customers?.map((obj) => obj.name).join(', ')
      },
    },
    {
      title: t('quantity.description'),
      dataIndex: 'description',
      key: 'description',
    },
    ...(isManagerOrAdmin
      ? [
          {
            title: t('actions.title'),
            key: 'action',
            render(text: string, record: Quantity) {
              return (
                <>
                  <Button
                    category="quantities"
                    action="Edit Quantity"
                    onClick={() => onEditQuantity(record)}
                    type="secondary"
                    className={classes.editButton}
                  >
                    {t('quantity.edit-btn')}
                  </Button>
                  <Popconfirm
                    title={t('quantity.delete-confirm')}
                    onConfirm={async (e) => {
                      e?.stopPropagation()
                      return await deleteQuantity(record.id)
                    }}
                    onCancel={(e) => {
                      e?.stopPropagation()
                    }}
                    okText={t('yes')}
                    cancelText={t('no')}
                  >
                    <Button
                      category="quantities"
                      action="Delete Quantity"
                      onClick={(e) => e.stopPropagation()}
                      type="primary"
                      color="danger"
                    >
                      {t('quantity.delete-btn')}
                    </Button>
                  </Popconfirm>
                </>
              )
            },
          },
        ]
      : []),
  ]

  if (error) {
    return <Text size="xl">{error.message}</Text>
  }
  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={quantities}
        rowKey="_id"
        className={classes.table}
        showWrapper={false}
      />
    </Spin>
  )
}

export default QuantitiesTable
