import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd'

import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import expiredToken from '@/assets/expired-token.svg'

const ExpiredToken = (): ReactElement => {
  const { t } = useTranslation()
  return (
    <Row gutter={[0, 0]} align="middle">
      <Col span={24} style={{ textAlign: 'center' }}>
        <img src={expiredToken} alt="expired token" />
      </Col>
      <Col span={24} style={{ textAlign: 'center' }}>
        <Row justify="center">
          <Col span={24}>
            <Title size="sm">{t('expired-session.title')}</Title>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={24}>
            <Text size="sm">{t('expired-session.description')}</Text>{' '}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ExpiredToken
