import { Store } from 'rc-field-form/lib/interface'

import { SearchSolution, Solution } from '@cozero/models'
import { actApiGatewayClient } from '@cozero/uris'

import { LIST, PARTIAL_LIST } from '@/redux/types'
import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { SOLUTIONS_TAG } from './tags'

export const GET_SOLUTIONS = 'getSolutions'
export const GET_SOLUTION = 'getSolution'
export const SEARCH_SOLUTIONS = 'searchSolutions'

type GetSolutionsType = { solutions: Solution[]; total: number }

const solutionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_SOLUTIONS]: builder.query<GetSolutionsType | undefined, SearchSolution | void>({
      query: (value) => ({
        url: actApiGatewayClient.solutions.GET_MANY,
        method: 'GET',
        params: {
          query: value?.query ? value.query : {},
          sort: value?.sort ? value.sort : {},
          page: value?.page ? value.page : {},
          pageSize: value?.pageSize ? value.pageSize : {},
        },
      }),
      providesTags: (result) =>
        providesList<Solution[], typeof SOLUTIONS_TAG>(result?.solutions, SOLUTIONS_TAG),
    }),
    [GET_SOLUTION]: builder.query<Solution | undefined, number>({
      query: (id) => ({
        url: actApiGatewayClient.solutions.GET_ONE.replace(':id', id.toString()),
        method: 'GET',
        params: {
          id,
        },
      }),
      providesTags: (result) => [{ type: SOLUTIONS_TAG, id: result?.id }],
    }),
    [SEARCH_SOLUTIONS]: builder.mutation<Solution[] | undefined, Store>({
      query: (query) => ({
        url: actApiGatewayClient.solutions.GET_MANY,
        method: 'GET',
        params: query,
      }),
      invalidatesTags: [
        { type: SOLUTIONS_TAG, id: LIST },
        { type: SOLUTIONS_TAG, id: PARTIAL_LIST },
      ],
    }),
  }),
})

export const {
  useGetSolutionQuery,
  useGetSolutionsQuery,
  useLazyGetSolutionQuery,
  useLazyGetSolutionsQuery,
  useSearchSolutionsMutation,
} = solutionsSlice

export default solutionsSlice
