import { PayloadAction, createSelector } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { Solution } from '@cozero/models'

import type { RootState } from '../'

import { SLICE_NAME_BASKET } from './constants'

type BasketState = {
  solutions: Solution[]
  isOffer: boolean
}

const initialState: BasketState = {
  solutions: [],
  isOffer: false,
}

const basketSlice = createSlice({
  name: SLICE_NAME_BASKET,
  initialState: initialState,
  reducers: {
    addToBasket: (state, action: PayloadAction<Solution>) => {
      state.solutions.push(action.payload)
    },
    removeFromBasket: (state, action: PayloadAction<Solution>) => {
      return {
        ...state,
        solutions: state.solutions.filter((solution) => solution.id !== action.payload.id),
      }
    },
    removeAllFromBasket: () => {
      return initialState
    },
    setIsOffer: (state, action: PayloadAction<boolean>) => {
      state.isOffer = action.payload
    },
  },
})

export const { addToBasket, removeFromBasket, removeAllFromBasket, setIsOffer } =
  basketSlice.actions

export default basketSlice

export const selectBasket = (state: RootState): Solution[] => state.basket.solutions

export const selectIsOffer = (state: RootState): boolean => state.basket.isOffer

export const selectBasketTotal: (state: RootState) => string = createSelector(
  selectBasket,
  (basket) => {
    return basket
      .reduce((amount, solution) => (solution?.averagePrice?.value ?? 0) + amount, 0)
      .toFixed(2)
  },
)
