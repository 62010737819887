import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import axios from '../utils/axios'

interface IAxiosError {
  status: number | undefined
  data: unknown
}

/**
 * Checks if api query/mutation response is an Error.
 * Can be used in situations where we want to handle certain exceptions
 * e.g **error.status === 402**
 * @param error - The redux query error response
 */
export const isAxiosError = (error: unknown): error is IAxiosError => {
  return typeof error === 'object' && error != null && 'status' in error && 'data' in error
}

/**
 * Checks if api query/mutation response is an Error.
 * Can be used in situations where we want to handle certain exceptions
 * e.g **error.status === 402**
 * @param error - The redux query error response
 */
export const isCozeroError = (error: unknown): error is IAxiosError => {
  return typeof error === 'object' && error != null && 'status' in error && 'data' in error
}

export const axiosBaseQuery: BaseQueryFn<
  {
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  },
  unknown,
  { message: string; status?: number; data?: AxiosResponse['data'] }
> = async ({ url, method, data, params }) => {
  try {
    const result = await axios({ url: url, method, data, params })
    return { data: result.data }
  } catch (error) {
    const err = error as AxiosError
    return {
      error: {
        message: err.message,
        status: err.response?.status,
        data: err.response?.data,
      },
    }
  }
}
