import { RcFile } from 'antd/es/upload'

import { PutSignedUrl } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

import { GET_SIGNED_URL, UPLOAD_TO_SIGNED_URL } from './constants'

const filesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_SIGNED_URL]: builder.mutation<PutSignedUrl[], string[]>({
      query: (fileNames) => ({
        url: centralApiGatewayClient.users.SIGNED_PUT_IMAGE_URL,
        method: 'GET',
        params: {
          fileNames: JSON.stringify(fileNames),
        },
      }),
    }),
    [UPLOAD_TO_SIGNED_URL]: builder.mutation<
      { path: string },
      { name: string; fileObj?: RcFile; signedUrl: string; path: string }
    >({
      query: ({ signedUrl, fileObj }) => ({
        url: signedUrl,
        method: 'PUT',
        data: fileObj,
        headers: {
          'Content-Type': fileObj?.type as string,
          'Content-Length': fileObj?.size?.toString() as string,
        },
      }),
    }),
  }),
})

export const { useGetSignedUrlMutation, useUploadFileToSignedUrlMutation } = filesApiSlice

export default filesApiSlice
