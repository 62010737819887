import React, { RefObject, useEffect } from 'react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SupplierProduct } from '..'
import _ from 'lodash-es'

import { Assumption, Product, ProductLogEntry } from '@cozero/models'

import composition from '@/assets/lifecycle-steps/composition.svg'
import distributionAndStorage from '@/assets/lifecycle-steps/distribution-and-storage.svg'
import endOfLife from '@/assets/lifecycle-steps/end-of-life.svg'
import packaging from '@/assets/lifecycle-steps/packaging.svg'
import production from '@/assets/lifecycle-steps/production.svg'
import usage from '@/assets/lifecycle-steps/usage.svg'
import { useLifecycleContext } from '@/contexts/lifecycle'
import useLog from '@/hooks/useLog'
import useProducts from '@/hooks/useProducts'

import GenericStep from '../GenericStep'
import { Field, LifecycleStepsKey, useStep } from '../steps'

interface IUseGenericStep {
  genericSteps: { element: JSX.Element | undefined }[]
  loading: boolean
  stepMetadata: { image: string; title: string; description: string }
  fetchData: () => Promise<void>
  prepareDataToPopulateStep: (initialData?: {
    id?: number
    inputs: { inputKey: string; value: number; unitId: number }[]
    subcategoryId: number
    categoryId: number
    calculationMethodId: number
    activityDataSourceId: number
    productTypeId: number
    territoryId?: number
    title?: string
    supplierId?: number
  }) => {
    element: JSX.Element
  }
  resetStep: () => void
  clearState: () => void
  addNewLogEntryForm: () => void
  hasAssumptions: boolean
  hasEntries: boolean
}

export const useGenericStep = ({
  product,
  supplierProduct,
  step,
  stepId,
  bottomRef,
}: {
  product: Product
  supplierProduct?: SupplierProduct
  step: LifecycleStepsKey
  stepId: number
  bottomRef: RefObject<HTMLDivElement>
}): IUseGenericStep => {
  const { t } = useTranslation('common')
  const [loading, setLoading] = useState(false)

  const productLogEntries = useRef<ProductLogEntry[]>([])
  const genericStepRef = useRef<{ resetFields: () => void }>(null)
  const [genericSteps, setGenericSteps] = useState<{ element: JSX.Element | undefined }[]>([])
  const [stepMetadata, setStepMetadata] = useState({ image: '', title: '', description: '' })
  const [hasAssumptions, setHasAssumptions] = useState(false)
  const [hasEntries, setHasEntries] = useState(false)
  const {
    generateFields,
    compositionOptionalFields,
    productionOptionalFields,
    distributionAndStorageOptionalFields,
  } = useStep()
  const { getAssumptions: getAssumptionsFromApi } = useLog()

  const { getProductLogEntry } = useProducts()
  const { setIsUnique, setStepData } = useLifecycleContext()

  const getAssumptions = async (productTypeId: number, lifecycleStepKey: string): Promise<void> => {
    setLoading(true)
    let assumptions = await getAssumptionsFromApi(productTypeId, lifecycleStepKey)
    const assumptionsMap = new Map<string, Assumption[] | undefined>()
    setGenericSteps([])

    // TODO still need to check cases that we have multiple assumptions for same log entry check bear note
    if (assumptions?.length) {
      setHasAssumptions(true)
      assumptions = assumptions.filter((assumption) => {
        const productTypeMappingAssumptions = assumption.productTypeMappingAssumptions
        assumption.productTypeMappingAssumptions = productTypeMappingAssumptions?.filter(
          (x) =>
            x?.productTypeId === product.productTypeId &&
            x?.activityDataSourceMapping?.subcategory?.category?.stepCategoryMapping?.some(
              (x) => x?.productLifecycleStep?.key === step,
            ),
        )
        return !!assumption.productTypeMappingAssumptions?.length
      })

      // Group assumptions meant to the same log entry
      assumptions.forEach((assumption) => {
        if (assumption?.productTypeMappingAssumptions) {
          assumption.productTypeMappingAssumptions.forEach((productTypeMappingAssumption) => {
            const adsId =
              productTypeMappingAssumption?.activityDataSourceMapping?.activityDataSourceId
            const productTypeId = productTypeMappingAssumption?.productTypeId
            const subcategoryId =
              productTypeMappingAssumption?.activityDataSourceMapping?.subcategoryId
            const categoryId =
              productTypeMappingAssumption?.activityDataSourceMapping?.subcategory?.categoryId

            const calculationMethodId =
              productTypeMappingAssumption?.activityDataSourceMapping?.calculationMethodId
            const key = `${adsId}-${productTypeId}-${subcategoryId}-${calculationMethodId}-${categoryId}`
            const assumptionsArray = assumptionsMap.get(key)
            if (assumptionsArray) {
              assumptionsMap.set(key, [...assumptionsArray, assumption])
            } else {
              assumptionsMap.set(key, [assumption])
            }
          })
        }
      })

      const steps = []
      for (const [key, assumptionsArray] of Array.from(assumptionsMap.entries())) {
        const [adsId, productTypeId, subcategoryId, calculationMethodId, categoryId] =
          key.split('-')
        if (assumptionsArray) {
          const result = prepareDataToPopulateStep({
            activityDataSourceId: Number(adsId),
            productTypeId: Number(productTypeId),
            categoryId: Number(categoryId),
            calculationMethodId: Number(calculationMethodId),
            territoryId: product.territoryId as number,
            inputs: _.uniqBy(
              [
                ...assumptionsArray.map((assumption) => ({
                  inputKey: assumption.userOption?.inputKey as string,
                  value: assumption.value,
                  unitId: assumption.unitId,
                })),
              ],
              'inputKey',
            ),
            subcategoryId: Number(subcategoryId),
          })
          steps.push(result)
        }
      }
      setGenericSteps(steps)
    } else {
      if (!productLogEntries.current?.length) {
        setHasAssumptions(false)
        const result = prepareDataToPopulateStep()
        setGenericSteps((prev) => {
          return [...prev, result]
        })
      }
    }
    setLoading(false)
  }

  const fetchProductLogEntries = async (
    productId: number,
  ): Promise<ProductLogEntry[] | undefined> => {
    const logEntries = (await getProductLogEntry(productId.toString(), stepId)) || []
    productLogEntries.current = logEntries
    const steps = []

    if (logEntries?.length) {
      setHasEntries(true)
    }
    for (const entry of logEntries) {
      const result = prepareDataToPopulateStep({
        id: entry.id,
        activityDataSourceId: entry.activityDataSourceId,
        calculationMethodId: entry.calculationMethodId,
        categoryId: entry.subcategory?.categoryId as number,
        subcategoryId: entry.subcategoryId,
        inputs: entry.inputs,
        productTypeId: product?.productTypeId as number,
        title: entry.title as string,
        territoryId: entry.territoryId as number,
        supplierId: (entry.supplierId ?? undefined) as number,
      })
      steps.push(result)
    }
    setGenericSteps(steps)
    return logEntries
  }

  const fetchData = async (): Promise<void> => {
    setHasEntries(false)
    setHasAssumptions(false)
    setLoading(true)
    const logEntries = await fetchProductLogEntries(product.id)

    if (!logEntries?.length && product?.productTypeId) {
      await getAssumptions(product.productTypeId, step)
    }
    setLoading(false)
  }

  const prepareDataToPopulateStep = (initialData?: {
    id?: number
    inputs: { inputKey: string; value: number; unitId: number }[]
    subcategoryId: number
    categoryId: number
    calculationMethodId: number
    activityDataSourceId: number
    productTypeId: number
    territoryId?: number
    title?: string
    supplierId?: number
  }): {
    element: JSX.Element
  } => {
    let fields: Field[] = []
    switch (step) {
      case LifecycleStepsKey.PRODUCT_COMPOSITION:
        fields = generateFields(compositionOptionalFields)
        setStepMetadata({
          image: composition,
          title: t('product.lifecycle-steps.composition.title'),
          description: t('product.lifecycle-steps.composition.subtitle'),
        })
        break
      case LifecycleStepsKey.PRODUCTION:
        fields = generateFields(productionOptionalFields)
        setStepMetadata({
          image: production,
          title: t('product.lifecycle-steps.production.title'),
          description: t('product.lifecycle-steps.production.subtitle'),
        })
        break
      case LifecycleStepsKey.DISTRIBUTION_AND_STORAGE:
        fields = generateFields(distributionAndStorageOptionalFields)
        setStepMetadata({
          image: distributionAndStorage,
          title: t('product.lifecycle-steps.distribution-and-storage.title'),
          description: t('product.lifecycle-steps.distribution-and-storage.subtitle'),
        })
        break
      case LifecycleStepsKey.END_OF_LIFE:
        fields = generateFields()
        setStepMetadata({
          image: usage,
          title: t('product.lifecycle-steps.end-of-life.title'),
          description: t('product.lifecycle-steps.end-of-life.subtitle'),
        })
        break
      case LifecycleStepsKey.PACKAGING:
        fields = generateFields()
        setStepMetadata({
          image: endOfLife,
          title: t('product.lifecycle-steps.packaging.title'),
          description: t('product.lifecycle-steps.packaging.subtitle'),
        })
        break
      case LifecycleStepsKey.USAGE:
        fields = generateFields()
        setStepMetadata({
          image: packaging,
          title: t('product.lifecycle-steps.usage.title'),
          description: t('product.lifecycle-steps.usage.subtitle'),
        })
        break
    }

    return {
      element: (
        <GenericStep
          productlifecycleStep={step}
          childRef={genericStepRef}
          product={product}
          index={-1}
          initialData={initialData}
          fields={fields}
          deleteEntry={deleteEntry}
        />
      ),
    }
  }

  const resetStep = (): void => {
    setGenericSteps([prepareDataToPopulateStep()])
  }

  const clearState = (): void => {
    setGenericSteps([])
    productLogEntries.current = []
    genericStepRef.current?.resetFields()
  }

  const deleteEntry = (index: number): void => {
    setStepData((prev) => {
      prev.splice(index, 1)
      return [...prev]
    })

    setGenericSteps((prev) => {
      prev.splice(index, 1)
      setIsUnique(prev.filter(({ element }) => element).length === 1)
      return [...prev]
    })
  }

  const addNewLogEntryForm = (): void => {
    const result = prepareDataToPopulateStep()

    setGenericSteps((prev) => {
      return [...prev, result]
    })
  }

  useEffect(() => {
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 10)
  }, [genericSteps])

  return {
    stepMetadata,
    loading,
    genericSteps,
    addNewLogEntryForm,
    fetchData,
    prepareDataToPopulateStep,
    resetStep,
    clearState,
    hasAssumptions,
    hasEntries,
  }
}
