export const SLICE_NAME_USER = 'auth'

// API Tags
export const TAG_USER = 'USER'
export default [TAG_USER]

// API slice endpoints
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const LOGIN_WITH_TOKEN = 'loginWithToken'
export const LOGIN_WITH_JWT_TOKEN = 'loginWithJwtToken'
export const GET_LOGIN_METHOD = 'getLoginMethod'
export const GET_USER_DATA = 'getUserData'
export const ACTIVATE_USER = 'activateUser'
