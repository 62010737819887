import { useMemo } from 'react'

import isEqual from 'lodash/isEqual'
import useSWR from 'swr'

import { LogSorter, PageFilter, TableViewProductLog } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import { SWRProduct } from '../types/SWRProduct'
import axios from '../utils/axios'

import useMemoCompare from './useMemoCompare'

/**
 * Hook to search for logs.
 * @param {Object} params
 * @param {number} params.page
 * @param {number} params.pageSize
 * @param {PageFilter[]} params.filters
 * @param {LogSorter[]} params.sorters
 */
const useProductData = ({
  page,
  pageSize,
  filters,
  sorters,
  businessUnitKey,
}: {
  type: string
  page: number
  pageSize: number
  filters?: Omit<PageFilter, 'options'>[]
  sorters?: LogSorter[]
  businessUnitKey?: string
}): SWRProduct<{ products: TableViewProductLog[]; totalProducts: number }> => {
  /**
   *
   * We have to make sure that the filters actually change every time
   */
  const memoedFilters = useMemoCompare<Omit<PageFilter, 'options'>[] | undefined>(
    filters,
    (
      prevFilters: Omit<PageFilter, 'options'>[] | undefined,
      nextFilters: Omit<PageFilter, 'options'>[] | undefined,
    ) => isEqual(prevFilters, nextFilters),
  )
  // same for sorters
  const memoedSorters = useMemoCompare<LogSorter[] | undefined>(
    sorters,
    (prevFilters: LogSorter[] | undefined, nextFilters: LogSorter[] | undefined) =>
      isEqual(prevFilters, nextFilters),
  )

  const { data, mutate, error, isValidating } = useSWR(
    [
      centralApiGatewayClient.products.GET_DATA,
      useMemo(
        (): {
          page: number
          pageSize: number
          filters?: Omit<PageFilter, 'options'>[]
          sorters?: LogSorter[]
          businessUnitKey?: string
        } => ({
          page,
          pageSize,
          filters: memoedFilters,
          sorters: memoedSorters,
          businessUnitKey,
        }),
        [page, pageSize, memoedFilters, memoedSorters, businessUnitKey],
      ),
    ],
    async (url, { page, pageSize, filters, sorters, businessUnitKey }) => {
      const { data } = await axios.post<{ products: TableViewProductLog[]; totalProducts: number }>(
        url,
        {
          query: { page, pageSize, filters },
          businessUnitKey,
          sort: sorters,
        },
      )
      return data
    },
    { revalidateOnFocus: false },
  )

  return {
    data,
    mutate,
    error,
    loading: !error && isValidating,
  }
}

export default useProductData
