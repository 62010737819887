import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { FactorRequest } from '@cozero/models'

import factorsRequestsApiSlice from './api'
import { FIND_FACTOR_REQUEST_SUPPLIER, SLICE_NAME_USER } from './constants'

export interface FactorRequestState {
  supplierRequests: FactorRequest[]
}

const initialState: FactorRequestState = {
  supplierRequests: [],
}

const factorRequestSlice = createSlice({
  name: SLICE_NAME_USER,
  initialState,
  reducers: {
    setFactorRequest: (state, action: PayloadAction<FactorRequest[]>) => {
      state.supplierRequests = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      factorsRequestsApiSlice.endpoints[FIND_FACTOR_REQUEST_SUPPLIER].matchFulfilled,
      (state: FactorRequestState, { payload }) => ({
        ...state,
        supplierRequests: payload,
      }),
    )
  },
})

export const { setFactorRequest } = factorRequestSlice.actions

export default factorRequestSlice
