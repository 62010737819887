import { useEffect, useRef, useState } from 'react'

type UseImageState = {
  imgRef: React.MutableRefObject<HTMLImageElement | null | undefined>
  error: boolean
  loading: boolean
  setImgRef: (img: HTMLImageElement | null) => void
}

/**
 * Custom hook to handle state for an image element.
 * @returns {UseImageState}
 **/
const useImageState = (): UseImageState => {
  const imgRef = useRef<HTMLImageElement | null>() // contains image reference
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const setImgRef = (img: HTMLImageElement | null): void => {
    imgRef.current = img
  }

  const _handleError = (): void => {
    setError(true)
    return setLoading(false)
  }

  const _handleLoad = (): void => {
    setError(false)
    setLoading(false)
  }

  useEffect(() => {
    if (imgRef.current) {
      imgRef.current.addEventListener('error', _handleError)
      imgRef.current.addEventListener('load', _handleLoad)
    }
    return () => {
      if (imgRef.current) {
        imgRef.current.removeEventListener('error', _handleError)
        imgRef.current.removeEventListener('load', _handleLoad)
      }
    }
  }, [imgRef])

  return {
    imgRef,
    error,
    loading,
    setImgRef,
  }
}

export default useImageState
