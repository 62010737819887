import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'

import { config } from '@/utils/config'

const NEW_RELIC_BEACON_URL = 'bam.eu01.nr-data.net'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    newrelic: any
  }
}

if (['staging', 'production'].includes(config.NODE_ENV)) {
  const apiOrigin = new URL(config.API_URL).origin

  const options = {
    init: {
      distributed_tracing: {
        enabled: true,
        cors_use_newrelic_header: true,
        cors_use_tracecontext_headers: true,
        allowed_origins: [apiOrigin],
      },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: [NEW_RELIC_BEACON_URL] },
    },
    info: {
      beacon: NEW_RELIC_BEACON_URL,
      errorBeacon: NEW_RELIC_BEACON_URL,
      applicationID: config.NEW_RELIC_APPLICATION_ID,
      licenseKey: config.NEW_RELIC_LICENSE_KEY,
      sa: 1,
    },
    loader_config: {
      accountID: config.NEW_RELIC_ACCOUNT_ID,
      trustKey: config.NEW_RELIC_ACCOUNT_ID,
      agentID: config.NEW_RELIC_APPLICATION_ID,
      applicationID: config.NEW_RELIC_APPLICATION_ID,
      licenseKey: config.NEW_RELIC_LICENSE_KEY,
    },
  }

  new BrowserAgent(options)

  if (window?.newrelic?.addRelease) {
    // Will maintain this comment for testing on staging and then remove it
    // eslint-disable-next-line no-console
    console.log(
      `Added New Relic release, release ID: ${config.NEW_RELIC_RELEASE_ID} | Release name: ${config.NEW_RELIC_RELEASE_NAME}`,
    )

    window.newrelic.addRelease(config.NEW_RELIC_RELEASE_NAME, config.NEW_RELIC_RELEASE_ID)
  }
}
