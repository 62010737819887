import React from 'react'
import { useTranslation } from 'react-i18next'
import { HiFlag } from 'react-icons/hi'

import Text from '@/atoms/Text'

import { CINDER_BLUE_50 } from '@/styles/variables'

import classes from './ErrorGraphView.module.less'

const ErrorGraphView = (): JSX.Element => {
  const { t } = useTranslation('common')

  return (
    <div className={classes.errorContainer}>
      <div className={classes.header}>
        <HiFlag size={20} color={CINDER_BLUE_50} className={classes.icon} />
        <Text fontWeight="semibold" mode="title" size="xs" color="secondary">
          {t('share.reports.errors.load.title')}
        </Text>
      </div>
      <Text fontWeight="medium" size="lg" color="disabled">
        {t('share.reports.errors.load.description')}
      </Text>
    </div>
  )
}

export default ErrorGraphView
