import React from 'react'

import { CINDER_BLUE_50 } from '@/styles/variables'
import { IconProps } from '@/types/general'

const SixDotsVertical = ({
  width = 24,
  height = 24,
  color = CINDER_BLUE_50,
  ...rest
}: IconProps): JSX.Element => {
  return (
    <svg width={width} height={height} {...rest} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 5C9 3.89543 8.10457 3 7 3C5.89543 3 5 3.89543 5 5C5 6.10457 5.89543 7 7 7C8.10457 7 9 6.10457 9 5ZM9 12C9 10.8954 8.10457 10 7 10C5.89543 10 5 10.8954 5 12C5 13.1046 5.89543 14 7 14C8.10457 14 9 13.1046 9 12ZM9 19C9 17.8954 8.10457 17 7 17C5.89543 17 5 17.8954 5 19C5 20.1046 5.89543 21 7 21C8.10457 21 9 20.1046 9 19ZM19 5C19 3.89543 18.1046 3 17 3C15.8954 3 15 3.89543 15 5C15 6.10457 15.8954 7 17 7C18.1046 7 19 6.10457 19 5ZM19 12C19 10.8954 18.1046 10 17 10C15.8954 10 15 10.8954 15 12C15 13.1046 15.8954 14 17 14C18.1046 14 19 13.1046 19 12ZM19 19C19 17.8954 18.1046 17 17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19Z"
        fill={color}
      />
    </svg>
  )
}

export default SixDotsVertical
