import { RootState } from '..'

import { PageFilter } from '@cozero/models'

import { FilterKey, PageType } from '@/molecules/FiltersDrawer'

import { SLICE_NAME_FILTER } from './constants'

export const selectProductsFilters = (
  state: RootState,
):
  | (PageFilter & {
      key: FilterKey
    })[]
  | null => state[SLICE_NAME_FILTER][PageType.PRODUCT]
