import React, { useMemo } from 'react'
import { useEffect, useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

import { Types } from '@antv/g2'
import { ColorAttr, Options } from '@antv/g2plot'
import { ChartPivotRow } from '@cubejs-client/core'

import { ReportType } from '@cozero/models'

import GraphTooltip from '../molecules/GraphTooltip'
import { colors, scopeAttributes } from '../styles/graphs'
import { CINDER_BLUE_40 } from '../styles/variables'

interface IUseGraphs {
  graphStyles: Omit<Options, 'data'>
  customTooltip: Types.TooltipCfg
  graphDataDepth: number
}

interface IUseGraphsProps {
  graphType: ReportType
  switchAxis?: boolean
  dimensionType?: string
  graphData?: ChartPivotRow[]
}

const useGraphs = ({
  graphType,
  switchAxis,
  dimensionType,
  graphData,
}: IUseGraphsProps): IUseGraphs => {
  const [graphStyles, setGraphStyles] = useState<Omit<Options, 'data'>>({ color: colors })
  const scopeColoredDimensions = ['Scopes.category']
  const isGraphAllowedScopeColors = (): boolean => {
    if (graphType === ReportType.BAR && switchAxis && dimensionType === 'Categories.scope') {
      return false
    } else {
      return true
    }
  }

  const isScopeBasedData = (): boolean =>
    dimensionType !== undefined &&
    scopeColoredDimensions.some((dimension) => dimension === dimensionType)

  useEffect(() => {
    setGraphStyles({
      color: isGraphAllowedScopeColors() && isScopeBasedData() ? scopeColors : colors,
    })
  }, [switchAxis, dimensionType, graphType])

  const scopeColors: ColorAttr = (datum) => {
    const scope = scopeAttributes.find(({ scope }) => {
      if (graphType === ReportType.SUNBURST && datum['ancestor-node']) {
        return `${datum['ancestor-node']}` === `${scope}`
      } else if (datum.type) {
        return `${datum.type}` === `${scope}`
      } else if (datum.title) {
        return `${datum.title}` === `${scope}`
      } else if (datum.x) {
        return `${datum.x}` === `${scope}`
      } else if (datum.name) {
        return `${datum.name}` === `${scope}`
      } else {
        return false
      }
    })
    return scope ? scope.color : CINDER_BLUE_40
  }

  const customTooltip = useMemo(
    (): Types.TooltipCfg => ({
      shared: true,
      ...GraphTooltip.defaultConfig,
      customContent: (title, data) => {
        const parsedData = data.map((item) => ({
          color: item.color,
          value: item.value,
          title: item.name,
        }))
        return renderToStaticMarkup(
          <GraphTooltip
            title={
              graphType === ReportType.SANKEY ||
              graphType === ReportType.SUNBURST ||
              graphType === ReportType.PIE
                ? undefined
                : title
            }
            data={parsedData}
          />,
        )
      },
    }),
    [],
  )

  const graphDataDepth = useMemo(() => {
    return graphData ? graphData[0].xValues.length : 0
  }, [graphData])

  return { graphStyles, customTooltip, graphDataDepth }
}

export default useGraphs
