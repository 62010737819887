import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router'

import { Skeleton } from 'antd/es'

import { useClosedPeriod } from '@/hooks/useClosedPeriod'

const CustomReportCrumb = (): JSX.Element => {
  const { closedPeriod, getClosedPeriod } = useClosedPeriod()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const unitName = useMemo(() => {
    if (closedPeriod) {
      setLoading(false)
      return closedPeriod.name
    } else {
      getClosedPeriod(id as string)
    }
  }, [closedPeriod, id])

  if (loading) {
    return <Skeleton.Button active style={{ maxHeight: 20, width: 30 }} />
  }
  if (!unitName) {
    return <>{id}</>
  }
  return <>{unitName}</>
}

export default CustomReportCrumb
