export const getStoredItem = (key: string): unknown => {
  const item = window.localStorage.getItem(key)
  if (item) {
    try {
      return JSON.parse(item)
    } catch {
      return undefined
    }
  }
  return undefined
}

const DESTINATION = 'COZERO_DESTINATION'

export const setDestination = (): void => {
  window.localStorage.setItem(DESTINATION, window.location.pathname)
}

export const getDestination = (): string | null => {
  const destination = window.localStorage.getItem(DESTINATION)
  if (destination) {
    window.localStorage.removeItem(DESTINATION)
  }
  return destination
}
