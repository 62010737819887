import { ContactForm, FormValues } from './types'

type IsSupplierFormValidArg = Partial<Omit<FormValues, 'contact'>> & {
  contact: Partial<ContactForm>[]
}

export const REQUIRED_FIELDS: Array<keyof FormValues | `contact.${keyof ContactForm}`> = [
  'name',
  'contact.email',
]

export function isSupplierFormValid(formFields: IsSupplierFormValidArg): boolean {
  return REQUIRED_FIELDS.every((field) => {
    const [parent, child] = field.split('.') as ['contact', keyof ContactForm]
    if (Array.isArray(formFields[parent])) {
      return (formFields[parent] as ContactForm[]).every((el) => el && !!el[child])
    } else {
      return !!formFields[field as keyof FormValues]
    }
  })
}
