import { config } from '@/utils/config'

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

const reportWebVitals = (onPerfEntry: any) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry)
      getFID(onPerfEntry)
      getFCP(onPerfEntry)
      getLCP(onPerfEntry)
      getTTFB(onPerfEntry)
    })
  }
}
const vitalsUrl = 'https://vitals.vercel-analytics.com/v1/vitals'

const getConnectionSpeed = () => {
  return 'connection' in navigator &&
    navigator['connection'] &&
    'effectiveType' in (navigator['connection'] as { effectiveType: any })
    ? (navigator['connection'] as { effectiveType: any })['effectiveType']
    : ''
}

const sendToVercelAnalytics = (metric: any) => {
  const analyticsId =
    process.env.VITE_VERCEL_ANALYTICS_ID ||
    process.env.REACT_APP_VERCEL_ANALYTICS_ID ||
    process.env.VERCEL_ANALYTICS_ID
  if (!analyticsId) {
    return
  }

  const body = {
    dsn: analyticsId,
    id: metric.id,
    page: window.location.pathname,
    href: window.location.href,
    event_name: metric.name,
    value: metric.value.toString(),
    speed: getConnectionSpeed(),
  }

  const blob = new Blob([new URLSearchParams(body).toString()], {
    // This content type is necessary for `sendBeacon`
    type: 'application/x-www-form-urlencoded',
  })
  if (navigator.sendBeacon) {
    navigator.sendBeacon(vitalsUrl, blob)
  } else {
    fetch(vitalsUrl, {
      body: blob,
      method: 'POST',
      credentials: 'omit',
      keepalive: true,
    })
  }
}

if (config.NODE_ENV === 'production') {
  reportWebVitals(sendToVercelAnalytics)
}
