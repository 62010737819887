import React from 'react'

import { CINDER_BLUE_80 } from '@/styles/variables'
import { IconProps } from '@/types/general'

const Sunburst = ({ width = 24, height = 24, color = CINDER_BLUE_80 }: IconProps): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0107 4.06101C7.05925 4.54819 4 7.91691 4 12.0002C4 12.6454 4.07639 13.2728 4.22063 13.8738L7.21209 12.733C7.17947 12.5054 7.1626 12.2726 7.1626 12.0359C7.1626 9.69465 8.81397 7.73907 11.0155 7.27039L11.0107 4.06101ZM7.89837 14.6118L4.92852 15.7444C6.27167 18.2757 8.93433 19.9998 11.9997 19.9998C15.0619 19.9998 17.7223 18.2792 19.0668 15.752L16.1605 14.6294C15.298 15.9986 13.7727 16.9083 12.035 16.9083C10.2898 16.9083 8.75888 15.9908 7.89837 14.6118ZM19.9993 12.0002C19.9993 12.6484 19.9222 13.2786 19.7767 13.8822L16.8549 12.7536C16.8894 12.5194 16.9073 12.2798 16.9073 12.0359C16.9073 9.68087 15.2365 7.71605 13.0155 7.26227L13.0107 4.06377C16.9515 4.56078 19.9993 7.92454 19.9993 12.0002ZM2 12.0002C2 6.47749 6.47701 2.00049 11.9997 2.00049C17.5223 2.00049 21.9993 6.47749 21.9993 12.0002C21.9993 17.5228 17.5223 21.9998 11.9997 21.9998C6.47701 21.9998 2 17.5228 2 12.0002ZM12.035 9.16357C10.4486 9.16357 9.1626 10.4496 9.1626 12.0359C9.1626 13.6223 10.4486 14.9083 12.035 14.9083C13.6213 14.9083 14.9073 13.6223 14.9073 12.0359C14.9073 10.4496 13.6213 9.16357 12.035 9.16357Z"
        fill={color}
      />
    </svg>
  )
}

export default Sunburst
