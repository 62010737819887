import '@/integrations/new-relic'
import '@/integrations/vercel'
import '@csstools/normalize.css'
import 'antd/dist/antd.less'
import 'core-js/es/global-this'
import 'core-js/features/array/at'
import 'moment/locale/de'

import React from 'react'
import { createRoot } from 'react-dom/client'

import '@/main.css'

import { App } from './App'

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App />)
