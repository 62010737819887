import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Target } from '@cozero/models'

import targetsApiSlice, { DELETE_TARGET } from '../targets/api'

import { SLICE_NAME_TARGETS } from './constants'

// Define a type for the slice state
export interface SelectedTargetsState {
  selectedTargets: Target['id'][]
  strategyBuilderSelectedTarget: Target['id'] | undefined
}

// Define the initial state using that type
const initialState: SelectedTargetsState = {
  selectedTargets: [],
  strategyBuilderSelectedTarget: undefined,
}

const targetsSlice = createSlice({
  name: SLICE_NAME_TARGETS,
  initialState: initialState,
  reducers: {
    setSelectedTargets: (state, action: PayloadAction<Target['id'][]>) => {
      state.selectedTargets = action.payload
    },
    setStrategyBuilderSelectedTarget: (state, action: PayloadAction<Target['id'] | undefined>) => {
      state.strategyBuilderSelectedTarget = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      targetsApiSlice.endpoints[DELETE_TARGET].matchFulfilled,
      (state: SelectedTargetsState, { payload }) => {
        state.selectedTargets = state.selectedTargets.filter((target) => target !== payload.id)
      },
    )
  },
})

export const { setSelectedTargets, setStrategyBuilderSelectedTarget } = targetsSlice.actions

export default targetsSlice
