import useSWR from 'swr'

import { Territory } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import axios from '../utils/axios'

type UseTerritories = {
  territories?: Territory[]
  mutate: (territories: Territory[]) => void
  error: Error
  loading: boolean
}

/**
 * Hook to fetch territories from the API
 * @param {string} section which section to fetch territories for
 * @returns {UseTerritories}
 */
const useTerritories = (): UseTerritories => {
  const {
    data: territories,
    mutate,
    error,
    isValidating,
  } = useSWR<Territory[]>(
    [logApiGatewayClient.territories.GET_MANY],
    async (uri) => {
      const { data } = await axios.get<Territory[]>(uri)
      return data
    },
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false },
  )

  return {
    territories,
    mutate,
    error,
    loading: !error && isValidating,
  }
}
export default useTerritories
