import React, { ReactElement, useMemo } from 'react'

import { ImageSkeletonProps, ParagraphSkeletonProps } from './Skeleton'
import classes from './Skeleton.module.less'

export const Image = ({
  className,
  children,
  size = 200,
  active = true,
  loading = false,
}: ImageSkeletonProps): ReactElement => {
  const skeletonClassNames = useMemo(
    () => [active ? classes.active : '', loading ? classes.image : '', className].join(' '),
    [loading, active, size, className],
  )
  return (
    <div style={{ width: size, height: size }} className={skeletonClassNames}>
      {!loading ? children : ''}
    </div>
  )
}

export const Paragraph = ({
  className,
  children,
  active = true,
  loading = false,
  size = 'md',
}: ParagraphSkeletonProps): JSX.Element => {
  const skeletonClassNames = useMemo(
    () =>
      [
        classes[size],
        active ? classes.active : '',
        loading ? classes.paragraph : '',
        className,
      ].join(' '),
    [loading, active, size, className],
  )
  const isSkeletonVisible = loading ? skeletonClassNames : ''
  return <div className={isSkeletonVisible}>{!loading ? children : ''}</div>
}
