import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import {
  Limit,
  Organization,
  Subscription,
  SubscriptionCheckoutSession,
  SubscriptionProduct,
} from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import {
  getCheckoutSession as fetchCheckoutSession,
  getCheckoutUrl as fetchCheckoutUrl,
  getCustomerPortalUrl as fetchCustomerPortalUrl,
  getSubscription as fetchSubscription,
  getSubscriptionProducts as fetchSubscriptionProducts,
  getCalendarUrl,
} from '../backend/admin/subscription'
import { SubscriptionContextInterface } from '../contexts/subscription'
import { useAppSelector } from '../redux'
import { selectUser } from '../redux/auth'
import axios from '../utils/axios'
import { config } from '../utils/config'

const useSubscriptionContext = (): SubscriptionContextInterface => {
  const { t } = useTranslation('common')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const user = useAppSelector(selectUser)
  const [subscribeModalSettings, setSubscribeModalSettings] = useState<{
    title: string
    closable: boolean
    visible: boolean
  }>({ title: t('subscription.upgrade-modal.title-default'), closable: true, visible: false })
  const [subscription, setSubscription] = useState<Subscription>()
  const [subscriptionProducts, setSubscriptionProducts] = useState<SubscriptionProduct[]>([])
  const [checkoutSession, setCheckoutSession] = useState<SubscriptionCheckoutSession>()
  const [subscriptionExpired, setSubscriptionExpired] = useState(false)

  async function getCalendarLink(): Promise<string | void> {
    try {
      setLoading(true)
      return await getCalendarUrl()
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  async function createHubspotLead(data: Record<string, string>): Promise<void> {
    try {
      const portalId = `${config.HUBSPOT_FORM_PORTAL_ID}`
      const formGuid = `${config.HUBSPOT_FORM_KEY}`
      const body = {
        ...data,
        portalId,
        formGuid,
      }
      await axios.post(centralApiGatewayClient.subscriptions.CONTACT_SALES, body)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  async function getCustomerPortalUrl(): Promise<string | void> {
    try {
      setLoading(true)
      return await fetchCustomerPortalUrl()
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  async function getCheckoutUrl(productId: string): Promise<string | void> {
    try {
      setLoading(true)
      return await fetchCheckoutUrl(productId)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  async function getSubscription(): Promise<Subscription | void> {
    try {
      setLoading(true)
      const subscription = await fetchSubscription()
      setSubscription(subscription)
      return subscription
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  async function getSubscriptionProducts(): Promise<SubscriptionProduct[] | void> {
    try {
      setLoading(true)
      const fetchedProducts = await fetchSubscriptionProducts()
      setSubscriptionProducts(fetchedProducts)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  async function getCheckoutSession(
    sessionId: string,
  ): Promise<SubscriptionCheckoutSession | void> {
    try {
      setLoading(true)
      const checkoutSession = await fetchCheckoutSession(sessionId)
      setCheckoutSession(checkoutSession)
      return checkoutSession
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  function getLimit(
    organization: Organization | undefined,
    featureName: string,
  ): Limit | undefined {
    return organization?.pricing?.limits?.find((limit) => limit?.feature?.key === featureName)
  }

  useEffect(() => {
    if (user?.organization) {
      const organization = user.organization as Organization
      let subscriptionExpired = false
      if (
        organization.subscriptionPeriodEnd &&
        moment(organization?.subscriptionPeriodEnd).diff(moment()) < 0
      ) {
        subscriptionExpired = true
      }
      if (
        organization?.pricing?.key === 'free' &&
        organization?.pricing?.daysOfTrialPeriod &&
        moment().isAfter(
          moment(organization.createdAt).add(organization?.pricing?.daysOfTrialPeriod, 'days'),
          'day',
        )
      ) {
        subscriptionExpired = true
      }
      setSubscriptionExpired(subscriptionExpired)
    }
  }, [user?.organization])

  return {
    getCalendarLink,
    subscribeModalSettings,
    setSubscribeModalSettings,
    error,
    loading,
    subscription,
    getSubscription,
    getCustomerPortalUrl,
    getSubscriptionProducts,
    subscriptionProducts,
    getCheckoutSession,
    checkoutSession,
    getLimit,
    subscriptionExpired,
    getCheckoutUrl,
    createHubspotLead,
  }
}

export default useSubscriptionContext
