import React, { ReactElement, ReactNode, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Row } from 'antd'

import { FactorRequest } from '@cozero/models'

import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'
import { selectSupplierFactorRequests } from '@/redux/factors-requests'
import { useFindFactorRequestSupplierMutation } from '@/redux/factors-requests/api'

import logo from '../../assets/COZERO_LOGO_WHITE.svg'
import NavUserMenu from '../NavUserMenu'

import classes from './NavOnboardingSupplierSidebar.module.less'

const NavOnboardingSupplierSidebar = ({
  hideUserMenu,
}: {
  hideUserMenu: boolean
}): ReactElement => {
  const factorRequests = useAppSelector(selectSupplierFactorRequests)

  return (
    <aside className={classes.sider}>
      <div className={classes.siderInnerContainer}>
        <Row justify="center">
          <img src={logo} width="170" />
        </Row>
        {factorRequests[0] && factorRequests[0].organization.logo?.url && (
          <>
            <Row justify="center" className={classes.divider}>
              X
            </Row>
            <Row justify="center" className={classes.divider}>
              <img src={factorRequests[0].organization.logo?.url} width="170" />
            </Row>
          </>
        )}
      </div>
      <div>{!hideUserMenu && <NavUserMenu />}</div>
    </aside>
  )
}

export default memo(NavOnboardingSupplierSidebar)
