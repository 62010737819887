import { useEffect } from 'react'

import i18n, { isSupportedLocale } from '@/i18n'
import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'

/**
 * Hook to get the user's locale and set the locale
 * @returns {UseUserLocale} locale and setLocale function
 */
const useUserLocale = (): void => {
  const user = useAppSelector(selectUser)

  useEffect(() => {
    if (user && isSupportedLocale(user.locale)) {
      i18n.changeLanguage(user.locale)
    }
  }, [user])
}
export default useUserLocale
