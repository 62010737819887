import { useMemo, useState } from 'react'

import { omit } from 'lodash-es'
import isEqual from 'lodash/isEqual'
import useSWR from 'swr'

import { LogSorter, PageFilter, Quantity, QuantityTag } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { SWRProduct } from '../types/SWRProduct'
import axios from '../utils/axios'

import useMemoCompare from './useMemoCompare'

interface QuantitiesHook extends SWRProduct<Quantity[]> {
  editQuantity: (body: Partial<Quantity>) => Promise<Quantity>
  deleteQuantity: (id: number) => Promise<void>
  createQuantity: (body: Partial<Quantity>) => Promise<Quantity>
  getQuantityTags: (pattern?: string) => Promise<QuantityTag[]>
  createQuantityTag: (name: string) => Promise<QuantityTag | undefined>
  deleteQuantityTag: (tagId: string | number) => Promise<void>
}

/**
 * New hook that handles all API related matters related to the quantities
 * @param {Object} params
 * @param {PageFilter[]} params.filters
 * @param {LogSorter[]} params.sorters
 */
const useQuantities = ({
  filters,
  sorters,
}: {
  filters?: PageFilter[]
  sorters?: LogSorter[]
}): QuantitiesHook => {
  const [internalLoading, setInternalLoading] = useState(false)

  /**
  /**
   * To stop possible infinite loops
   * We have to make sure that the filters actually change every time
   */
  const memoedFilters = useMemoCompare<PageFilter[] | undefined>(
    filters,
    (prevFilters: PageFilter[] | undefined, nextFilters: PageFilter[] | undefined) =>
      isEqual(prevFilters, nextFilters),
  )
  // same for sorters
  const memoedSorters = useMemoCompare<LogSorter[] | undefined>(
    sorters,
    (prevFilters: LogSorter[] | undefined, nextFilters: LogSorter[] | undefined) =>
      isEqual(prevFilters, nextFilters),
  )

  const { data, error, isValidating, mutate } = useSWR<Quantity[]>(
    [
      logApiGatewayClient.quantities.SEARCH,
      useMemo<{
        filters?: PageFilter[]
        sorters?: LogSorter[]
      }>(
        (): { filters?: PageFilter[]; sorters?: LogSorter[] } => ({
          filters: memoedFilters?.map((x) => omit(x, 'options')) as PageFilter[],
          sorters: memoedSorters,
        }),
        [memoedSorters, memoedFilters],
      ),
    ],
    // since we are not using a get request, we provide our own post req
    async (url, { filters, sorters }) => {
      const { data } = await axios.post<Quantity[]>(url, { query: { filters }, sort: sorters })
      return data
    },
  )

  /**
   * Edit the quantity
   * @param {Partial<Quantity>} body the quantity body
   * @returns {Quantity} The new quantity
   */
  const editQuantity = async (body: Partial<Quantity>): Promise<Quantity> => {
    const { data: quantity } = await axios.put<Quantity>(
      logApiGatewayClient.quantities.UPDATE_ONE.replace(':id', `${body.id}`),
      body,
    )
    if (data) {
      const index = data?.findIndex((x) => x.id === quantity.id)
      data[index] = quantity
      mutate([...data])
    }
    return quantity
  }

  /**
   * Edit the quantity
   * @param {string} id - the quantity id
   * @returns {void}
   */
  const deleteQuantity = async (id: number): Promise<void> => {
    setInternalLoading(true)
    const { data } = await axios.delete<void>(
      logApiGatewayClient.quantities.DELETE_ONE.replace(':id', `${id}`),
    )
    // Mutate the search cache if it exists
    mutate()
    setInternalLoading(false)
    return data
  }

  /**
   * Create a new quantity
   * @param {Partial<Quantity>} body
   * @returns {Quantity} the new quantity
   */
  const createQuantity = async (body: Partial<Quantity>): Promise<Quantity> => {
    setInternalLoading(true)
    const { data: quantity } = await axios.post<Quantity>(logApiGatewayClient.quantities.CREATE, {
      productId: body.product?.id ?? body.productId,
      customerIds: body.customers?.map((obj) => obj.id),
      startDate: body.startDate,
      endDate: body.endDate,
      volume: body.volume,
      description: body.description,
      unit: body.unit,
      tags: body.tags,
    })
    mutate(data ? [quantity, ...data] : [])
    setInternalLoading(false)
    return quantity
  }

  const getQuantityTags = async (pattern = ''): Promise<QuantityTag[]> => {
    setInternalLoading(true)
    let tags: QuantityTag[] = []
    const url = logApiGatewayClient.quantityTags.GET_MANY
    const queryString = pattern ? `?name=${pattern}` : ''
    const tagsResponse = await axios.get(`${url}${queryString}`)
    tags = tagsResponse.data as QuantityTag[]
    setInternalLoading(false)
    return tags
  }
  const createQuantityTag = async (name: string): Promise<QuantityTag | undefined> => {
    setInternalLoading(true)
    const tagsResponse = await axios.post(logApiGatewayClient.quantityTags.CREATE, { name })
    const tag: QuantityTag = tagsResponse.data as QuantityTag
    setInternalLoading(false)
    return tag
  }
  const deleteQuantityTag = async (tagId: string | number): Promise<void> => {
    setInternalLoading(true)
    await axios.delete(logApiGatewayClient.quantityTags.DELETE_ONE.replace(':id', `${tagId}`))
    setInternalLoading(false)
  }

  return {
    data,
    error,
    loading: !error && (isValidating || internalLoading),
    mutate,
    createQuantity,
    deleteQuantity,
    editQuantity,
    getQuantityTags,
    createQuantityTag,
    deleteQuantityTag,
  }
}

export default useQuantities
