import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import Card from '@/atoms/Card'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import classes from './InfoBanner.module.less'

const InfoBanner = ({ description }: { description: string }): ReactElement => {
  const { t } = useTranslation()
  return (
    <Card className={classes.cardWrapper} shadow="none">
      <div className={classes.header}>
        <Title size="sm" className={classes.title}>
          {t('calculation-changes.impact-modal.alert.title')}
        </Title>
      </div>
      <div className={classes.bodyWrapper} style={{ display: 'flex', flexDirection: 'column' }}>
        <Text size="xl" className={classes.subtitle}>
          {description}
        </Text>
      </div>
    </Card>
  )
}

export default InfoBanner
