import { AuditEvent } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import axios from '@/utils/axios'
import { getUrl } from '@/utils/url'

export async function getAuditEvents(): Promise<AuditEvent[]> {
  const url = getUrl(centralApiGatewayClient.auditEvents.GET_MANY)
  const auditEvents = await axios.get(url.toString())

  return auditEvents.data
}
