import React, { ReactElement, ReactNode, memo } from 'react'

import classes from './Button.module.less'

type Props = {
  icon: ReactNode
  className?: string
}
const BtnIcon = ({ icon, className }: Props): ReactElement => (
  <span className={`${classes.btnIcon} ${className}`} role="img">
    {icon}
  </span>
)

export default memo(BtnIcon)
