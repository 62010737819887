import { Role } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import axios from '@/utils/axios'
import { getUrl } from '@/utils/url'

export async function getRoles(): Promise<Role[]> {
  const url = getUrl(centralApiGatewayClient.roles.GET_MANY)

  const getRolesResponse = await axios.get(url.toString())

  return await getRolesResponse.data
}
