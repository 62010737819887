// Endpoints
export const CREATE_USER_FUNCTION = 'createUserFunction'
export const GET_USER_FUNCTIONS = 'getUserFunctions'
export const GET_SINGLE_USER_FUNCTION = 'getSinglerUserFunction'
export const UPDATE_USER_FUNCTION = 'updateUserFunction'
export const DELETE_USER_FUNCTION = 'deleteUserFunction'

//Tags

export const TAG_USER_FUNCTIONS = 'USER_FUNCTIONS'
export default [TAG_USER_FUNCTIONS]
