import React, { ReactElement, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd/es'

import { Report, ReportCategory } from '@cozero/models'

import Text from '@/atoms/Text'

import classes from '../../classes.module.less'

const ChooseReportCategory = ({
  reportCategories,
  setReports,
}: {
  reportCategories: ReportCategory[]
  setReports: (newReports: Report[]) => void
}): ReactElement => {
  const [chosenReportCategory, setChosenReportCategory] = useState<ReportCategory>()
  const { t } = useTranslation('common')

  const renderElement = (reportCategory: ReportCategory): JSX.Element => (
    <Col
      key={reportCategory.id}
      span={24}
      className={`${
        chosenReportCategory === reportCategory ? classes.selectedCategory : undefined
      } ${classes.reportCategory}`}
      onClick={() => {
        setChosenReportCategory(reportCategory)
        setReports(reportCategory.reports)
      }}
    >
      <Text ellipsis={{ tooltip: reportCategory.name }} className={classes.sideBarCategoryText}>
        {reportCategory.name}
      </Text>
    </Col>
  )

  return (
    <>
      <Row gutter={[8, 2]} wrap className={classes.chooseReportCategoryWrapper}>
        <Col
          span={24}
          className={`${!chosenReportCategory ? classes.selectedCategory : undefined} ${
            classes.reportCategory
          }`}
          onClick={() => {
            setChosenReportCategory(undefined)
            const newReports = reportCategories.reduce(
              (acc, category) => [...acc, ...category.reports],
              [] as Report[],
            )
            setReports(
              newReports.filter(
                (value, index, self) =>
                  self.findIndex((value2) => value2.id === value.id) === index,
              ),
            )
          }}
        >
          <Text
            ellipsis={{ tooltip: t('log.category-modal.viewAll') }}
            className={classes.sideBarCategoryText}
          >
            {t('log.category-modal.viewAll')}
          </Text>
        </Col>
        {reportCategories
          .filter((category) => category.highlighted)
          .map((reportCategory) => renderElement(reportCategory))}

        <Col span={24} className={classes.categoryDividerContainer}>
          <Text fontWeight="bold" size="xl">
            {t('share.reports.category-header')}
          </Text>
        </Col>

        {reportCategories
          .filter((category) => !category.highlighted)
          .map((reportCategory) => renderElement(reportCategory))}
      </Row>
    </>
  )
}

export default memo(ChooseReportCategory)
