import React, { ReactElement, ReactNode, Suspense, lazy } from 'react'
import { TFunction } from 'react-i18next'

import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import AdminProvider from '@/contexts/admin'
import FiltersProvider from '@/contexts/filters'
import { config } from '@/utils/config'

import AdminSettingsRoute from '../AdminSettingsRoute'
import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'
import SuspenseSpinner from '../SuspenseSpinner'

const PageNotFound = lazy(() => import('@/pages/404'))
const CertificatePage = lazy(() => import('@/pages/Share/Certificates'))
const CreateStory = lazy(() => import('@/pages/Share/CreateStory'))
const SharePages = lazy(() => import('@/pages/Share/SharePages'))
const ViewSharePages = lazy(() => import('@/pages/Share/SharePages/ViewSharePage'))
const StoryPage = lazy(() => import('@/pages/Share/Stories'))
const SuccessStory = lazy(() => import('@/pages/Share/SuccessStory'))
const SustainabilityReportPage = lazy(() => import('@/pages/Share/SustainabilityReports'))

const { routes } = config

const Wrappers = ({ children }: { children: ReactNode }): ReactElement => (
  <AdminProvider>
    <PrivateRoute>
      <FeatureRoute requiredFeature="share">{children}</FeatureRoute>
    </PrivateRoute>
  </AdminProvider>
)

/**
 * All routes in the /share/ namespace
 */
const getShareRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.share.base,
    children: [
      {
        path: routes.share.sharePages,
        element: (
          <Wrappers>
            <FiltersProvider saveQueryString={true}>
              <Suspense fallback={<SuspenseSpinner />}>
                <SharePages />
              </Suspense>
            </FiltersProvider>
          </Wrappers>
        ),
        breadcrumb: t('layout.share-pages'),
      } as BreadcrumbsRoute,
      {
        path: routes.share.viewSharePage,
        element: (
          <Wrappers>
            <FiltersProvider saveQueryString={true}>
              <Suspense fallback={<SuspenseSpinner />}>
                <ViewSharePages />
              </Suspense>
            </FiltersProvider>
          </Wrappers>
        ),
      },
      {
        path: routes.share.stories,
        element: (
          <Wrappers>
            <Suspense fallback={<SuspenseSpinner />}>
              <StoryPage />
            </Suspense>
          </Wrappers>
        ),
        breadcrumb: t('layout.stories'),
      } as BreadcrumbsRoute,

      {
        path: routes.share.story,
        element: (
          <Wrappers>
            <Suspense fallback={<SuspenseSpinner />}>
              <SuccessStory />
            </Suspense>
          </Wrappers>
        ),
      },
      {
        path: routes.share.createStory,
        element: (
          <Wrappers>
            <Suspense fallback={<SuspenseSpinner />}>
              <CreateStory />
            </Suspense>
          </Wrappers>
        ),
      },
      {
        path: routes.share.editStory,
        element: (
          <Wrappers>
            <AdminSettingsRoute level="manager">
              <Suspense fallback={<SuspenseSpinner />}>
                <CreateStory />
              </Suspense>
            </AdminSettingsRoute>
          </Wrappers>
        ),
      },
      {
        path: routes.share.sustainabilityReports,
        element: (
          <Wrappers>
            <Suspense fallback={<SuspenseSpinner />}>
              <SustainabilityReportPage />
            </Suspense>
          </Wrappers>
        ),
        breadcrumb: t('layout.sustainability-reports'),
      } as BreadcrumbsRoute,
      {
        path: routes.share.certifications,
        element: (
          <Wrappers>
            <Suspense fallback={<SuspenseSpinner />}>
              <CertificatePage />
            </Suspense>
          </Wrappers>
        ),
        breadcrumb: t('layout.certificates'),
      } as BreadcrumbsRoute,
      {
        path: `${routes.share.base}/*`,
        element: (
          <Wrappers>
            <Suspense fallback={<SuspenseSpinner />}>
              <PageNotFound />
            </Suspense>
          </Wrappers>
        ),
      },
    ],
  },
]
export default getShareRoutes
