import React, { ReactElement, ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineLightBulb } from 'react-icons/hi'

import { kebabCase } from 'lodash-es'

import { routes } from '@cozero/utils'

import NavMenuItem from '@/molecules/NavMenuItem'
import NavSubMenu from '@/molecules/NavSubMenu'

import Divider from '@/atoms/Divider'

import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin } from '@/redux/auth'

import { selectIsFinished } from '../../redux/onboarding'
import NavCompanyHeader from '../NavCompanyHeader'
import NavUserMenu from '../NavUserMenu'

import classes from './NavSidebar.module.less'
import { useMenuItemChildren } from './NavSidebar.useMenuItemChildren'
import { useMenuItems } from './NavSidebar.useMenuItems'

type Base = {
  key: string
  title: string
  feature: string
  icon?: ReactElement
  cy?: string
  newFeature?: boolean
  isDisabled?: boolean
  disabledTooltip?: string
}

export type MenuItem =
  | (Base & {
      children?: MenuItem[]
      key: string
      route?: never
      excludeRoles?: string[]
    })
  | (Base & {
      children?: never
      route?: string
      excludeRoles?: string[]
    })

export const NavSidebar = ({
  trialNotification,
  supplierOnboarding,
  hideUserMenu,
}: {
  trialNotification: ReactNode
  supplierOnboarding?: boolean
  hideUserMenu?: boolean
}): ReactElement => {
  const { t } = useTranslation()

  const [openSubMenuKey, setOpenSubMenuKey] = useState<string | null>(null)

  const isOnboardingFinished = useAppSelector(selectIsFinished)

  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const shouldSeeTrialNotification = isManagerOrAdmin

  const { getMappedActItemChildren } = useMenuItemChildren()
  const { menuItems } = useMenuItems()

  const MenuItems = useMemo((): ReactNode => {
    return menuItems.map((item) => {
      const menuItemChildren = item.key === 'act' ? getMappedActItemChildren(item) : item.children

      if (menuItemChildren) {
        return (
          <NavSubMenu
            key={item.key}
            base={item.key}
            title={item.title}
            icon={item.icon}
            menuOpen={openSubMenuKey === item.key}
            onOpen={(base: string | null) => setOpenSubMenuKey(base)}
            items={menuItemChildren}
          />
        )
      }

      return (
        <NavMenuItem
          key={item.route ?? item.key}
          title={item.title}
          route={item.route}
          icon={item.icon}
          isDisabled={item.isDisabled}
          data-cy={kebabCase(item.title)}
        />
      )
    })
  }, [menuItems, openSubMenuKey, getMappedActItemChildren])

  return (
    <aside className={classes.sider}>
      <div className={classes.siderInnerContainer}>
        <NavCompanyHeader />

        <div className={classes.siderOnboardingSection}>
          <NavMenuItem
            title={t('onboarding.demo.get-started')}
            showFocusDot={!isOnboardingFinished}
            icon={<HiOutlineLightBulb />}
            route={supplierOnboarding ? routes.onboardingSupplierStep2 : routes.onboarding}
          />
          <Divider />
        </div>

        <nav>{MenuItems}</nav>
      </div>

      <div>
        {shouldSeeTrialNotification && <div>{trialNotification}</div>}

        {!hideUserMenu && <NavUserMenu />}
      </div>
    </aside>
  )
}
