import React, { HTMLAttributes, forwardRef, memo } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'

import classes from './Divider.module.less'

export interface DividerProps extends HTMLAttributes<HTMLHRElement> {
  color?: 'dark' | 'light'
}

const Divider = forwardRef<HTMLHRElement, DividerProps>(
  ({ color = 'dark', className, ...rest }, ref): ReactElement => (
    <hr
      ref={ref}
      aria-orientation="horizontal"
      className={`${classes.divider} ${classes[color]} ${className}`}
      {...rest}
    />
  ),
)

Divider.displayName = 'Divider'

export default memo(Divider)
