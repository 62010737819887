import React, { useEffect, useState } from 'react'

import { Spin } from 'antd/es'

import { EmissionFactor, EmissionFactorWithIncludes, Page } from '@cozero/models'

import Form from '@/atoms/Form'

import { useFactorContext } from '@/contexts/factor'

import CustomFactorModal from '../CustomFactorModal'
import FactorsTable from '../FactorsTable'

interface FactorProps {
  getFactors: ({
    page,
    pageSize,
  }: {
    page: number
    pageSize: number
  }) => Promise<void | Page<EmissionFactorWithIncludes>>
  isPeriodClosed: boolean
  setSelectedFactor: (factor?: EmissionFactorWithIncludes) => void
  upsertingFactor?: Partial<EmissionFactorWithIncludes> | null
  loading: boolean
  selectedFactor?: EmissionFactorWithIncludes
  factors?: Page<EmissionFactorWithIncludes>
  logEntryId?: number
  selectedTab: 'all' | 'used'
  closeParentModal: () => void
}

function FactorsTab({
  getFactors,
  isPeriodClosed,
  setSelectedFactor,
  upsertingFactor,
  loading,
  selectedFactor,
  factors,
  logEntryId,
  selectedTab,
  closeParentModal,
}: FactorProps): JSX.Element {
  const [form] = Form.useForm()
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const { updateCustomFactor, createCustomFactor } = useFactorContext()

  function onSelectFactor(factor: EmissionFactorWithIncludes): void {
    setSelectedFactor(factor)
  }
  const onCloseModal = (): void => {
    setSelectedFactor(undefined)
  }

  async function updateFactor(
    id: number,
    values: Partial<EmissionFactorWithIncludes>,
  ): Promise<void> {
    updateCustomFactor(id, values)
    await getFactors({ page, pageSize })
    onCloseModal()
    closeParentModal()
  }

  async function createFactor(
    factor: EmissionFactor,
    values: Partial<EmissionFactorWithIncludes>,
  ): Promise<void> {
    createCustomFactor(factor.id, {
      ...values,
      activityDataSourceId: factor.activityDataSourceId,
      denominatorUnitId: factor.denominatorUnitId,
      unitId: factor.unitId,
      territoryId: factor.territoryId,
      originId: factor.originId,
      value: parseFloat(`${values.value}`),
      ...(factor.modeledImpactId ? { modeledImpactId: factor.modeledImpactId } : {}),
    })
    await getFactors({ page, pageSize })
    onCloseModal()
    closeParentModal()
  }

  useEffect(() => {
    form.resetFields()
  }, [upsertingFactor])

  useEffect(() => {
    getFactors({ page, pageSize })
  }, [logEntryId, selectedTab])

  return (
    <Spin spinning={loading}>
      {!upsertingFactor && !selectedFactor && (
        <FactorsTable
          factors={factors}
          isPeriodClosed={isPeriodClosed}
          page={page}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setPage={setPage}
          setSelectedFactor={onSelectFactor}
        />
      )}
      <CustomFactorModal
        isVisible={!!selectedFactor}
        onClose={onCloseModal}
        selectedFactor={selectedFactor}
        updateCustomFactor={updateFactor}
        createCustomFactor={createFactor}
      />
    </Spin>
  )
}

export default FactorsTab
