import React, { useEffect, useState } from 'react'

import classNames from 'classnames'
import moment, { Moment } from 'moment'

import { DateRangeFilter, PageFilter } from '@cozero/models'

import DateRangePicker from '@/atoms/DateRangePicker'

import classes from './DateRangePickerFilter.module.less'

interface DateRangePickerFilterProps {
  filters?: PageFilter[]
  saveFilters: (filters: PageFilter[]) => void
  logType?: PageFilter['logType']
  visualStyle?: 'default' | 'ghost'
  disabled?: boolean
  logRange?: { startDate?: Date; endDate?: Date }
}

function DateRangePickerFilter({
  filters: filtersFromProps = [],
  saveFilters,
  logType,
  visualStyle,
  disabled = false,
  logRange,
}: DateRangePickerFilterProps): JSX.Element {
  const [selectedDateRange, setSelectedDateRange] = useState<
    [Moment | null, Moment | null] | undefined
  >([null, null])
  const { filters } = (filtersFromProps?.find(
    (x) => x.type === 'dateRange',
  ) as DateRangeFilter) || { filters: [] }

  const ranges: { [key: string]: [Moment | null, Moment | null] } = {}
  ranges['Year-to-Date'] = [moment().utc().startOf('year'), moment().utc()]
  ranges['Last 12 months'] = [moment().utc().subtract(12, 'months'), moment().utc()]
  for (let i = 3; i >= 0; i--) {
    ranges[moment().subtract(i, 'year').year()] = [
      moment().utc().subtract(i, 'year').startOf('year'),
      moment().utc().subtract(i, 'year').endOf('year'),
    ]
  }

  function saveDateRangeFilters(dateRange: [Moment | null, Moment | null]): void {
    if (!dateRange[0] && !dateRange[1]) {
      saveFilters([
        ...filtersFromProps.filter(
          (filter) => filter.type !== 'date' && filter.type !== 'dateRange',
        ),
      ])
    } else {
      saveFilters([
        ...filtersFromProps.filter(
          (filter) => filter.type !== 'date' && filter.type !== 'dateRange',
        ),
        {
          filters: [
            {
              key: 'startDate',
              value: dateRange?.[0]?.utc().toDate(),
              type: 'date',
              conditions: [],
              selectedCondition: {
                key: 'gte',
                label: 'gte',
              },
              label: 'startDate',
              logType,
            },
            {
              key: 'endDate',
              value: dateRange?.[1]?.utc().toDate(),
              type: 'date',
              conditions: [],
              selectedCondition: {
                key: 'lte',
                label: 'lte',
              },
              label: 'endDate',
              logType,
            },
          ],
          type: 'dateRange',
          logType,
        },
      ])
    }
  }

  // set default date filters (initially or after reset)
  useEffect(() => {
    const startValue = filters.find((filter) => filter.key === 'startDate')?.value
    const endValue = filters.find((filter) => filter.key === 'endDate')?.value

    if (!startValue && !endValue && logRange) {
      const start = moment(logRange.startDate)
      const end = moment(logRange.endDate)

      saveDateRangeFilters([start, end])
    }
  }, [logRange, filters])

  // update selected range according to filters
  useEffect(() => {
    const startValue = filters.find((filter) => filter.key === 'startDate')?.value
    const endValue = filters.find((filter) => filter.key === 'endDate')?.value

    const start = startValue ? moment(startValue).utc() : null
    const end = endValue ? moment(endValue).utc() : null

    if (selectedDateRange && (start !== selectedDateRange[0] || end !== selectedDateRange[1])) {
      setSelectedDateRange([start, end])
    }
  }, [filters])

  return (
    <DateRangePicker
      disabled={disabled}
      value={selectedDateRange}
      onChange={saveDateRangeFilters}
      ranges={ranges}
      className={classNames(classes.dateRangePicker, visualStyle && classes[visualStyle])}
    />
  )
}

export default DateRangePickerFilter
