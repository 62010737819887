import React, { ImgHTMLAttributes, ReactElement, memo, useRef, useState } from 'react'

import useIntersectionObserver from '@/hooks/useIntersectionObserver'

import Emoji from '../Emoji'

import classes from './LazyLoadImage.module.less'

type Props = ImgHTMLAttributes<HTMLImageElement>

const LazyLoadImage = ({ src, className, ...rest }: Props): ReactElement => {
  const imgRef = useRef<HTMLImageElement>(null)
  const isInView = useIntersectionObserver(imgRef, { rootMargin: '150px' })
  const [error, setError] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const onImgLoad = (): void => setLoaded(true)

  const onImgError = (): void => setError(true)

  if (!src || error) {
    return (
      <div className={classes.placeholder}>
        <Emoji symbol="🍃" className={classes.emoji} />
        <span className={classes.text}>No image available</span>
      </div>
    )
  }
  return (
    <div ref={imgRef}>
      {isInView && (
        <img
          className={`${classes.lazyImg} ${loaded && classes.loaded} ${className}`}
          src={src}
          onLoad={onImgLoad}
          onError={onImgError}
          {...rest}
        />
      )}
    </div>
  )
}

export default memo(LazyLoadImage)
