import React, { ReactElement, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { Skeleton } from 'antd/es'

import { selectSelectedBusinessUnitKey } from '@/redux/businessUnits'
import { useGetLocationsQuery } from '@/redux/locations'

const LocationCrumb = (): ReactElement => {
  const { id } = useParams()
  const businessUnitKey = useSelector(selectSelectedBusinessUnitKey)
  const { data: locations, isLoading } = useGetLocationsQuery(
    { businessUnitKey: businessUnitKey ?? '' },
    { skip: !businessUnitKey },
  )

  const locationName = useMemo(() => {
    const name = locations?.find((location) => location.id === parseInt(id ?? ''))?.name
    if (name) {
      return name
    }
  }, [id, locations])

  if (isLoading) {
    return <Skeleton.Button active style={{ maxHeight: 20, width: 30 }} />
  }
  if (!locationName) {
    return <>{id}</>
  }
  return <>{locationName}</>
}

export default LocationCrumb
