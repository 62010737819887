import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { BusinessUnit } from '@cozero/models'

import { LOGIN, LOGOUT, authApiSlice } from '../auth'

import { SLICE_NAME_BUSINESS_UNITS } from './constants'

// Define a type for the slice state
export interface BusinessUnitState {
  selectedBusinessUnit: BusinessUnit | null
  businessUnitTree: BusinessUnit[]
}

// Define the initial state using that type
const initialState: BusinessUnitState = {
  selectedBusinessUnit: null,
  businessUnitTree: [],
}

const businessUnitSlice = createSlice({
  name: SLICE_NAME_BUSINESS_UNITS,
  initialState: initialState,
  reducers: {
    setSelectedBusinessUnit: (state, action: PayloadAction<BusinessUnit>) => {
      state.selectedBusinessUnit = action.payload
    },
    resetSelectedBusinessUnit: (state) => {
      state.selectedBusinessUnit = null
    },
    updateBusinessUnitTree: (state, action: PayloadAction<BusinessUnit[]>) => {
      return {
        ...state,
        businessUnitTree: [...state.businessUnitTree, ...action.payload],
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApiSlice.endpoints[LOGIN].matchFulfilled,
        (state: BusinessUnitState, { payload }) => {
          state.selectedBusinessUnit = payload.user.businessUnit as BusinessUnit
        },
      )
      .addMatcher(authApiSlice.endpoints[LOGOUT].matchFulfilled, () => initialState)
  },
})

export const { setSelectedBusinessUnit, resetSelectedBusinessUnit, updateBusinessUnitTree } =
  businessUnitSlice.actions

export default businessUnitSlice
