import { AxiosError, AxiosResponse } from 'axios'

import axios from './axios'

const fetcher = async <T>(resourceUri: string): Promise<AxiosResponse<T> | undefined> => {
  try {
    const res = await axios.get<T>(resourceUri)
    if (res.data) {
      return res
    }
  } catch (error) {
    throw new Error((error as AxiosError).message ?? 'error')
  }
}

export default fetcher
