import React, { useContext } from 'react'

import {
  Limit,
  Organization,
  Subscription,
  SubscriptionCheckoutSession,
  SubscriptionProduct,
} from '@cozero/models'

import useSubscriptionHandler from '../hooks/useSubscription'

interface ISubscribeModalSettings {
  title: string
  closable: boolean
  visible: boolean
}

export interface SubscriptionContextInterface {
  getCalendarLink: () => Promise<string | void>
  createHubspotLead: (data: Record<string, string>) => Promise<void>
  getCheckoutUrl: (productId: string) => Promise<string | void>
  subscribeModalSettings: ISubscribeModalSettings
  setSubscribeModalSettings: (settings: ISubscribeModalSettings) => void
  error: string
  loading: boolean
  subscription?: Subscription
  subscriptionProducts: SubscriptionProduct[]
  getSubscription: () => Promise<Subscription | void>
  getCustomerPortalUrl: () => Promise<string | void>
  getSubscriptionProducts: () => Promise<SubscriptionProduct[] | void>
  getCheckoutSession: (sessionId: string) => Promise<SubscriptionCheckoutSession | void>
  checkoutSession?: SubscriptionCheckoutSession
  getLimit: (organization: Organization | undefined, featureName: string) => Limit | undefined
  subscriptionExpired: boolean
}

export const subscriptionContext = React.createContext<SubscriptionContextInterface | undefined>(
  undefined,
)

const { Provider } = subscriptionContext

export function useSubscriptionContext(): SubscriptionContextInterface {
  const contextValue = useContext(subscriptionContext)
  if (contextValue === undefined) {
    throw new Error('Context must be inside a Provider')
  }
  return contextValue
}

interface ProviderProps {
  children: React.ReactNode
}

const SubscriptionProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const subscriptionAcessors = useSubscriptionHandler()
  return <Provider value={subscriptionAcessors}>{children}</Provider>
}

export default SubscriptionProvider
