import { Category } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_CATEGORIES } from './tags'

export const GET_CATEGORIES = 'getCategories'
export const GET_CATEGORIES_FOR_LOG_CREATION = 'getCategoriesForLogCreation'
export const GET_CATEGORIES_WITH_LOGS = 'getCategoriesWithLogs'

const categoriesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_CATEGORIES]: builder.query<Category[], void>({
      query: () => ({
        url: logApiGatewayClient.categories.GET_MANY,
        method: 'GET',
      }),
      providesTags: (result) =>
        providesList<Category[], typeof TAG_CATEGORIES>(result, TAG_CATEGORIES),
    }),
    [GET_CATEGORIES_FOR_LOG_CREATION]: builder.query<
      Category[],
      { onlyCategoriesWithLogs?: boolean; businessUnitId?: number }
    >({
      query: ({ onlyCategoriesWithLogs, businessUnitId }) => ({
        url: logApiGatewayClient.categories.GET_FOR_LOG_CREATION,
        method: 'GET',
        params: {
          onlyCategoriesWithLogs,
          businessUnitId,
        },
      }),
      providesTags: (result) =>
        providesList<Category[], typeof TAG_CATEGORIES>(result, TAG_CATEGORIES),
    }),
    [GET_CATEGORIES_WITH_LOGS]: builder.query<
      Category[],
      {
        onlyWithLogsAssociated?: boolean
        businessUnitId?: number
      }
    >({
      query: ({ onlyWithLogsAssociated, businessUnitId }) => ({
        url: logApiGatewayClient.categories.GET_MANY_WITH_LOGS,
        method: 'GET',
        params: {
          onlyWithLogsAssociated,
          businessUnitId,
        },
      }),
      providesTags: (result) =>
        providesList<Category[], typeof TAG_CATEGORIES>(result, TAG_CATEGORIES),
    }),
  }),
})

export const {
  useGetCategoriesQuery,
  useGetCategoriesForLogCreationQuery,
  useGetCategoriesWithLogsQuery,
} = categoriesSlice

export default categoriesSlice
