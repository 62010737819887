import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Form, FormInstance, Popconfirm, Row, Spin, Tag, TreeSelect } from 'antd/es'
import { DataNode } from 'antd/es/tree'

import { UserOutlined } from '@ant-design/icons'
import debounce from 'lodash/debounce'

import { BusinessUnit, UserFunction } from '@cozero/models'

import Modal from '@/molecules/Modal'
import Table from '@/molecules/Table'

import Button from '@/atoms/Button'
import InputField from '@/atoms/InputField'
import Title from '@/atoms/Title'

import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'
import {
  selectSelectedBusinessUnitKey,
  useLazyGetActiveBusinessUnitsQuery,
  useLazyGetBusinessUnitQuery,
} from '@/redux/businessUnits'
import {
  useCreateUserFunctionMutation,
  useDeleteUserFunctionMutation,
  useGetUserFunctionsQuery,
  useUpdateUserFunctionMutation,
} from '@/redux/userFunctions'
import { updateTreeData } from '@/utils/tree'

import classes from './classes.module.less'

const genTreeNode = (
  businessUnit: BusinessUnit,
): BusinessUnit & { pId: number | null; isLeaf: boolean; value: string } => {
  const hasChildren = businessUnit.children.length
  return {
    id: businessUnit.id,
    pId: businessUnit.parentUnitId,
    value: businessUnit.key,
    isLeaf: !hasChildren,
    title: businessUnit.title,
    key: businessUnit.key,
    children: businessUnit.children,
  } as BusinessUnit & { pId: number | null; isLeaf: boolean; value: string }
}

const UserFunctions = (): ReactElement => {
  const { t } = useTranslation('common')
  const [form] = Form.useForm()
  const businessUnitKey = useAppSelector(selectSelectedBusinessUnitKey)
  const isAdmin = useAppSelector(selectUser)
  const [userFunction, setUserFunction] = useState<Partial<UserFunction>>()
  const [getBusinessUnit] = useLazyGetBusinessUnitQuery()
  const [getActiveBusinessUnits] = useLazyGetActiveBusinessUnitsQuery()
  const [loading, setLoading] = useState(false)
  const [isSavingUserFunction, setIsSavingUserFunction] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [businessUnits, setBusinessUnits] = useState<BusinessUnit[]>([])
  const [initialBusinessUnits, setInitialBusinessUnits] = useState<BusinessUnit[]>([])
  const { data: userFunctions, isLoading: loadingUserFunctions } = useGetUserFunctionsQuery()
  const [updateUserFunction] = useUpdateUserFunctionMutation()
  const [deleteUserFunction] = useDeleteUserFunctionMutation()
  const [createUserFunction] = useCreateUserFunctionMutation()

  const columns = [
    {
      title: t('settings.user-functions.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('settings.user-functions.business-units'),
      dataIndex: 'businessUnits',
      key: 'businessUnits',
      render: (_text: string, record: UserFunction) =>
        record.businessUnits?.map((obj) => obj.title).join(', '),
    },
    {
      title: t('settings.user-functions.users'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render(_text: string, record: UserFunction) {
        return (
          <span>
            <UserOutlined className={classes.icon} />
            {record.users?.length || 0}
          </span>
        )
      },
    },
    isAdmin
      ? {
          title: t('actions.title'),
          key: 'action',
          width: 200,
          render(_text: string, record: UserFunction) {
            return (
              <Row gutter={8}>
                <Col>
                  <Button
                    action="edit"
                    category="user-functions"
                    onClick={() => onEditUserFunction(record)}
                    type="secondary"
                    className={classes.editUserFunctionButton}
                  >
                    {t('settings.user-functions.edit-btn')}
                  </Button>
                </Col>
                <Col>
                  <Popconfirm
                    title={t('settings.user-functions.delete')}
                    onConfirm={async (e) => {
                      e?.stopPropagation()
                      await deleteUserFunction(record.id)
                    }}
                    onCancel={(e) => {
                      e?.stopPropagation()
                    }}
                    okText={t('yes')}
                    cancelText={t('no')}
                  >
                    <Button
                      action="delete"
                      category="user-functions"
                      onClick={(e) => e.stopPropagation()}
                      type="primary"
                      color="danger"
                    >
                      {t('settings.user-functions.delete-btn')}
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            )
          },
        }
      : { width: 0 },
  ]

  function onEditUserFunction(editingUserFunction: UserFunction): void {
    setUserFunction(editingUserFunction)
    form.setFieldsValue(editingUserFunction)
    setIsModalVisible(true)
  }

  async function saveUserFunction(values: UserFunction): Promise<void> {
    if (!userFunction) {
      return
    }
    setIsSavingUserFunction(true)
    const newUserFunction = {
      ...userFunction,
      ...values,
    }
    if (userFunction.id) {
      await updateUserFunction({
        id: userFunction.id,
        name: newUserFunction.name,
        businessUnits: newUserFunction.businessUnits,
      }).unwrap()
    } else {
      await createUserFunction({
        name: newUserFunction.name,
        businessUnits: newUserFunction.businessUnits,
      }).unwrap()
    }
    return setIsModalVisible(false)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function changeBusinessUnits(value: string, _: unknown, extra: any): void {
    const found = userFunction?.businessUnits?.find((obj) => obj.key === value)
    if (!found && value) {
      const values = form.getFieldsValue()
      setUserFunction({
        ...userFunction,
        ...values,
        businessUnits: [
          ...(userFunction?.businessUnits || []),
          {
            ...extra?.triggerNode?.props,
            key: value,
          },
        ],
      })
      setBusinessUnits(initialBusinessUnits)
    }
  }

  function removeBusinessUnit(businessUnit: BusinessUnit): void {
    if (!userFunction?.businessUnits) {
      return
    }
    const foundIndex = userFunction.businessUnits.findIndex((obj) => obj.key === businessUnit.key)
    if (foundIndex > -1) {
      const newBusinessUnits = [...(userFunction?.businessUnits || [])]
      newBusinessUnits.splice(foundIndex, 1)
      const values = form.getFieldsValue()
      setUserFunction({
        ...userFunction,
        ...values,
        businessUnits: newBusinessUnits,
      })
    }
  }

  async function onLoadData(treeNode: unknown, businessUnitTree?: BusinessUnit[]): Promise<void> {
    const businessUnit = treeNode as BusinessUnit
    if (businessUnit) {
      const parentBusinessUnit = await getBusinessUnit({
        id: `${businessUnit.id}`,
        active: true,
      }).unwrap()
      if (parentBusinessUnit) {
        const newTree = updateTreeData(
          businessUnitTree?.length ? businessUnitTree : businessUnits,
          businessUnit.id,
          (parentBusinessUnit.children as BusinessUnit[]) || [],
        )
        setBusinessUnits(newTree)
        setInitialBusinessUnits(newTree)
      }
    }
  }

  async function fetchBusinessUnits(): Promise<void> {
    const businessUnits = await getActiveBusinessUnits({
      businessUnitKey: businessUnitKey ?? '',
      root: true,
    }).unwrap()
    if (businessUnits) {
      const mappedBusinessUnits = businessUnits.map((obj) => genTreeNode(obj))
      setBusinessUnits(mappedBusinessUnits)
      setInitialBusinessUnits(mappedBusinessUnits)
    }
  }

  async function onSearch(value: string): Promise<void> {
    if (value) {
      const businessUnits = await getActiveBusinessUnits({
        businessUnitKey: businessUnitKey ?? '',
        search: value,
      }).unwrap()
      if (businessUnits) {
        setBusinessUnits(businessUnits)
      }
    } else {
      setBusinessUnits(initialBusinessUnits)
    }
  }

  function closeModal(): void {
    setUserFunction(undefined)
    setIsModalVisible(false)
  }

  function openModal(): void {
    setUserFunction({ name: '' })
    setIsModalVisible(true)
  }

  useEffect(() => {
    setLoading(true)
    fetchBusinessUnits()
    setLoading(false)
  }, [])

  return (
    <>
      <Spin spinning={loadingUserFunctions || loading} tip={t('loading')}>
        <Row>
          <Col span={24}>
            <Row justify="space-between" className={classes.section}>
              <Col span={16}>
                <Title size="sm">{t('settings.user-functions.title')}</Title>
              </Col>
            </Row>
            {isAdmin && (
              <Row justify="end">
                <Col>
                  <Button
                    action="user-functions/create"
                    category="user-functions"
                    className={classes.requestButton}
                    type="primary"
                    onClick={openModal}
                  >
                    {t('settings.user-functions.create')}
                  </Button>
                </Col>
              </Row>
            )}
            <Row className={classes.section}>
              <Col span={24}>
                <Spin spinning={loadingUserFunctions}>
                  <Table columns={columns} dataSource={userFunctions} pagination={false} />
                </Spin>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
      <UserFunctionModal
        isModalVisible={isModalVisible}
        formSubmissionLoading={isSavingUserFunction}
        closeModal={closeModal}
        form={form}
        userFunction={userFunction}
        saveUserFunction={saveUserFunction}
        removeBusinessUnit={removeBusinessUnit}
        onLoadData={onLoadData}
        changeBusinessUnits={changeBusinessUnits}
        businessUnits={businessUnits}
        onSearch={onSearch}
      />
    </>
  )
}

const UserFunctionModal = ({
  isModalVisible,
  closeModal,
  form,
  formSubmissionLoading,
  userFunction,
  saveUserFunction,
  removeBusinessUnit,
  onLoadData,
  changeBusinessUnits,
  businessUnits,
  onSearch,
}: {
  isModalVisible: boolean
  closeModal: () => void
  form: FormInstance<UserFunction>
  userFunction?: Partial<UserFunction>
  formSubmissionLoading: boolean
  saveUserFunction: (values: UserFunction) => void
  removeBusinessUnit: (businessUnit: BusinessUnit) => void
  onLoadData: (treeNode: unknown) => Promise<void>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeBusinessUnits: (value: string, _: unknown, extra: any) => void
  businessUnits: BusinessUnit[]
  onSearch: (value: string) => void
}): JSX.Element => {
  const { t } = useTranslation('common')

  const prepareData = (businessUnits: BusinessUnit[]): DataNode[] => {
    return businessUnits.map((businessUnit) => {
      const children = prepareData((businessUnit.children as BusinessUnit[]) ?? [])
      const isLeaf = children?.length === 0 ?? false

      return {
        value: businessUnit.id,
        key: businessUnit.id,
        children,
        title: businessUnit.title,
        id: businessUnit.id,
        isLeaf,
      }
    })
  }

  return (
    <Modal
      title={t('settings.user-functions.modal-title')}
      visible={isModalVisible}
      footer={null}
      onCancel={closeModal}
      onOk={form.submit}
      okText={t('actions.submit')}
      okButtonProps={{ loading: formSubmissionLoading }}
    >
      <Form
        layout="vertical"
        initialValues={userFunction}
        onFinish={saveUserFunction}
        className={classes.infoForm}
        form={form}
      >
        <Form.Item
          label={t('name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('settings.user-functions.required'),
            },
          ]}
        >
          <InputField size="large" />
        </Form.Item>
        <Form.Item label={t('settings.user-functions.business-units')}>
          <TreeSelect
            treeDataSimpleMode
            style={{ width: '100%' }}
            loadData={onLoadData}
            onChange={changeBusinessUnits}
            treeData={prepareData(businessUnits)}
            showSearch
            allowClear
            treeNodeLabelProp="title"
            treeNodeFilterProp="title"
            onSearch={debounce(onSearch, 500)}
            size="large"
            className={classes.selectInput}
            popupClassName={classes.dropdown}
          />
        </Form.Item>
        <div className={classes.userFunctionTags}>
          {userFunction?.businessUnits?.map((businessUnit) => {
            return (
              <Tag
                closable
                onClose={() => removeBusinessUnit(businessUnit as BusinessUnit)}
                key={businessUnit.key}
                className={classes.userFunctionTag}
              >
                {businessUnit.title}
              </Tag>
            )
          })}
        </div>
      </Form>
    </Modal>
  )
}

export default UserFunctions
