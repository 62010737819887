import React, { ReactElement, memo, useMemo, useState } from 'react'

import { Progress, ProgressProps } from 'antd/es'

import { CINDER_BLUE_20, COZERO_BLUE_80, COZERO_ORANGE_80 } from '@/styles/variables'

import classes from './ProgressBar.module.less'

interface Props extends ProgressProps {
  label?: string
  value?: number
  max?: number
}

const ProgressBar = ({ label, percent, max, value, showInfo, ...rest }: Props): ReactElement => {
  const [full, setFull] = useState(false)
  const progressFill = useMemo(() => {
    let progress = 0
    if (percent) {
      progress = percent
    }
    if (max !== undefined && value !== undefined) {
      progress = (value / max) * 100
    }

    setFull(progress >= 100)
    return progress
  }, [percent, max, value])

  return (
    <div className={classes.container}>
      <Progress
        percent={progressFill}
        showInfo={showInfo ?? false}
        strokeColor={full ? COZERO_ORANGE_80 : COZERO_BLUE_80}
        trailColor={rest.trailColor ?? CINDER_BLUE_20}
        {...rest}
      />
      {label && (
        <label className={classes.label}>
          {max !== undefined && value !== undefined && `${value}/${max}`} {label}
        </label>
      )}
    </div>
  )
}

export default memo(ProgressBar)
