import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineExclamationCircle } from 'react-icons/hi'

import Modal from '../Modal'

import classes from './AlertDialog.module.less'

interface Props {
  visible: boolean
  onOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onClose: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  message: string
}

const AlertDialog = ({ visible, onOk, onClose, message }: Props): ReactElement => {
  const { t } = useTranslation('')
  return (
    <Modal
      title={t('actions.confirm.title')}
      visible={visible}
      onCancel={onClose}
      onOk={onOk}
      okButtonProps={{ color: 'danger' }}
    >
      <span className={classes.message}>
        <HiOutlineExclamationCircle />
        {message}
      </span>
    </Modal>
  )
}

export default AlertDialog
