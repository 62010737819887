import React, { ReactElement, ReactNode, memo } from 'react'
import { Link, useLocation, useMatch } from 'react-router-dom'

import { Breadcrumb } from 'antd/es'

import classes from './Crumb.module.less'

const Crumb = ({
  path,
  children,
  preventNavigation,
}: {
  path: string
  children: ReactNode
  childPath: string
  preventNavigation?: boolean
}): ReactElement => {
  const match = useMatch({ path })
  const location = useLocation()

  return (
    <Breadcrumb.Item className={classes.root}>
      {match || preventNavigation ? (
        <Link to={location} onClick={(e) => e.preventDefault()}>
          {children}
        </Link>
      ) : (
        <Link to={path}>{children}</Link>
      )}
    </Breadcrumb.Item>
  )
}

export default memo(Crumb)
