import { TFunction } from 'react-i18next'

import { i18n } from 'i18next'
import moment from 'moment'

import { AntTableColumn, Report } from '@cozero/models'

import { formatColumns } from './config'

interface renderProps {
  t: TFunction<'common'>
  i18n: i18n
}

export type Row = { [key: string]: string | number | boolean }

interface renderColumnProps extends renderProps {
  column: AntTableColumn<Report>
}

interface renderRowProps extends renderProps {
  row: Row
}

export const renderColumn = ({ column, t, i18n }: renderColumnProps): AntTableColumn<Row> => {
  const castedColumn: AntTableColumn<Row> = column as unknown as AntTableColumn<Row>
  column.children?.forEach((children) => renderColumn({ column: children, t, i18n }))
  if (
    formatColumns.number.includes(castedColumn.key) ||
    moment(castedColumn.key, moment.ISO_8601, true).isValid()
  ) {
    castedColumn.render = (text: string) => {
      if (!isNaN(Number(text))) {
        return Number(text).toLocaleString(i18n.language, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      } else {
        return '-'
      }
    }
    castedColumn.sorter = (a, b) =>
      (a[castedColumn.key] as number) - (b[castedColumn.key] as number)
  } else if (formatColumns.date_year.includes(castedColumn.key)) {
    castedColumn.render = (text: string) => moment(text).format('YYYY')
    castedColumn.sorter = (a, b) =>
      moment(a[castedColumn.key] as string).unix() - moment(b[castedColumn.key] as string).unix()
  } else if (formatColumns.date_month.includes(castedColumn.key)) {
    castedColumn.render = (text: string) => moment(text).format('MM/YYYY')
    castedColumn.sorter = (a, b) =>
      moment(a[castedColumn.key] as string).unix() - moment(b[castedColumn.key] as string).unix()
  } else if (formatColumns.date_quarter.includes(castedColumn.key)) {
    castedColumn.render = (text: string) => parseQuarter(text)
    castedColumn.sorter = (a, b) => {
      const dateA = moment(a[castedColumn.key] as string)
      const dateB = moment(b[castedColumn.key] as string)
      return dateA.unix() - dateB.unix()
    }
  }

  if (moment(castedColumn.key, moment.ISO_8601, true).isValid()) {
    castedColumn.title = moment(castedColumn.key).format('YYYY')
  } else {
    if (i18n.exists(`common:reports.keys.${castedColumn.key}`)) {
      castedColumn.title = t(`reports.keys.${castedColumn.key}`)
    }
  }
  return castedColumn
}

export const renderRow = ({ row, t, i18n }: renderRowProps): renderRowProps['row'] => {
  for (const [key, value] of Object.entries(row)) {
    if (i18n.exists(`common:reports.keys.${value}`)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      row[key] = t(`reports.keys.${value}`)
    }
  }
  return row
}

export const renderCSVRow = ({ row, t, i18n }: renderRowProps): renderRowProps['row'] => {
  const translatedRow: { [key: string]: string | number | boolean } = {}
  for (const [key, value] of Object.entries(row)) {
    let translatedKey = key
    if (i18n.exists(`common:reports.keys.${key}`)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      translatedKey = t(`reports.keys.${key}`)
    }
    if (i18n.exists(`common:reports.keys.${value}`)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      translatedRow[translatedKey] = t(`reports.keys.${value}`)
    } else {
      translatedRow[translatedKey] = value
    }
    if (formatColumns.number.includes(key) || moment(key, moment.ISO_8601, true).isValid()) {
      translatedRow[translatedKey] = Number(value).toLocaleString(i18n.language, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    } else if (formatColumns.date_year.includes(key)) {
      translatedRow[translatedKey] = moment(value as string).format('YYYY')
    } else if (formatColumns.date_month.includes(key)) {
      translatedRow[translatedKey] = moment(value as string).format('MM/YYYY')
    } else if (formatColumns.date_quarter.includes(key)) {
      translatedRow[translatedKey] = parseQuarter(value as string)
    }
  }
  return translatedRow
}

const parseQuarter = (value: string): string => {
  const date = moment(value)
  const quarter = Math.ceil(date.month() / 3) + 1
  return `${date.format('YYYY')} Q${quarter}`
}
