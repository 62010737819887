import React, { ReactElement, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiQuestionMarkCircle } from 'react-icons/hi'

import Tooltip from '../Tooltip'

import classes from './WikiIcon.module.less'

interface Props {
  title: string
  url?: string
  size?: 'xs' | 'sm' | 'md' | 'lg'
  className?: string
  color?: string
}

const WikiIcon = ({
  url,
  className = '',
  size = 'md',
  title,
  color,
}: Props): ReactElement | null => {
  const { t } = useTranslation()
  const iconClasses = useMemo(() => `${classes.icon} ${classes[size]} ${className}`, [className])

  if (!title) {
    return null
  }

  return (
    <Tooltip title={title ?? t('learn-more')}>
      <a href={url} target="_blank" rel="noreferrer">
        <div className={iconClasses}>
          <HiQuestionMarkCircle color={color} />
        </div>
      </a>
    </Tooltip>
  )
}

export default memo(WikiIcon)
