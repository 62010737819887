import React from 'react'
import { useParams } from 'react-router'

import { Skeleton } from 'antd/es'

import { useGetSolutionQuery } from '@/redux/solutions'

const SolutionBreadCrumb = (): JSX.Element => {
  const { id } = useParams()
  const { data: selectedSolution, isLoading: loading } = useGetSolutionQuery(parseInt(id ?? ''))

  if (loading) {
    return <Skeleton.Button active style={{ maxHeight: 20, width: 30 }} />
  }
  if (selectedSolution && !selectedSolution.title) {
    return <>{id}</>
  }
  return <>{selectedSolution?.title}</>
}

export default SolutionBreadCrumb
