import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ClimateAction } from '@cozero/models'

import { ClimateActionTab } from '@/organisms/ClimateActionsTable'

import climateActionsApiSlice, { DELETE_CLIMATE_ACTION } from '@/redux/climateActions/api'

import { SLICE_NAME_CLIMATE_ACTIONS } from './constants'

type ClimateActionsState = {
  selectedActions: ClimateAction['id'][]
  selectedTab: ClimateActionTab
}

const initialState: ClimateActionsState = {
  selectedActions: [],
  selectedTab: 'active',
}

const climateActionsSlice = createSlice({
  name: SLICE_NAME_CLIMATE_ACTIONS,
  initialState,
  reducers: {
    selectClimateActions: (state, { payload }: PayloadAction<ClimateAction['id'][]>) => {
      state.selectedActions = payload
    },
    selectClimateAction: (state, { payload }: PayloadAction<number>) => {
      state.selectedActions = [...state.selectedActions, payload]
    },
    deselectClimateAction: (state, { payload }: PayloadAction<number>) => {
      state.selectedActions = state.selectedActions.filter((id) => id !== payload)
    },
    selectClimateActionTab: (state, { payload }: PayloadAction<ClimateActionTab>) => {
      state.selectedTab = payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      climateActionsApiSlice.endpoints[DELETE_CLIMATE_ACTION].matchFulfilled,
      (state: ClimateActionsState, { payload }) => {
        state.selectedActions = state.selectedActions.filter((action) => action !== payload.id)
      },
    )
  },
})

export const {
  selectClimateActions,
  selectClimateAction,
  deselectClimateAction,
  selectClimateActionTab,
} = climateActionsSlice.actions

export default climateActionsSlice
