import React, { useMemo } from 'react'
import { useParams } from 'react-router'

import { Skeleton } from 'antd/es'

import { skipToken } from '@reduxjs/toolkit/query/react'
import { parseInt } from 'lodash-es'

import { useGetClimateActionQuery } from '@/redux/climateActions'

const ClimateActionsCrumb = (): JSX.Element => {
  const { id } = useParams()
  const { data: climateAction, isLoading: loading } = useGetClimateActionQuery(
    parseInt(id ?? '') ?? skipToken,
  )

  const climateActionName = useMemo(() => {
    const name = climateAction?.actionName
    if (name) {
      return name
    }
  }, [id, climateAction])

  if (loading) {
    return <Skeleton.Button active style={{ maxHeight: 20, width: 30 }} />
  }
  if (!climateActionName) {
    return <>{id}</>
  }
  return <>{climateActionName}</>
}

export default ClimateActionsCrumb
