import { useEffect } from 'react'

import history from 'history/browser'

export const useBackListener = (callback: () => void): void => {
  useEffect(() => {
    const listener = ({ action }: { action: string }): void => {
      if (action === 'POP') {
        callback()
      }
    }

    return history.listen(listener)
  }, [callback])
}
