import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd/es'

import Title from '@/atoms/Title'

import UserForm from './UserForm'
import classes from './classes.module.less'

function UserProfile(): JSX.Element {
  const { t } = useTranslation('common')

  return (
    <Row justify="center">
      <Col span={22}>
        <Row>
          <Col span={24}>
            <Row className={classes.section}>
              <Col span={24}>
                <Title size="sm">{t('nav.profile')}</Title>
              </Col>
            </Row>
            <Row className={classes.section}>
              <Col xs={24} md={12}>
                <UserForm />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default UserProfile
