import { Subscription, SubscriptionCheckoutSession, SubscriptionProduct } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import axios from '@/utils/axios'
import { getUrl } from '@/utils/url'

export async function getCalendarUrl(): Promise<string> {
  const url = getUrl(centralApiGatewayClient.subscriptions.GET_SALES_CALENDAR)

  const calendarResponse = await axios.get(url.toString())

  return calendarResponse.data
}

export async function getCheckoutUrl(productId: string): Promise<string> {
  const url = getUrl(centralApiGatewayClient.subscriptions.GET_CHECKOUT_URL)
  url.searchParams.append('productId', productId)
  const checkoutResponse = await axios.get(url.toString())

  return checkoutResponse.data
}

export async function getCustomerPortalUrl(): Promise<string> {
  const url = getUrl(centralApiGatewayClient.subscriptions.GET_CUSTOMER_PORTAL)

  const customerPortalResponse = await axios.get(url.toString())

  return customerPortalResponse.data
}

export async function getSubscription(): Promise<Subscription> {
  const url = getUrl(centralApiGatewayClient.subscriptions.GET_ACTIVE)

  const subscriptionResponse = await axios.get(url.toString())

  return subscriptionResponse.data
}

export async function getSubscriptionProducts(): Promise<SubscriptionProduct[]> {
  const url = getUrl(centralApiGatewayClient.subscriptions.GET_PRODUCTS)

  const subscriptionProductsResponse = await axios.get(url.toString())

  return subscriptionProductsResponse.data
}

export async function getCheckoutSession(sessionId: string): Promise<SubscriptionCheckoutSession> {
  const url = getUrl(
    centralApiGatewayClient.subscriptions.GET_CHECKOUT_SESSION.replace(':id', sessionId),
  )

  const checkoutSessionResponse = await axios.get(url.toString())

  return checkoutSessionResponse.data
}
