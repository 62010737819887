import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { useParams } from 'react-router'

import { Col, Row } from 'antd'
import { ColumnsType } from 'antd/es/table'

import { skipToken } from '@reduxjs/toolkit/query/react'

import { Quantity } from '@cozero/models'

import HighlightValue from '../../../../atoms/HighlightValue'
import * as Skeleton from '../../../../atoms/Skeleton'
import useQuantities from '../../../../hooks/useQuantities'
import useQuantitiesTableColumns from '../../../../hooks/useQuantitiesTableColumns'
import { OverviewCardProps } from '../../../../molecules/OverviewCard'
import Table from '../../../../molecules/Table'
import OverviewRow from '../../../../organisms/OverviewRow'
import { useGetProductQuery } from '../../../../redux/products'
import { formatNumber } from '../../../../utils/number'

import classes from './ProductQuantities.module.less'

const ProductQuantities = (): ReactElement => {
  const { productId } = useParams()
  const { t } = useTranslation('common')
  const { data: product, isLoading } = useGetProductQuery(productId ?? skipToken)
  const totalEmissions: number = useMemo(
    () =>
      product?.productEmissions?.length
        ? product.productEmissions.reduce((acc, { value }) => acc + value, 0)
        : 0,
    [product],
  )
  const columnsData = useQuantitiesTableColumns(totalEmissions)
  const { data, loading } = useQuantities({
    filters: [
      {
        key: 'productId',
        type: 'relationship',
        label: 'Product',
        selectedCondition: {
          key: 'in',
          label: 'Is',
        },
        logType: 'quantities',
        value: [product?.id || -1],
        conditions: [],
      },
    ],
    sorters: [
      {
        key: 'id',
        label: 'id',
        logType: 'quantities',
        selectedSort: 'desc',
      },
    ],
  })

  const overviewData: OverviewCardProps[] = useMemo(() => {
    const emissions = product?.productEmissions.reduce((acc, x) => acc + x.value, 0) ?? 0
    const totalQuantities = product?.quantities?.reduce((acc, x) => acc + x.volume, 0) ?? 0
    return [
      {
        content: (
          <div>
            <HighlightValue value={formatNumber(emissions * totalQuantities)} unit="tCO₂e" />
          </div>
        ),
        headerTitle: t('product.lifecycle-steps.quantity.total-emissions'),
      },
      {
        content: (
          <div>
            <Row>
              <Skeleton.Paragraph size="fill" loading={isLoading}>
                <HighlightValue value={formatNumber(totalQuantities)} />
              </Skeleton.Paragraph>
            </Row>
          </div>
        ),
        headerTitle: t('product.lifecycle-steps.quantity.number-of-units'),
      },
    ]
  }, [product])
  return (
    <>
      <OverviewRow
        loadingOverview={false}
        marginBetween={14}
        overviewData={overviewData}
        hideTitle={true}
      />
      <Row className={classes.title}>{t('layout.quantities')}</Row>
      <Skeleton.Paragraph loading={loading} active={loading}>
        <Table
          columns={columnsData as ColumnsType<Quantity>}
          dataSource={data}
          pagination={(data ?? []).length > 10 ? { pageSize: 10 } : false}
        ></Table>
      </Skeleton.Paragraph>
    </>
  )
}

export default ProductQuantities
