import { useCallback } from 'react'

import { useSWRConfig } from 'swr'

const useSWRClearCache = (): (() => void) => {
  const { cache, mutate } = useSWRConfig()

  return useCallback(() => {
    const swrKeys: string[] = Array.from((cache as Map<string, string | []>).keys())
    swrKeys.forEach((key) => cache.delete(key))
  }, [])
}

export default useSWRClearCache
