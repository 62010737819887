import { Store } from 'antd/es/form/interface'

import { ConfigureApiIntegrationDto } from '@cozero/dtos'
import { ApiIntegration } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_API_INTEGRATIONS } from './tags'

export const GET_INTEGRATIONS = 'getIntegrations'
export const CONFIGURE_INTEGRATION = 'configureIntegration'
export const CHECK_INTEGRATION_CONNECTION = 'checkIntegrationConnection'
export const MANUAL_IMPORT = 'manualImport'

const apiIntegrationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_INTEGRATIONS]: builder.query<ApiIntegration[], void>({
      query: () => ({
        url: centralApiGatewayClient.apiIntegrations.GET_MANY,
        method: 'GET',
      }),
      providesTags: (result) =>
        providesList<ApiIntegration[], typeof TAG_API_INTEGRATIONS>(result, TAG_API_INTEGRATIONS),
    }),
    [CONFIGURE_INTEGRATION]: builder.mutation<
      void,
      { integrationId: string; data: ConfigureApiIntegrationDto }
    >({
      query: ({
        integrationId,
        data,
      }: {
        integrationId: string
        data: ConfigureApiIntegrationDto
      }) => ({
        url: centralApiGatewayClient.apiIntegrations.CONFIGURE.replace(':id', integrationId),
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: TAG_API_INTEGRATIONS }],
      extraOptions: { maxRetries: 0 },
    }),
    [MANUAL_IMPORT]: builder.mutation<
      void,
      { integrationConfigurationId: number; startDate: string; endDate: string }
    >({
      query: ({
        integrationConfigurationId,
        startDate,
        endDate,
      }: {
        integrationConfigurationId: number
        startDate: string
        endDate: string
      }) => ({
        url: centralApiGatewayClient.apiIntegrations.MANUAL_IMPORT.replace(
          ':id',
          integrationConfigurationId.toString(),
        ),
        method: 'POST',
        params: {
          startDate,
          endDate,
        },
      }),
    }),
    [CHECK_INTEGRATION_CONNECTION]: builder.mutation<
      boolean,
      { integrationId: string; values: Store }
    >({
      query: ({ integrationId, values }: { integrationId: string; values: Store }) => ({
        url: centralApiGatewayClient.apiIntegrations.CHECK_CONNECTION.replace(':id', integrationId),
        method: 'POST',
        data: values,
      }),
    }),
  }),
})

export const {
  useGetIntegrationsQuery,
  useConfigureIntegrationMutation,
  useManualImportMutation,
  useCheckIntegrationConnectionMutation,
} = apiIntegrationsApiSlice
export default apiIntegrationsApiSlice
