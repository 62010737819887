import { QuantityTag } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import axios from '../utils/axios'

interface QuantityTagsHook {
  getQuantityTags: (pattern?: string) => Promise<QuantityTag[]>
  createQuantityTag: (name: string) => Promise<QuantityTag | undefined>
  deleteQuantityTag: (tagId: string | number) => Promise<void>
}

/**
 * New hook that handles all API related matters related to the quantities
 * @param {Object} params
 * @param {PageFilter[]} params.filters
 * @param {LogSorter[]} params.sorters
 */
const useQuantityTags = (): QuantityTagsHook => {
  const getQuantityTags = async (pattern = ''): Promise<QuantityTag[]> => {
    let tags: QuantityTag[] = []
    const url = logApiGatewayClient.quantityTags.GET_MANY
    const queryString = pattern ? `?name=${pattern}` : ''
    const tagsResponse = await axios.get(`${url}${queryString}`)
    tags = tagsResponse.data as QuantityTag[]
    return tags
  }
  const createQuantityTag = async (name: string): Promise<QuantityTag | undefined> => {
    const tagsResponse = await axios.post(logApiGatewayClient.quantityTags.CREATE, { name })
    const tag: QuantityTag = tagsResponse.data as QuantityTag
    return tag
  }
  const deleteQuantityTag = async (tagId: string | number): Promise<void> => {
    await axios.delete(logApiGatewayClient.quantityTags.DELETE_ONE.replace(':id', `${tagId}`))
  }

  return {
    getQuantityTags,
    createQuantityTag,
    deleteQuantityTag,
  }
}

export default useQuantityTags
