import React from 'react'
import { useTranslation } from 'react-i18next'

import { Cascader } from 'antd/es'

import ActivityDataSourceCascader from '@/organisms/ActivityDataSourcesCascader'

import { LifecycleStepCardProps } from '@/molecules/LifecycleStepCard'

import Button from '@/atoms/Button'
import InputField from '@/atoms/InputField'
import Select from '@/atoms/Select'

import composition from '@/assets/lifecycle-steps/composition.svg'
import distributionAndStorage from '@/assets/lifecycle-steps/distribution-and-storage.svg'
import endOfLife from '@/assets/lifecycle-steps/end-of-life.svg'
import packaging from '@/assets/lifecycle-steps/packaging.svg'
import productConfiguration from '@/assets/lifecycle-steps/product-configuration.svg'
import production from '@/assets/lifecycle-steps/production.svg'
import quantity from '@/assets/lifecycle-steps/quantity.svg'
import usage from '@/assets/lifecycle-steps/usage.svg'

export enum LifecycleStepsKey {
  'PRODUCT_CONFIGURATION' = 'product-configuration',
  'PRODUCT_COMPOSITION' = 'product-composition',
  'PRODUCTION' = 'production',
  'PACKAGING' = 'packaging',
  'DISTRIBUTION_AND_STORAGE' = 'distribution-and-storage',
  'USAGE' = 'usage',
  'QUANTITIES' = 'quantities',
  'END_OF_LIFE' = 'end-of-life',
}

export enum LifecycleStepsID {
  'product-configuration' = 1,
  'product-composition' = 2,
  'production' = 3,
  'packaging' = 4,
  'distribution-and-storage' = 5,
  'usage' = 6,
  'end-of-life' = 7,
  'quantities' = 8,
}

export enum GenericFieldKey {
  'TITLE' = 'title',
  'LOG_CATEGORY' = 'categoryId',
  'LOG_SUBCATEGORY' = 'subcategoryId',
  'CALCULATION_METHOD' = 'calculationMethodId',
  'ACTIVITY_DATA_SOURCE' = 'activityDataSourceId',
  'SUPPLIER' = 'supplierId',
  'INPUTS' = 'inputs',
  'TERRITORY' = 'territoryId',
}

export interface Step extends Omit<LifecycleStepCardProps, 'index'> {
  path?: string
  stepKey: LifecycleStepsKey
}

interface StepList {
  productIdToEdit?: number
  selectedSteps: LifecycleStepsKey[]
  childRef: React.MutableRefObject<{
    onNext: () => void
    onReturn: () => void
    onClearData: () => void
  }>
  handleLifecycleChange: (value: boolean, index: number, isActive: boolean) => void
}

export interface Field {
  key: GenericFieldKey
  label?: string
  tooltip?: string
  component: JSX.Element
  additionalComponent?: JSX.Element
}

type OptionalField = Field & { position: number }

interface StepContext {
  stepList: ({ handleLifecycleChange, selectedSteps }: StepList) => Step[]
  generateFields: (optionalFields?: OptionalField[]) => Field[]
  compositionOptionalFields: OptionalField[]
  productionOptionalFields: OptionalField[]
  distributionAndStorageOptionalFields: OptionalField[]
  genericSteps: LifecycleStepsKey[]
  mandatoryGenericFields: Field[]
}

export const useStep = (): StepContext => {
  const { t } = useTranslation('common')

  const stepList = ({ handleLifecycleChange, selectedSteps }: StepList): Step[] => {
    return [
      {
        imageSrc: productConfiguration,
        path: 'configuration',
        title: t('product.lifecycle-steps.configuration.title'),
        description: t('product.lifecycle-steps.configuration.description'),
        selectedLifecycle: handleLifecycleChange,
        isDisabled: true,
        initialValue: true,
        stepKey: LifecycleStepsKey.PRODUCT_CONFIGURATION,
      },
      {
        imageSrc: composition,
        title: t('product.lifecycle-steps.composition.title'),
        description: t('product.lifecycle-steps.composition.description'),
        selectedLifecycle: handleLifecycleChange,
        initialValue: selectedSteps.some((step) => step === LifecycleStepsKey.PRODUCT_COMPOSITION),
        stepKey: LifecycleStepsKey.PRODUCT_COMPOSITION,
        path: 'composition',
      },
      {
        imageSrc: production,
        title: t('product.lifecycle-steps.production.title'),
        description: t('product.lifecycle-steps.production.description'),
        selectedLifecycle: handleLifecycleChange,
        initialValue: selectedSteps.some((step) => step === LifecycleStepsKey.PRODUCTION),
        stepKey: LifecycleStepsKey.PRODUCTION,
        path: 'production',
      },
      {
        imageSrc: packaging,
        title: t('product.lifecycle-steps.packaging.title'),
        description: t('product.lifecycle-steps.packaging.description'),
        selectedLifecycle: handleLifecycleChange,
        initialValue: selectedSteps.some((step) => step === LifecycleStepsKey.PACKAGING),
        stepKey: LifecycleStepsKey.PACKAGING,
        path: 'packaging',
      },
      {
        imageSrc: distributionAndStorage,
        title: t('product.lifecycle-steps.distribution-and-storage.title'),
        description: t('product.lifecycle-steps.distribution-and-storage.description'),
        selectedLifecycle: handleLifecycleChange,
        initialValue: selectedSteps.some(
          (step) => step === LifecycleStepsKey.DISTRIBUTION_AND_STORAGE,
        ),
        stepKey: LifecycleStepsKey.DISTRIBUTION_AND_STORAGE,
        path: 'distribution-and-storage',
      },
      {
        imageSrc: usage,
        title: t('product.lifecycle-steps.usage.title'),
        description: t('product.lifecycle-steps.usage.description'),
        selectedLifecycle: handleLifecycleChange,
        initialValue: selectedSteps.some((step) => step === LifecycleStepsKey.USAGE),
        stepKey: LifecycleStepsKey.USAGE,
        path: 'usage',
      },
      {
        imageSrc: endOfLife,
        title: t('product.lifecycle-steps.end-of-life.title'),
        description: t('product.lifecycle-steps.end-of-life.description'),
        selectedLifecycle: handleLifecycleChange,
        initialValue: selectedSteps.some((step) => step === LifecycleStepsKey.END_OF_LIFE),
        stepKey: LifecycleStepsKey.END_OF_LIFE,
        path: 'end-of-life',
      },
      {
        imageSrc: quantity,
        title: t('product.lifecycle-steps.quantities.title'),
        description: t('product.lifecycle-steps.quantities.description'),
        selectedLifecycle: handleLifecycleChange,
        stepKey: LifecycleStepsKey.QUANTITIES,
        path: 'quantities',
        initialValue: true,
        isDisabled: true,
      },
    ]
  }

  const genericSteps: LifecycleStepsKey[] = [
    LifecycleStepsKey.PRODUCT_COMPOSITION,
    LifecycleStepsKey.PRODUCTION,
    LifecycleStepsKey.PACKAGING,
    LifecycleStepsKey.DISTRIBUTION_AND_STORAGE,
    LifecycleStepsKey.USAGE,
    LifecycleStepsKey.END_OF_LIFE,
  ]

  const generateFields = (optionalFields?: OptionalField[]): Field[] => {
    const fields = [...mandatoryGenericFields]
    if (!optionalFields) {
      return fields
    }
    optionalFields.forEach(({ position, component, key, label, additionalComponent, tooltip }) => {
      fields.splice(position, 0, { key, component, label, additionalComponent, tooltip })
    })
    return fields
  }

  const mandatoryGenericFields: Field[] = [
    {
      key: GenericFieldKey.TITLE,
      label: t('product.lifecycle-steps.generic-fields.part-name'),
      component: <InputField></InputField>,
    },
    {
      key: GenericFieldKey.LOG_SUBCATEGORY,
      label: t('product.lifecycle-steps.generic-fields.log-sub-category'),
      component: (
        <Select
          filterOption={true}
          showSearch
          optionFilterProp="label"
          placeholder={t('product.lifecycle-steps.generic-fields.log-sub-category')}
        ></Select>
      ),
    },
    {
      key: GenericFieldKey.CALCULATION_METHOD,
      label: t('product.lifecycle-steps.generic-fields.what-type-of-data-are-you-accounting'),
      component: (
        <Select
          filterOption={true}
          optionFilterProp="label"
          showSearch
          placeholder={t(
            'product.lifecycle-steps.generic-fields.what-type-of-data-are-you-accounting-placeholder',
          )}
        ></Select>
      ),
    },
    {
      key: GenericFieldKey.ACTIVITY_DATA_SOURCE,
      label: t('product.lifecycle-steps.generic-fields.source-of-the-data'),
      component: <ActivityDataSourceCascader allowClear={false} />,
    },
    {
      key: GenericFieldKey.TERRITORY,
      label: t('product.lifecycle-steps.generic-fields.where-was-this-data-procured-from'),
      component: (
        <Cascader
          showSearch
          style={{ width: '100%' }}
          dropdownMatchSelectWidth={false}
          size="large"
          placeholder={t(
            'product.lifecycle-steps.generic-fields.where-was-this-data-procured-from-placeholder',
          )}
        />
      ),
    },
  ]

  const compositionOptionalFields: OptionalField[] = [
    {
      key: GenericFieldKey.LOG_CATEGORY,
      label: t('product.lifecycle-steps.generic-fields.log-category'),
      component: (
        <Select
          showSearch
          filterOption={true}
          optionFilterProp="label"
          placeholder={t('product.lifecycle-steps.generic-fields.log-category')}
        ></Select>
      ),
      position: 1,
    },
    {
      key: GenericFieldKey.SUPPLIER,
      label: t('product.lifecycle-steps.generic-fields.who-is-the-provider'),
      tooltip: t('product.lifecycle-steps.generic-fields.who-is-the-provider-tooltip'),
      component: (
        <Select
          showSearch
          style={{ width: '100%' }}
          optionFilterProp="label"
          dropdownMatchSelectWidth={false}
          size="large"
          filterOption={(input, option) =>
            (option?.label as string)?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
        />
      ),
      additionalComponent: (
        <Button category="supplier" action="add" type="text">
          {t('suppliers.add')}
        </Button>
      ),
      position: 5,
    },
  ]

  const productionOptionalFields: OptionalField[] = [
    {
      key: GenericFieldKey.LOG_CATEGORY,
      label: t('product.lifecycle-steps.generic-fields.log-category'),
      component: (
        <Select
          showSearch
          filterOption={true}
          optionFilterProp="label"
          placeholder={t('product.lifecycle-steps.generic-fields.log-category')}
        ></Select>
      ),
      position: 1,
    },
  ]

  const distributionAndStorageOptionalFields: OptionalField[] = [
    {
      key: GenericFieldKey.LOG_CATEGORY,
      label: t('product.lifecycle-steps.generic-fields.log-category'),
      component: (
        <Select
          showSearch
          filterOption={true}
          optionFilterProp="label"
          placeholder={t('product.lifecycle-steps.generic-fields.log-category')}
        ></Select>
      ),
      position: 1,
    },
  ]

  return {
    stepList,
    generateFields,
    compositionOptionalFields,
    productionOptionalFields,
    distributionAndStorageOptionalFields,
    genericSteps,
    mandatoryGenericFields,
  }
}
