import React, { useMemo } from 'react'

import { Steps as AntdStepper } from 'antd/es'

import { Product } from '@cozero/models'

import { useLifecycleContext } from '@/contexts/lifecycle'

const { Step: AntdStep } = AntdStepper

export interface StepperProps {
  size: 'small' | 'default'
  direction: 'horizontal' | 'vertical'
  handleAction: (action: number) => void
  product?: Product
}

const Stepper = ({ size, direction, handleAction, product }: StepperProps): JSX.Element => {
  const { currentStep, stepsToDisplay: steps } = useLifecycleContext()

  const step = useMemo(() => {
    return steps.map(({ title, description }, i) => (
      <AntdStep title={title} description={description} key={i} />
    ))
  }, [steps, currentStep])

  return (
    <AntdStepper
      onChange={currentStep > 0 || product ? handleAction : undefined}
      direction={direction}
      size={size}
      current={currentStep}
    >
      {step}
    </AntdStepper>
  )
}

export default Stepper
