import { RootState } from '..'

import { MethodologyChangelogState } from './slice'

export const selectMethodologyChangelogs = (
  state: RootState,
): MethodologyChangelogState['methodologyChangelogs'] =>
  state.methodologyChangelogs.methodologyChangelogs

export const selectCalculationChangelogRecord = (
  state: RootState,
): MethodologyChangelogState['selectedCalculationChangelogRecord'] =>
  state.methodologyChangelogs.selectedCalculationChangelogRecord
