import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Layout, Row } from 'antd/es'

import Title from '@/atoms/Title'

const AppCrashFallbackUi = (): ReactElement => {
  const { t } = useTranslation('common')
  return (
    <Layout.Content style={{ height: '100vh' }}>
      <Row justify="center" align="middle" style={{ height: '100vh' }}>
        <Col>
          <Title size="sm">{t('general-errors.app-error')}</Title>
        </Col>
      </Row>
    </Layout.Content>
  )
}

export default AppCrashFallbackUi
