import React, { Dispatch, SetStateAction, useContext } from 'react'

import { ProductLifecycleStep } from '@prisma/client'
import { Moment } from 'moment'

import { Product, Supplier } from '@cozero/models'

import { LifecycleStepsKey } from '@/pages/Organization/NewProduct/steps'

import useAppHandler from '../hooks/useApp'

export interface AppContextInterface {
  error?: Error
  loading: boolean
  products: Product[]
  activeProducts?: Product[]
  dateRange: [Moment | null, Moment | null]
  dateError?: string
  setDateRange: (values: [Moment | null, Moment | null]) => void
  getProducts: (root?: boolean) => Promise<void>
  setDateError: Dispatch<SetStateAction<string | undefined>>
  onSearch: (key: string, search: string) => Promise<void>
  getSuppliers: () => void
  suppliers: Supplier[]
  createSupplier: (values: Supplier) => Promise<Supplier | null | undefined>
  updateSupplier: (id: number, values: Supplier) => Promise<void>
  deleteSupplier: (id: number) => Promise<void>
  getOrganizationSuppliers: () => Promise<Supplier[] | void>
  organizationSuppliers: Supplier[]
  getLifecycleSteps: (
    lifecycleSteps?: LifecycleStepsKey[],
  ) => Promise<ProductLifecycleStep[] | void>
  lifecycleSteps: ProductLifecycleStep[]
}

export const appContext = React.createContext<AppContextInterface | undefined>(undefined)

const { Provider } = appContext

export function useAppContext(): AppContextInterface {
  try {
    const contextValue = useContext(appContext)
    if (!contextValue) {
      throw new Error('useAppContext must be used within an AppProvider')
    }
    return contextValue
  } catch (e) {
    throw new Error(e)
  }
}

interface ProviderProps {
  children: React.ReactNode
}

const AppProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const appAccessors = useAppHandler()
  return <Provider value={appAccessors}>{children}</Provider>
}

export default AppProvider
