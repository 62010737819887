import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { HiArrowRight } from 'react-icons/hi'

import { Col, Row } from 'antd/es'

import Card from '@/atoms/Card'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import logo from '@/assets/demo-icon.svg'
import teachableLogo from '@/assets/teachable-icon.svg'
import wiki from '@/assets/wiki-icon.svg'

import classes from './ResourceCenterCard.module.less'

interface Props {
  close?: () => void
  className?: string
  title: string
  subtitle: string
  url: string
  linkText?: string
  type?: string
  cardNumber?: string
  image?: string
  logo?: string
}

type ResourceCenterCardProps = {
  title?: string
}

const ResourceCard = ({ title, logo, subtitle, linkText, url }: Props): ReactElement => (
  <Card shadow="none" type="flat" className={classes.resourceCard}>
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <img src={logo} className={classes.logo} alt="logo" />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <div className={classes.header}>
                  <Title as="h5" size="xs">
                    {title}
                  </Title>
                </div>
              </Col>
            </Row>
            {subtitle && (
              <Text size="xl" color="secondary" fontWeight="regular" className={classes.subtitle}>
                {subtitle}
              </Text>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
    {linkText && (
      <Row>
        <Col span={24}>
          <Text fontWeight="regular" size="xl" className={classes.supportText}>
            <a href={url} target="_blank" rel="noreferrer">
              {linkText}
            </a>
            <HiArrowRight className={classes.icon} />
          </Text>
        </Col>
      </Row>
    )}
  </Card>
)

function ResourceCenterCard({ title }: ResourceCenterCardProps): JSX.Element {
  const { t } = useTranslation('common')
  return (
    <Row>
      <Col span={24}>
        <Row className={classes.bottomCard}>
          <Col span={24}>
            <Text size="xl" fontWeight="regular" className={classes.text}>
              {title}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row justify="space-between" gutter={[16, 8]}>
          <Col span={8}>
            <ResourceCard
              title={t('onboarding.resource-cards.1.title')}
              subtitle={t('onboarding.resource-cards.1.subtitle')}
              linkText={t('onboarding.resource-cards.1.btn-text')}
              url={t('intercom.resourceCards.dataSourcing')}
              logo={wiki}
            />
          </Col>
          <Col span={8}>
            <ResourceCard
              title={t('onboarding.resource-cards.2.title')}
              subtitle={t('onboarding.resource-cards.2.subtitle')}
              linkText={t('onboarding.resource-cards.2.btn-text')}
              url={t('intercom.resourceCards.emissionCategories')}
              logo={logo}
            />
          </Col>
          <Col span={8}>
            <ResourceCard
              logo={teachableLogo}
              title={t('onboarding.resource-cards.3.title')}
              subtitle={t('onboarding.resource-cards.3.subtitle')}
              linkText={t('onboarding.resource-cards.3.btn-text')}
              url={t('onboarding.resource-cards.3.link')}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ResourceCenterCard
