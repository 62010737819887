import { UserFunction } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import axios from '@/utils/axios'
import { getUrl } from '@/utils/url'

export async function getUserFunctions(): Promise<UserFunction[]> {
  const url = getUrl(centralApiGatewayClient.userFunctions.GET_MANY)
  const getUserFunction = await axios.get(url.toString())

  return getUserFunction.data
}

export async function createUserFunction(body: Partial<UserFunction>): Promise<UserFunction> {
  const url = getUrl(centralApiGatewayClient.userFunctions.CREATE)
  const createUserFunction = await axios.post(url.toString(), body)

  return createUserFunction.data
}

export async function updateUserFunction(
  id: number,
  body: Partial<UserFunction>,
): Promise<UserFunction> {
  const url = getUrl(centralApiGatewayClient.userFunctions.UPDATE_ONE.replace(':id', `${id}`))
  const updateUserFunction = await axios.put(url.toString(), body)

  return updateUserFunction.data
}

export async function deleteUserFunction(id: number): Promise<void> {
  const url = getUrl(centralApiGatewayClient.userFunctions.DELETE_ONE.replace(':id', `${id}`))
  const deleteUserFunctionResponse = await axios.delete(url.toString())

  return deleteUserFunctionResponse.data
}
