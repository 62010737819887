import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { HiArrowRight, HiCheck, HiOutlineBookOpen } from 'react-icons/hi'
import { useNavigate } from 'react-router'

import { Col, Row } from 'antd/es'

import Button from '@/atoms/Button'
import Card from '@/atoms/Card'
import Tag from '@/atoms/Tag'
import Title from '@/atoms/Title'

import classes from './OnboardingStepCard.module.less'

interface Props {
  title: string
  subtitle: string
  cardNumber: number
  wikiLink: string
  wikiLinkText: string
  actionLink: string
  open: boolean
  className?: string
  completed?: boolean
}

const OnboardingStepCard = ({
  title,
  subtitle,
  completed,
  cardNumber,
  wikiLink,
  wikiLinkText,
  actionLink,
  open,
}: Props): ReactElement => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  return (
    <Card shadow="none">
      <Row>
        <Col span={1}>
          <div className={`${classes.circleIcon} ${completed && classes.completed}`}>
            {completed ? <HiCheck /> : <span>{cardNumber}</span>}
          </div>
        </Col>
        <Col xs={21} xxl={22}>
          <div className={classes.header}>
            <Title size="xs" className={classes.title}>
              {title}
            </Title>
            <p className={classes.subtitle}>{subtitle}</p>
          </div>
        </Col>
        <Col xs={2} xxl={1}>
          <Tag className={classes.tag} type={completed ? 'success' : 'info'}>
            {completed ? t('onboarding.complete') : t('onboarding.demo.tag')}
          </Tag>
        </Col>
      </Row>
      {(completed || open) && (
        <Row>
          <Col span={16} offset={1}>
            <a href={wikiLink} className={classes.supportText} target="_blank" rel="noreferrer">
              <HiOutlineBookOpen className={classes.icon} />
              <span>{wikiLinkText}</span>
            </a>
          </Col>
        </Row>
      )}
      {(completed || open) && (
        <Row justify="end">
          <Col>
            <Button
              type={completed ? 'secondary' : 'primary'}
              category="onboarding"
              action="start-onboarding"
              className={classes.cardBtn}
              onClick={() => navigate(actionLink)}
              fillParent
              suffixIcon={<HiArrowRight />}
            >
              {t('onboarding.demo.start')}
            </Button>
          </Col>
        </Row>
      )}
    </Card>
  )
}

export default OnboardingStepCard
