import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Query } from '@cubejs-client/core'

import Text from '@/atoms/Text'

import { CINDER_BLUE_50 } from '@/styles/variables'

interface IProps {
  cubeJSQuery: Query
}

export const UnitMeasureText = ({ cubeJSQuery }: IProps): JSX.Element => {
  const { t } = useTranslation('common')

  const text = useMemo((): string => {
    return `${t(`reports.statisticCard-keys.${cubeJSQuery.measures?.[0]}`)} (${t(
      `reports.statisticCard-unit.${cubeJSQuery.measures?.[0]}`,
    )})`
  }, [cubeJSQuery.measures])

  return (
    <Text size="lg" fontWeight="medium" style={{ color: CINDER_BLUE_50 }}>
      {text}
    </Text>
  )
}
