import { useCallback, useEffect } from 'react'

import { User } from '@cozero/models'

import { useAppSelector } from '@/redux'
import { useLazyGetUserDataQuery } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useLazyGetOrganizationUsersQuery } from '@/redux/organizations'

interface IReturn {
  refetchData: () => void
  user?: User
  users?: User[]
  loading: boolean
}

export const useFetchData = (): IReturn => {
  const [fetchUser, { data: user, isLoading: loadingUser }] = useLazyGetUserDataQuery()
  const [fetchUsers, { data: users, isLoading: loadingUsers }] = useLazyGetOrganizationUsersQuery()

  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)

  const fetchData = useCallback(() => {
    fetchUser()
    fetchUsers({ businessUnit: selectedBusinessUnit?.key ?? '' })
  }, [])

  useEffect(fetchData, [])

  return {
    refetchData: fetchData,
    user,
    users,
    loading: loadingUser && loadingUsers,
  }
}
