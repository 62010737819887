import React, { ReactElement } from 'react'

import { Col, Row } from 'antd/es'

import classNames from 'classnames'

import classes from './HighlightValue.module.less'

interface Props {
  value: string
  unit?: string
  leftUnit?: string
  striked?: boolean
}

const HighlightValue = ({ value, unit, leftUnit, striked }: Props): ReactElement => {
  const valueClasses = classNames(classes.content, { [classes.striked]: striked })
  return (
    <Row className={classes.alignRow}>
      {leftUnit && <Col className={classes.leftUnit}>{leftUnit}</Col>}
      <Col className={valueClasses}>{value}</Col>
      {unit && <Col className={classes.unitMargin}>{unit}</Col>}
    </Row>
  )
}

export default HighlightValue
