export const TAG_LOCATIONS = 'LOCATIONS'
export const TAG_PAGINATED_LOCATIONS = 'LOCATIONS'
export const TAG_ACTIVE_LOCATIONS = 'LOCATIONS'
export const TAG_GEOLOCATIONS = 'GEOLOCATIONS'
export const TAG_LOCATION_LOGS = 'LOCATION_LOGS'

export default [
  TAG_LOCATIONS,
  TAG_GEOLOCATIONS,
  TAG_LOCATION_LOGS,
  TAG_ACTIVE_LOCATIONS,
  TAG_PAGINATED_LOCATIONS,
]
