import { Chart } from '@antv/g2'

export const SVGToDataURL = (chart: Chart): string => {
  const canvas = chart.getCanvas()
  const canvasDom = canvas.get('el')
  const clone = canvasDom.cloneNode(true)
  const svgDocType = document.implementation.createDocumentType(
    'svg',
    '-//W3C//DTD SVG 1.1//EN',
    'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd',
  )
  const svgDoc = document.implementation.createDocument(
    'http://www.w3.org/2000/svg',
    'svg',
    svgDocType,
  )
  svgDoc.replaceChild(clone, svgDoc.documentElement)
  const svgData = new XMLSerializer().serializeToString(svgDoc)
  return 'data:image/svg+xml;charset=utf8,' + encodeURIComponent(svgData)
}

// Reference: https://github.com/antvis/G2/issues/2122#issuecomment-596875986
// G2Plot does not provide a built-in method to download images, so we use this workaround provided by G2
/**
 * Download image from G2 chart
 * @param chart G2 canvas chart instance (Graphs with rendered prop = 'canvas')
 * @param name file name (optional)
 * @returns void
 **/
export const downloadImage = (chart: Chart | undefined, name = 'G2Chart'): void => {
  if (!chart) {
    return
  }
  const link = document.createElement('a')
  const renderer = chart.renderer
  const filename = `${name}${renderer === 'svg' ? '.svg' : '.png'}`
  const canvas = chart.getCanvas()
  canvas.get('timeline')?.stopAllAnimations()

  setTimeout(() => {
    if (window.Blob && window.URL && renderer !== 'svg') {
      const canvasDom = canvas.get('el')
      const arr = canvasDom.toDataURL('image/png').split(',')
      const mime = arr[0]?.match(/:(.*?);/)?.[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      const blobObj = new Blob([u8arr], { type: mime })
      link.addEventListener('click', () => {
        link.download = filename
        link.href = window.URL.createObjectURL(blobObj)
      })
    } else {
      link.addEventListener('click', () => {
        link.download = filename
        link.href = SVGToDataURL(chart)
      })
    }
    const e = document.createEvent('MouseEvents')
    e.initEvent('click', false, false)
    link.dispatchEvent(e)
  }, 16)
}

const svgToPng = (svg: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const img = new Image()
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx?.drawImage(img, 0, 0)
      resolve(canvas.toDataURL('image/png'))
    }
    img.onerror = reject
    img.src = svg
  })
}

/**
 * Download graph as image
 * @param chart G2 canvas chart instance (Graphs with rendered prop = 'canvas')
 * @param name file name (optional)
 * @param format file format (svg,png)
 * @returns void
 **/
export const downloadGraph = async ({
  chart,
  name = 'graph',
  format,
}: {
  chart: Chart | undefined
  name?: string
  format: string
}): Promise<void> => {
  if (!chart) {
    return
  }

  const link = document.createElement('a')

  const timestamp = new Date().toISOString()
  const filename = `${name}-${timestamp}.${format}`

  const canvas = chart.getCanvas()
  canvas.get('timeline')?.stopAllAnimations()

  const svgURL = SVGToDataURL(chart)

  if (format === 'svg') {
    link.addEventListener('click', () => {
      link.download = filename
      link.href = svgURL
    })
  } else {
    const pngUrl = await svgToPng(svgURL)
    link.addEventListener('click', () => {
      link.download = filename
      link.href = pngUrl
    })
  }

  const e = document.createEvent('MouseEvents')
  e.initEvent('click', false, false)
  link.dispatchEvent(e)
}
