import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Annotation, LineOptions, Options, Plot } from '@antv/g2plot'
import { Series, SeriesNamesColumn } from '@cubejs-client/core'
import { LineChart, LineChartProps } from '@opd/g2plot-react'
import moment from 'moment'

import { Report, ReportType } from '@cozero/models'

import useGraphs from '@/hooks/useGraphs'
import { colors, legendStyles, padding, xAxisStyles, yAxisStyles } from '@/styles/graphs'
import { COZERO_BLUE_5, WHITE_NEUTRAL } from '@/styles/variables'
import { formatNumber } from '@/utils/number'

const config: Partial<LineChartProps> = {
  autoFit: true,
  xField: 'x',
  yField: 'value',
  smooth: false,
  lineStyle: {
    lineWidth: 4,
    lineCap: 'round',
  },
  slider: {
    start: 0,
    end: 1,
    trendCfg: {
      isArea: true,
      smooth: false,
      areaStyle: {
        fillOpacity: 0,
        fill: colors[0],
        stroke: colors[0],
        lineWidth: 2,
        lineOpacity: 0.3,
      },
    },
    backgroundStyle: {
      fill: WHITE_NEUTRAL,
      fillOpacity: 1,
    },
    foregroundStyle: {
      fill: COZERO_BLUE_5,
      fillOpacity: 0.2,
      opacity: 0.3,
    },
    textStyle: {
      fontSize: 12,
      fontFamily: 'Inter',
      fontWeight: 500,
    },
    formatter: (text: string) => moment(text).format('MMM YYYY'),
    height: 30,
  },
  seriesField: 'title',
  appendPadding: padding,
}

const Line = React.memo(
  ({
    height,
    report,
    chartData,
    chartRef,
    svgChartRef,
    visible,
    timeGranularity,
    disableSlider,
    showLegend,
    measure,
    keys,
    annotations,
    dimensionType,
  }: {
    height: number | null
    report?: Report
    chartData: Series<{ x: string; value: number }>[]
    chartRef?: React.MutableRefObject<Plot<Options> | null>
    svgChartRef?: React.MutableRefObject<Plot<Options> | null>
    visible: boolean
    timeGranularity?: 'month' | 'quarter' | 'year'
    measure?: string
    dimensionType?: string
    disableSlider?: boolean
    showLegend?: boolean
    keys: SeriesNamesColumn[]
    annotations?: Annotation[]
    padding?: string | number
  }): ReactElement | null => {
    const { graphStyles, customTooltip } = useGraphs({
      graphType: ReportType.LINE,
      dimensionType,
    })
    const { t, i18n } = useTranslation('common')

    if (!height) {
      return null
    }

    const props: LineOptions = {
      ...graphStyles,
      ...config,
      annotations: annotations ? [...annotations] : [],
      legend:
        showLegend === undefined || showLegend || report?.type === ReportType.FORECAST
          ? report?.type === ReportType.FORECAST
            ? {
                layout: 'horizontal',
                position: 'top',
                itemName: {
                  formatter: (title) => {
                    const preTitle = title
                      ?.toString()
                      .substring(0, title?.toString().lastIndexOf('_'))
                    return i18n.exists(`forecast.${preTitle}.title`)
                      ? `${t(`forecast.${preTitle}.title`)}`
                      : preTitle
                  },
                },
                ...legendStyles,
              }
            : {
                ...legendStyles,
                marker: {
                  symbol: 'hyphen',
                },
              }
          : false,
      xAxis: {
        label: {
          style: {
            ...xAxisStyles.label?.style,
          },
          formatter: (item) =>
            timeGranularity === 'month' || !timeGranularity
              ? moment(item).format('MMM YYYY')
              : timeGranularity === 'quarter'
              ? `Q${moment(item).quarter()} ${moment(item).year()}`
              : moment(item).year(),
        },
      },
      yAxis: {
        ...yAxisStyles,
        title: {
          style: yAxisStyles.title?.style,
          text: i18n.exists(`common:reports.legend.${keys?.[0].key.split(',')?.at(-1)}`)
            ? t(`reports.legend.${keys?.[0].key.split(',')?.at(-1)}`)
            : i18n.exists(`common:reports.legend.${keys?.[0].key}`)
            ? t(`reports.legend.${keys?.[0].key}`)
            : t('tonnes-emitted'),
        },
      },
      tooltip: {
        ...customTooltip,
        formatter: (item) => {
          if (report?.type === ReportType.FORECAST) {
            const preTitle = item.title
              ?.toString()
              .substring(0, item.title?.toString().lastIndexOf('_'))
            return {
              name: i18n.exists(`forecast.${preTitle}.title`)
                ? t(`forecast.${preTitle}.title`)
                : preTitle,
              value: `${formatNumber(item.value)} ${t('tonnes-emitted')}`,
              title: `${
                timeGranularity === 'month' || !timeGranularity
                  ? moment(item.x).format('MMM YYYY')
                  : timeGranularity === 'quarter'
                  ? `Q${moment(item.x).quarter()} ${moment(item.x).year()}`
                  : moment(item.x).year()
              }`,
            }
          }
          return {
            name: item.title,
            value: `${formatNumber(item.value)} ${
              i18n.exists(`common:reports.legend.${measure}`)
                ? t(`reports.legend.${measure}`)
                : t('tonnes-emitted')
            }`,
            title: `${
              timeGranularity === 'month' || !timeGranularity
                ? moment(item.x).format('MMM YYYY')
                : timeGranularity === 'quarter'
                ? `Q${moment(item.x).quarter()} ${moment(item.x).year()}`
                : moment(item.x).year()
            }`,
          }
        },
        customItems: (items) => {
          if (report?.type !== ReportType.FORECAST) {
            return items
          }
          return items.map((item) => {
            const preTitle = item.data.title
              ?.toString()
              .substring(0, item.data.title?.toString().lastIndexOf('_'))
            return {
              ...item,
              name: `<span style='line-height: 1.5'><b>
            ${item.name}
          </b></br><span>${
            i18n.exists(`forecast.${preTitle}.description`)
              ? t(`forecast.${preTitle}.description`)
              : ''
          }</span><br>Value</>`,
            }
          })
        },
      },
      slider: !disableSlider ? config.slider : false,
      data: chartData.flatMap((datum) =>
        datum.series.map((seriesItem) => ({
          ...seriesItem,
          title: i18n.exists(`common:reports.keys.${datum.key}`)
            ? t(`reports.keys.${datum.key}`)
            : datum.title.includes(',')
            ? datum.title.split(',')[0]
            : datum.title,
          x: seriesItem.x,
        })),
      ),
    }
    return (
      <div>
        <LineChart
          height={height}
          style={{ display: visible ? 'block' : 'none' }}
          renderer={'canvas'}
          {...props}
          chartRef={(ref) => {
            if (ref && chartRef && report) {
              chartRef.current = ref
            }
          }}
        />
        <LineChart
          height={height}
          style={{ display: 'none' }}
          renderer={'svg'}
          {...props}
          width={1000}
          chartRef={(ref) => {
            if (ref && svgChartRef) {
              svgChartRef.current = ref
            }
          }}
        />
      </div>
    )
  },
)

Line.displayName = 'Line'

export default Line
