import React, { ReactElement, memo } from 'react'
import { HiOutlineX } from 'react-icons/hi'

import classes from './CloseIcon.module.less'

const CloseIcon = (): ReactElement => (
  <div className={classes.closeIcon}>
    <HiOutlineX />
  </div>
)

export default memo(CloseIcon)
