/* eslint-disable react-hooks/rules-of-hooks */
/* TODO: VN: The only way we can sort this out right now is to ignore this,
 * We have to find another way to do this; Issue has been opened with react-router
 */
import React from 'react'
import { useInRouterContext, useLocation, useNavigate, useParams } from 'react-router'

import { WithRouterProps } from './types'

/**
 * Because React-router-dom has deprecated withRouter as a HOC,
 * we need to create a custom HOC to get the location, navigate and params.
 * @param Child - The wrapped component
 */
export function withRouter<P extends WithRouterProps>(
  Child: React.ComponentClass<P>,
): {
  (props: Omit<P, keyof WithRouterProps>): JSX.Element
  displayName: string
} {
  const WithRouterHOC = (props: Omit<P, keyof WithRouterProps>): JSX.Element => {
    const inRouterContext = useInRouterContext()

    if (inRouterContext) {
      const location = useLocation()
      const navigate = useNavigate()
      const params = useParams()

      return <Child {...(props as P)} navigate={navigate} location={location} params={params} />
    }

    return <Child {...(props as P)} />
  }

  WithRouterHOC.displayName = `withRouterHOC(${Child.displayName || Child.name || 'Component'})`

  return WithRouterHOC
}
