import React, { ReactElement, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Layout, Row } from 'antd/es'

import Title from '@/atoms/Title'

import classes from './styles.module.less'

const SIDER_WIDTH = 265

const SidebarErrorTemplate = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Layout.Sider trigger={null} className={classes.sider} width={SIDER_WIDTH} collapsible>
      <Row align="top" justify="center" gutter={16}>
        <Col>
          <div className={`${classes.errorWrapper} ${classes.errorSidebar}`}>
            <Title className={`${classes.errorText} ${classes.white}`}>
              {t('general-errors.app-error')}
            </Title>
          </div>
        </Col>
      </Row>
    </Layout.Sider>
  )
}

export default memo(SidebarErrorTemplate)
