import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { FactorRequestResponse } from '@cozero/models'

import factorsRequestsResponseApiSlice from './api'
import {
  CREATE_FACTOR_REQUEST_RESPONSE,
  GET_FACTOR_REQUEST_RESPONSE,
  SLICE_NAME_USER,
} from './constants'

export interface FactorRequestResponseState {
  factorRequestResponse: FactorRequestResponse | null
}

const initialState: FactorRequestResponseState = {
  factorRequestResponse: null,
}

const factorRequestResponseSlice = createSlice({
  name: SLICE_NAME_USER,
  initialState,
  reducers: {
    setFactorRequestResponse: (state, action: PayloadAction<FactorRequestResponse>) => {
      state.factorRequestResponse = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      factorsRequestsResponseApiSlice.endpoints[GET_FACTOR_REQUEST_RESPONSE].matchFulfilled,
      (state: FactorRequestResponseState, { payload }) => ({
        ...state,
        factorRequestResponse: payload,
      }),
    )
  },
})

export const { setFactorRequestResponse } = factorRequestResponseSlice.actions

export default factorRequestResponseSlice
