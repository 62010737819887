import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Organization, User } from '@cozero/models'

import { SupportedLocale } from '@/i18n'

import { LOGIN, authApiSlice } from '../auth'
import organizationsApiSlice, { UPDATE_ORGANIZATION } from '../organizations/api'
import { UPDATE_CURRENT_USER, userProfileApiSlice } from '../userProfile'

import { LOGIN_WITH_JWT_TOKEN, LOGIN_WITH_TOKEN, LOGOUT, SLICE_NAME_USER } from './constants'

// Define a type for the slice state
export interface AuthState extends Partial<User> {
  token: string | null
  user?: User
  organization?: Organization
}

const initialState: AuthState = {
  locale: undefined,
  token: null,
  user: undefined,
  organization: undefined,
}

const authSlice = createSlice({
  name: SLICE_NAME_USER,
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
      state.organization = action.payload.organization as Organization
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    resetAuthState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApiSlice.endpoints[LOGIN].matchFulfilled,
        (state: AuthState, { payload }) => ({
          ...state,
          user: payload.user,
          token: payload.accessToken,
          organization: payload.user.organization as Organization,
        }),
      )
      .addMatcher(
        authApiSlice.endpoints[LOGIN_WITH_JWT_TOKEN].matchFulfilled,
        (state: AuthState, { payload }) => {
          return {
            ...state,
            user: payload.user,
            token: payload.accessToken,
            organization: payload.user.organization as Organization,
          }
        },
      )
      .addMatcher(authApiSlice.endpoints[LOGOUT].matchFulfilled, () => {
        const forcedLogout = !!window.localStorage.getItem('FORCED_LOGOUT')
        window.localStorage.clear()

        if (forcedLogout) {
          window.localStorage.setItem('FORCED_LOGOUT', 'true')
        }

        return initialState
      })
      .addMatcher(
        authApiSlice.endpoints[LOGIN_WITH_TOKEN].matchFulfilled,
        (state: AuthState, { payload }) => ({
          ...state,
          locale: payload.user.locale as SupportedLocale,
          user: payload.user,
          token: payload.accessToken,
          organization: payload.user.organization as Organization,
        }),
      )
      .addMatcher(
        userProfileApiSlice.endpoints[UPDATE_CURRENT_USER].matchFulfilled,
        (state, { payload }) => ({
          ...state,
          locale: payload.locale as SupportedLocale,
          user: payload,
        }),
      )
      .addMatcher(
        organizationsApiSlice.endpoints[UPDATE_ORGANIZATION].matchFulfilled,
        (state, { payload }) => ({
          ...state,
          organization: payload,
        }),
      )
  },
})

export const { setUser, setToken, resetAuthState } = authSlice.actions

export default authSlice
