import React, { ReactElement, memo, useMemo } from 'react'
import { HiUser } from 'react-icons/hi'

import { Avatar as AntAvatar, AvatarProps, Tooltip } from 'antd/es'

import { User } from '@cozero/models'

import classes from './Avatar.module.less'

interface Props {
  firstName?: User['firstName']
  lastName?: User['lastName']
  image?: User['photo']
  size?: 'xs' | 'sm' | 'md'
  showTooltip?: boolean
  outlined?: boolean
  className?: string
}

/**
 * A basic avatar representing a user
 * @arg props.user the user the avatar represents
 * @arg [props.size='sm] the size of the avatar
 * @component
 */
const Avatar = ({
  image,
  firstName,
  lastName,
  size = 'sm',
  showTooltip = true,
  outlined = false,
  className,
}: Props): ReactElement => {
  const classNames = useMemo(
    () => [classes.avatar, classes[size], outlined && classes.outlined, className].join(' '),
    [size],
  )
  const SHORT_NAME = useMemo(
    () =>
      firstName || lastName ? `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`.toUpperCase() : null,
    [firstName, lastName],
  )
  const FULL_NAME = useMemo(
    () => (firstName && lastName ? `${firstName} ${lastName}` : null),
    [firstName, lastName],
  )

  const Base = (props: AvatarProps): JSX.Element => (
    <Tooltip title={FULL_NAME} trigger={showTooltip ? ['hover'] : []}>
      <AntAvatar className={classNames} {...props} />
    </Tooltip>
  )

  if (image && image.url) {
    return <Base className={classNames} src={<img src={image.url} />} />
  }

  if (!image) {
    return <Base>{SHORT_NAME}</Base>
  }

  return <Base icon={<HiUser className={classes.icon} />} />
}

export default memo(Avatar)
