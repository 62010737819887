import { TFunction } from 'react-i18next'

import { message } from 'antd/es'

/**
 * Copy anything to the users clipboard, returns a confimation/error message if
 * @argument {string} value the value to copy
 */
export const copyToClipboard = async (
  value: string,
  t: TFunction<'common', undefined>,
): Promise<boolean> => {
  try {
    const clipBoard = navigator.clipboard
    await clipBoard.writeText(value)
    return message.success(t('actions.copy.success'))
  } catch (e) {
    return message.error(t('actions.copy.failed'))
  }
}
