import React, { ReactNode, createContext, useContext, useState } from 'react'

import { Moment } from 'moment'

import { ClimateActionLevel, ClimateActionType } from '@cozero/models'

export interface IActionDetails {
  actionName: string
  description?: string
  category?: number
  subcategory?: number
  level?: ClimateActionLevel
  actionArea?: number
  responsible?: number
  businessUnitId?: number
  locationId?: number
  type: ClimateActionType
  draft?: boolean
}

export interface IImpactDetails {
  startDate?: Date | Moment
  annualCost: number
  annualCarbonSaving: number
  lifetime: number
  timeToImpact?: number
  initialCost: number
  annualCarbonSavingUnit: 'tons' | 'percentage'
  annualCarbonSavingPercentage?: number
  referenceYear?: Date | Moment
}

interface IClimateActionContext {
  currentStep: number
  nextButtonDisabled: boolean
  reductionMeasure: ClimateActionType
  actionDetails: IActionDetails | null
  setNextButtonDisabled: (disabled: boolean) => void
  setCurrentStep: (step: number) => void
  nextStep: () => void
  prevStep: () => void
  setActionDetails: (details: IActionDetails) => void
  setReductionMeasure: (measure: ClimateActionType) => void
  impactDetails: IImpactDetails | null
  setImpactDetails: (impactDetails: IImpactDetails) => void
  isChanged: boolean
  setIsChanged: (isChanged: boolean) => void
}

const defaultState = {
  currentStep: 0,
  nextButtonDisabled: true,
  reductionMeasure: 'direct' as ClimateActionType,
  actionDetails: {
    actionName: '',
    type: 'direct' as ClimateActionType,
  },
  impactDetails: {
    initialCost: 0,
    annualCost: 0,
    annualCarbonSaving: 0,
    timeToImpact: 0,
    lifetime: 1,
    annualCarbonSavingUnit: 'tons' as 'tons' | 'percentage',
  },
  isChanged: false,
}

const ClimateActionContext = createContext<IClimateActionContext>(
  defaultState as IClimateActionContext,
)

const ClimateActionProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [state, setState] = useState(defaultState)

  const value = {
    ...state,
    setCurrentStep: (currentStep: number) =>
      setState((prevState) => {
        return { ...prevState, currentStep }
      }),
    prevStep: () =>
      setState((prevState) => {
        return { ...prevState, currentStep: prevState.currentStep - 1 }
      }),
    nextStep: () =>
      setState((prevState) => {
        return { ...prevState, currentStep: prevState.currentStep + 1 }
      }),
    setNextButtonDisabled: (disabled: boolean) =>
      setState((prevState) => {
        return { ...prevState, nextButtonDisabled: disabled }
      }),
    setActionDetails: (actionDetails: IActionDetails) => {
      return setState((prevState) => {
        return { ...prevState, actionDetails: { ...prevState.actionDetails, ...actionDetails } }
      })
    },
    setReductionMeasure: (measure: ClimateActionType) => {
      return setState((prevState) => {
        return { ...prevState, reductionMeasure: measure }
      })
    },
    setImpactDetails: (impactDetails: IImpactDetails) => {
      return setState((prevState) => {
        return { ...prevState, impactDetails: { ...prevState.impactDetails, ...impactDetails } }
      })
    },
    setIsChanged: (isChanged: boolean) =>
      setState((prevState) => {
        return { ...prevState, isChanged }
      }),
  }

  return <ClimateActionContext.Provider value={value}>{children}</ClimateActionContext.Provider>
}

const useClimateActionContext = (): IClimateActionContext => {
  try {
    const contextValue = useContext(ClimateActionContext)
    if (!contextValue) {
      throw new Error('useClimateActionContext must be used within a ClimateActionProvider')
    }
    return contextValue
  } catch (e) {
    throw new Error(e)
  }
}

export { useClimateActionContext, ClimateActionContext }

export default ClimateActionProvider
