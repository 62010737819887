import { User, UserOnboardingStep } from '@cozero/models'
import { centralApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'
import { TAG_USER } from '../auth'

import { DISABLE_ONBOARDING_STEP, UPDATE_ONBOARDING } from './constants'

const onboardingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [DISABLE_ONBOARDING_STEP]: builder.mutation<User, string>({
      query: (type) => ({
        url: centralApiGatewayClient.users.DISABLE_ONBOARDING,
        method: 'PUT',
        data: {
          [type]: false,
        },
      }),
      invalidatesTags: (res) => [{ type: TAG_USER, id: res?.id }],
    }),
    [UPDATE_ONBOARDING]: builder.mutation<
      UserOnboardingStep[] | null,
      { steps: UserOnboardingStep[] | null }
    >({
      query: ({ steps }) => ({
        url: centralApiGatewayClient.users.UPDATE_ONBOARDING,
        method: 'POST',
        data: {
          steps,
        },
      }),
      invalidatesTags: (result) => [{ type: TAG_USER, result }],
      extraOptions: { maxRetries: 0 },
    }),
  }),
})

export const { useDisableOnboardingStepMutation, usePrefetch, useUpdateOnboardingMutation } =
  onboardingApiSlice

export default onboardingApiSlice
