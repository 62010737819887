import { useState } from 'react'

import { ILifecycleContext } from '../contexts/lifecycle'
import { CreateProductLogEntry } from '../pages/Organization/NewProduct/GenericStep'
import { LifecycleStepsKey } from '../pages/Organization/NewProduct/steps'

const useLifecycleHandler = (): ILifecycleContext => {
  const [currentStep, setCurrentStep] = useState(-1)
  const [stepsToDisplay, setStepsToDisplay] = useState<{ title: string }[]>([])
  const [selectedSteps, setSelectedSteps] = useState<{ index: number; isActive: boolean }[]>([])
  const [from, setFrom] = useState<string | undefined>()
  const [isUnique, setIsUnique] = useState<boolean>(true)
  const [stepData, setStepData] = useState<
    (CreateProductLogEntry & {
      inputs: {
        inputKey: string
        value: number
        unitId: number
      }[]
      productlifecycleStep: LifecycleStepsKey
    })[]
  >([])
  const [totalEmissionFactor, setTotalEmissionFactor] = useState<number>(0)
  const [isDataValid, setIsDataValid] = useState<boolean>(false)
  const [isNewProduct, setIsNewProduct] = useState<boolean>(false)

  return {
    setCurrentStep,
    currentStep,
    stepsToDisplay,
    setStepsToDisplay,
    selectedSteps,
    setSelectedSteps,
    stepData,
    setStepData,
    isUnique,
    setIsUnique,
    isDataValid,
    setIsDataValid,
    totalEmissionFactor,
    setTotalEmissionFactor,
    isNewProduct,
    setIsNewProduct,
    from,
    setFrom,
  }
}

export default useLifecycleHandler
