import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd/es'

import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import classes from './classes.module.less'

const DataForm = (): JSX.Element => {
  const { t } = useTranslation('common')

  return (
    <Row>
      <Col span={24}>
        <Row className={classes.section}>
          <Col span={24}>
            <Title size="sm">{t('settings.data.title')}</Title>
          </Col>
        </Row>
        <Row className={classes.section}>
          <Col span={12}>
            <Text size="xl">{t('settings.data.description')}</Text>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default DataForm
