import React from 'react'
import { Navigate, RouteProps } from 'react-router-dom'

import { Organization } from '@cozero/models'

import { useAppSelector } from '@/redux'
import { getIsAdmin, getIsManagerOrAdmin, selectUser } from '@/redux/auth'
import { config } from '@/utils/config'

type AdminSettingsProps = RouteProps & {
  pricingTypeFilter?: string[]
  level?: 'admin' | 'manager'
}

const { routes } = config

const AdminSettingsRoute = ({
  children,
  pricingTypeFilter,
  level,
}: AdminSettingsProps): JSX.Element => {
  const user = useAppSelector(selectUser)
  const isAdmin = useAppSelector(getIsAdmin)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const pricingType = (user?.organization as Organization)?.pricing?.type

  if (pricingType && pricingTypeFilter?.includes(pricingType)) {
    return <Navigate to={routes.home} />
  }

  if ((level === 'admin' && isAdmin) || (level === 'manager' && isManagerOrAdmin)) {
    return <>{children}</>
  }

  if ((!level && isAdmin) || (!level && isManagerOrAdmin)) {
    return <>{children}</>
  }

  return <Navigate to={routes.home} />
}

export default AdminSettingsRoute
