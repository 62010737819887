import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd'
import { Collapse } from 'antd/es'

import Card from '../../../../atoms/Card'
import Text from '../../../../atoms/Text'

import classes from './FaqSection.module.less'

const { Panel } = Collapse

const FaqSection = (): JSX.Element => {
  const { t } = useTranslation('common')

  const questions = useMemo(
    () => [
      {
        title: t('onboarding.suppliers.faq.0.title'),
        text: t('onboarding.suppliers.faq.0.text'),
      },
      {
        title: t('onboarding.suppliers.faq.1.title'),
        text: t('onboarding.suppliers.faq.1.text'),
      },
      {
        title: t('onboarding.suppliers.faq.2.title'),
        text: t('onboarding.suppliers.faq.2.text'),
      },
      {
        title: t('onboarding.suppliers.faq.3.title'),
        text: t('onboarding.suppliers.faq.3.text'),
      },
    ],
    [],
  )
  return (
    <Card bgColor="default" shadow="none" className={classes.mainWrapper}>
      <Collapse bordered={false} ghost expandIconPosition="end">
        <Panel
          header={
            <Text fontWeight="semibold" size="sm" mode="title">
              {t('onboarding.suppliers.faq.title')}
            </Text>
          }
          key="1"
        >
          <Row gutter={[0, 30]} className={classes.questionsWrapper}>
            <Col span={24}>
              {questions.map(({ title, text }) => (
                <Row gutter={[20, 0]} key={title} className={classes.faqEntry}>
                  <Col span={1}>
                    <div className={classes.questionPill}>
                      <Text color="blue" fontWeight="semibold" size="lg" mode="text">
                        <div>?</div>
                      </Text>
                    </div>
                  </Col>
                  <Col span={23}>
                    <Text fontWeight="medium" size="xs" mode="title">
                      {title}
                    </Text>
                  </Col>
                  <Col span={23} offset={1}>
                    <Text mode="text" size="sm" fontWeight="light">
                      {text}
                    </Text>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Card>
  )
}

export default FaqSection
