import { FactorRequestResponse } from '@cozero/models'
import { centralApiGatewayClient, logApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

import { CREATE_FACTOR_REQUEST_RESPONSE, GET_FACTOR_REQUEST_RESPONSE } from './constants'

const factorsRequestsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [CREATE_FACTOR_REQUEST_RESPONSE]: builder.mutation<
      FactorRequestResponse,
      Partial<FactorRequestResponse>
    >({
      query: (data) => ({
        url: logApiGatewayClient.factorRequestsResponses.CREATE,
        method: 'POST',
        data,
      }),
    }),

    [GET_FACTOR_REQUEST_RESPONSE]: builder.query<FactorRequestResponse, number>({
      query: (userId) => ({
        url: centralApiGatewayClient.users.GET_ONE.replace(':id', `${userId}`),
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useCreateFactorRequestResponseMutation,
  useGetFactorRequestResponseQuery,
  usePrefetch,
} = factorsRequestsApiSlice

export default factorsRequestsApiSlice
