import React, { useCallback, useEffect, useState } from 'react'

import { Cascader } from 'antd/es'

import { orderBy } from 'lodash'

import { ActivityDataSourceWithChildren } from '@cozero/models'

interface ActivityDataSourceLazyLoadedCascaderProps {
  activityDataSources: ActivityDataSourceWithChildren[]
  disabled?: boolean
  value?: number
  onChange?: (ads: number[]) => void
  placeholder?: string
}

interface CascaderOption {
  label: string
  value: number
  children: CascaderOption[]
  isLeaf: boolean
}

const ActivityDataSourceLazyLoadedCascader = ({
  value,
  activityDataSources,
  disabled,
  placeholder,
  onChange,
}: ActivityDataSourceLazyLoadedCascaderProps): JSX.Element => {
  const [activityDataSourcesToDisplay, setActivityDataSourcesToDisplay] = useState<
    ActivityDataSourceWithChildren[]
  >([])
  const [adsStructurePath, setAdsStructurePath] = useState<number[]>([])

  useEffect(() => {
    if (activityDataSources.length && !activityDataSourcesToDisplay.length) {
      setActivityDataSourcesToDisplay(activityDataSources)
    }
  }, [activityDataSources, activityDataSourcesToDisplay])

  const parseActivityDataSources = (
    activityDataSources: ActivityDataSourceWithChildren[],
  ): CascaderOption[] => {
    const parsedActivityDataSources = []
    for (const ads of activityDataSources) {
      parsedActivityDataSources.push({
        label: ads.name,
        value: ads.id,
        children: ads?.children?.length ? parseActivityDataSources(ads.children) : [],
        isLeaf: !ads?.children?.length,
      })
    }
    return orderBy(parsedActivityDataSources, ['label'], ['asc'])
  }

  const adsOptions = useCallback(() => {
    if (activityDataSourcesToDisplay) {
      return parseActivityDataSources(activityDataSourcesToDisplay)
    }
  }, [activityDataSourcesToDisplay])

  return (
    <Cascader
      value={adsStructurePath}
      disabled={disabled}
      options={adsOptions()}
      placeholder={placeholder}
      changeOnSelect
      onChange={(value) => {
        onChange && onChange(value as number[])
        setAdsStructurePath(value as number[])
      }}
      showSearch={true}
    />
  )
}

export default ActivityDataSourceLazyLoadedCascader
