import React, { Suspense, lazy } from 'react'
import { RouteObject } from 'react-router'

import { routes } from '@cozero/utils'

import SuspenseSpinner from '../SuspenseSpinner'

const EmployeEmissionsPage = lazy(() => import('@/pages/SelfReporting'))

const SELF_REPORTING_ROUTES = (): RouteObject[] => [
  {
    path: routes.employeeEmissions.base,
    element: (
      <Suspense fallback={<SuspenseSpinner />}>
        <EmployeEmissionsPage />
      </Suspense>
    ),
  },
]

export default SELF_REPORTING_ROUTES
