import React from 'react'

import { Spin, TablePaginationConfig } from 'antd/es'

import { Location, Page } from '@cozero/models'

import Table from '@/molecules/Table'

import { useLocationsColumns } from './useLocationsColumns'

interface Props {
  defaultPageSize?: number
  currentPage?: number
  pageSize?: number
  locations?: Location[]
  paginatedLocations?: Page<Location>
  businessUnitsAllowed: boolean
  isManagerOrAdmin: boolean
  loading?: boolean
  setCurrentPage?: (size: number) => void
  setPageSize?: (size: number) => void
}

function LocationsTable({
  defaultPageSize = 10,
  currentPage,
  pageSize,
  locations,
  paginatedLocations,
  businessUnitsAllowed,
  isManagerOrAdmin,
  loading = false,
  setPageSize,
  setCurrentPage,
}: Props): JSX.Element {
  const { columns } = useLocationsColumns({
    showEditAction: isManagerOrAdmin,
    businessUnitsAllowed,
  })

  const changeTable = (pagination: TablePaginationConfig): void => {
    if (setCurrentPage && pagination.current && pagination.current !== currentPage) {
      setCurrentPage(pagination.current)
    }
  }

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={locations ? locations : paginatedLocations?.data}
        rowKey="id"
        scroll={{ x: 1700 }}
        onChange={changeTable}
        pagination={
          setPageSize
            ? {
                current: currentPage,
                defaultPageSize,
                pageSize,
                total: paginatedLocations?.total,
                hideOnSinglePage: true,
                onShowSizeChange: (_current, size) => {
                  setPageSize && setPageSize(size)
                },
              }
            : false
        }
      />
    </Spin>
  )
}

export default LocationsTable
