import React from 'react'
import { ReactNode } from 'react'
import { ReactElement } from 'react'

import classes from './Container.module.less'

interface Props {
  children: ReactNode
}

const Container = ({ children }: Props): ReactElement => {
  return <div className={classes.container}>{children}</div>
}

export default Container
