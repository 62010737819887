export const SLICE_NAME_STRATEGY_BUILDER = 'strategyBuilder'

export enum ActOnboardingStep {
  CHOOSE_CLOSED_PERIOD = 'CHOOSE_CLOSED_PERIOD',
  SETUP_FORECAST = 'SETUP_FORECAST',
  CREATE_TARGET = 'CREATE_TARGET',
  CREATE_CLIMATE_ACTION = 'CREATE_CLIMATE_ACTION',
}

export const ActOnboardingSteps: ActOnboardingStep[] = [
  ActOnboardingStep.CHOOSE_CLOSED_PERIOD,
  ActOnboardingStep.SETUP_FORECAST,
  ActOnboardingStep.CREATE_TARGET,
  ActOnboardingStep.CREATE_CLIMATE_ACTION,
]
